import { get } from 'lodash';

/**
 * @param {Object} methods
 * @returns {Function}
 */
export default function callMethodInTree(methods) {
  /**
   * @param {Object} webApiObject
   * @param {string} methodPath
   * @param {*} data
   * @param {*} config
   * @returns {*}
   */
  return (webApiObject, { methodPath, data, config }) => {
    const method = get(methods, methodPath);

    return method.call(webApiObject, data, config);
  };
}
