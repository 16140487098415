import { has, get, set, unset, reduce, curry } from 'lodash';
import ApplicationError from 'errors/ApplicationError';

/**
 * @param {Object} mapping
 * @param {Object|*} obj
 * @returns {Object|*}
 */
export default curry(function mapSnapshotProps(mapping, obj) {
  if (!mapping) {
    throw new ApplicationError(`You have to provide "mapping". Received: ${mapping}.`);
  }

  if (!obj) {
    return obj;
  }

  return reduce(mapping, (acc, propName, key) => {
    const hasPropName = has(obj, propName);

    if (hasPropName) {
      const propValue = get(obj, propName);
      unset(acc, propName);
      set(acc, key, propValue);
    }

    return acc;
  }, { ...obj });
});
