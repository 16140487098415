import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

const { CancellationError } = Promise;

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
CancellationError.is = getErrorTypeCheckFor(CancellationError);

export default CancellationError;
