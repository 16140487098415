/* eslint-disable react/display-name, react/no-multi-comp, react/prop-types */
import React from 'react';
import { isNil } from 'lodash';
import t from 'tcomb-form-plus';
import observerFactory from 'shared/components/Form/decorators/observerFactory';
import MaskedValueTransformer from './MaskedValueTransformer';

export class BaseTextboxFactory extends t.form.Component {}

/**
 * Bind given Component to Factory
 *
 * @param  {Function} Component
 * @returns {t.form.Component}
 */
export default function getTextboxFactory(Component) {
  @observerFactory
  class PatchTextboxFactory extends BaseTextboxFactory {
    /**
     * Textbox constructor
     *
     * Create MaskedValueTransformer instance once for further usage
     * `maskedValueTransformer` updates automatically on input type changes
     *
     * @param  {object} props
     */
    constructor(props) {
      super(props);

      this.maskedValueTransformer = new MaskedValueTransformer({
        typeInfo: this.typeInfo,
        mask: props.options.mask,
      });

      // Fix initial value for masked inputs
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.value = this.getTransformer().format(props.value);
      this.displayName = `TextboxFactory$${props.ctx.path.join('.')}`;
    }

    /**
     * @inheritdoc
     */
    componentWillReceiveProps(nextProps, nextContext) {
      super.componentWillReceiveProps(nextProps, nextContext);

      if (nextProps.type !== this.props.type) {
        // update masked Value Transformer according to new type
        this.maskedValueTransformer.setTypeInfo(this.typeInfo);
      }

      if (nextProps.options.mask !== this.props.options.mask) {
        this.maskedValueTransformer.setMask(nextProps.options.mask);
      }
    }

    /**
     * We need to pass pure unmasked value to parent to set this value to store
     *
     * @param  {Object} event
     */
    handleInputChange = event => {
      const modelValue = this.getTransformer().parse(event.target.value);
      this.onChange(modelValue);
    };

    /**
     * Enable error reporting
     */
    handleInputBlur = () => {
      this.setState({
        isTouched: true,
      });
    };

    /**
     * Return function that can parse and format back field value according to model inner type
     * called on each render
     *
     * @returns {Function} Transformer function
     */
    getTransformer() {
      // super() call in constructor triggers this method
      // so check that maskedValueTransformer is instantiated
      if (this.props.options.mask && this.maskedValueTransformer) {
        return this.maskedValueTransformer;
      }

      const { innerType } = this.typeInfo;
      if (innerType === t.Number || innerType === t.Integer) {
        return t.form.Textbox.numberTransformer;
      }

      return t.form.Textbox.transformer;
    }

    /**
     * Valid state displaying logic
     *
     * @returns {Boolean}
     */
    isValid() {
      return Boolean(!this.hasError() && this.state.value);
    }

    /**
     * Construct input props
     *
     * @returns {Object}  props
     */
    getLocals() {
      const { options, ctx } = this.props;
      const { value } = this.state;

      let error;
      if (this.isErrorVisible()) {
        error = this.getError();
      }

      const locals = {
        error,
        required: options.required || !this.typeInfo.isMaybe,
        hint: options.hint,
        inline: options.inline,
        onChange: this.handleInputChange,
        onBlur: this.handleInputBlur,
        placeholder: options.placeholder,
        mask: options.mask,
        type: options.type,
        maxLength: options.messageLimit,
        value: isNil(value) ? '' : value.toString(),
        disabled: options.disabled,
        icon: options.icon,
        className: options.className,
        // add name to field to be able to interact with them in tests
        name: ctx.path.join('.'),
        theme: options.theme,
      };

      return locals;
    }

    /**
     * Render textbox
     *
     * @returns {ReactElement}
     */
    getTemplate() {
      const TemplateComponent = this.props.options.template || Component;

      return locals => <TemplateComponent {...locals} />;
    }
  }

  return PatchTextboxFactory;
}
/* eslint-enable react/display-name, react/no-multi-comp, react/prop-types */

