/* eslint-disable no-template-curly-in-string */
export default {
  error: {
    error: 'Error',
    short: 'Error occurred',
    unknown: 'An unknown error occurred. Please refresh and try again later.',
    request: {
      common: 'We had trouble loading your ${dataType}. Please try again later.',
    },
    formField: {
      required: 'This field is required',
      notSelected: 'Please, select ${entity} to continue',
      minLength: '${fieldName} must be at least ${numChars} characters long',
      maxLength: '${fieldName} can\'t be longer than ${numChars} characters',
      minDigits: '${fieldName} should contain ${numDigits} digits',
      requiredChar: '${fieldName} should contain at least ${requiredChar}',
      invalidInput: '${fieldName} can\'t contain special characters',
      invalid: '${fieldName} is invalid',
      invalidType: 'Please enter a valid ${fieldType}',
      memberId: 'Please use your full Member ID. It can be found on your insurance card. Often times, your Member ID is your SSN.',
      date: 'Enter date in format MM/DD/YYYY',
      maxDate: 'Date can\'t be greater than ${maxDate}',
      maxMonth: 'Month can\'t be greater than ${maxMonth}',
      maxYear: 'Year can\'t be greater than ${maxYear}',
      minYear: 'Year can\'t be less than ${minYear}',
      futureDate: 'You can\'t specify future date here',
      maxSize: 'There was a problem uploading the image. Please make sure the file is under ${maxSize}MB and a .jpg, .png, or .pdf file.',
      useSuggest: 'You have to select ${fieldName} from suggestions',
      tooBig: '${fieldName} can\'t be greater than ${maxFieldName}',
      samePassword: 'Passwords must match',
    },
  },
  warning: {
    emptyResult: 'Nothing found',
  },
  info: {
    item: 'item',
    number: 'number',
    oneLetter: 'one letter',
    thisField: 'This field',
    memberId: 'Member ID',
    zipCode: 'ZIP Code',
    email: 'Email',
    emailAddress: 'email address',
    password: 'Password',
    phone: 'Phone',
    state: 'State',
  },
};
/* eslint-enable no-template-curly-in-string */
