import { types, addDisposer } from 'mobx-state-tree';
import PagedItemsStore from './PagedItemsStore';

const BaseTasksStore = PagedItemsStore
  .named('BaseTasksStore')
  .props({
    activeTask: types.maybeNull(types.frozen({})),
  })
  .actions(self => ({
    /**
     * @param {Object} task - server format task object
     */
    setActiveTask(task) {
      if (!self.items.has(task.id)) {
        // since `activeTask` is reference field, its value should exist in the three before assignment
        self.items.put(task);
      }

      self.activeTask = task.id;
      self.stores.tasksCount.update();
    },
    clearActiveTask() {
      const { activeTask, items } = self;
      self.activeTask = null;

      if (items.has(activeTask.id)) {
        items.delete(activeTask.id);
      }

      // don't wait for requests completion
      self.reloadCurrentPage();
      self.stores.tasksCount.update();
    },
    afterCreate() {
      setTimeout(() => {
        const { auth } = self.stores;
        const unlistenLogout = auth.onLogout(self.reset);

        addDisposer(self, unlistenLogout);
      });
    },
  }));

export default BaseTasksStore;
