import { isFunction, isPlainObject, every } from 'lodash';

/**
 * This function will be imported in error files. In order to avoid circular dependencies we store it in
 * the separate file and do not import errors here.
 *
 * @param {Error} ErrorConstructor
 * @returns {Function}
 */
export default function getErrorTypeCheckFor(ErrorConstructor) {
  /**
   * @example
   * APIError.is(err);
   * APIError.is(err, err => err.code === 403 || err.code === 404)
   * APIError.is(err, { code: 404 })
   *
   * @param   {any}  any
   * @param   {Object|Function} [predicate] additional function or object to check error with
   * @returns {Boolean}
   */
  return function is(any, predicate) {
    if (!(any instanceof ErrorConstructor)) {
      return false;
    }

    if (isFunction(predicate)) {
      return predicate(any);
    } else if (isPlainObject(predicate)) {
      return every(predicate, (val, key) => any[key] === val);
    }

    return true;
  };
}
