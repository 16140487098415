import { isInteger, startsWith, assign, isArray, includes } from 'lodash';
import appConfig from 'app/config';
import APIError from 'errors/APIError';
import UnauthorizedAPIError from 'errors/UnauthorizedAPIError';
import TimeoutAPIError from 'errors/TimeoutAPIError';
import { ERROR_CODES } from 'app/shared/constants';
import { API_AUTH_HEADER } from 'app/webApi/constants';
import RedirectError from 'errors/RedirectError';
import { markErrorAsLogged, markErrorAsReported } from 'errors/utils';
import history from 'shared/history';
import arePathnamesSame from 'shared/utils/history/arePathnamesSame';
import APILogger from 'app/webApi/utils/APILogger';
import { createWebApi, createAxiosRequester, callMethodInTree } from 'app/webApi/createWebApi';
import * as methods from 'app/webApi/methods';

const AXIOS_TIMEOUT_CODE = 'ECONNABORTED';
const DEFAULT_ERROR_CODE = ERROR_CODES.networkError;

/**
 * @param {Object} config
 * @returns {boolean}
 */
function isProductApi(config) {
  return (
    startsWith(config.url, appConfig.apiHost) ||
    // `baseURL` will be added to request url only after all interceptors execution
    startsWith(config.baseURL, appConfig.apiHost)
  );
}

/**
 * Make Authentication Header
 *
 * @param  {String} token
 * @param  {String} type
 * @returns {Object}
 */
function createAuthHeader(token, type) {
  return {
    [API_AUTH_HEADER]: `${type} ${token}`,
  };
}

/**
 * @param {Object} defaultHeaders
 * @param {Object} config
 * @returns {Object}
 */
function addDefaultHeaders(defaultHeaders, config) {
  config.headers = {
    ...defaultHeaders,
    ...config.headers,
  };

  return config;
}

/**
 * Redirect to logout page if auth token became invalid.
 *
 * @param   {APIError} error
 * @param   {Object} options.stores
 */
function handleUnauthorizedAPIError(error, { stores }) {
  const { navigation, auth } = stores;
  const { isTransitioning, currentLocation } = navigation;

  // In the event if we receive this error when the user is not signed-in we just treat is as a regular error
  if (!auth.isAuthenticated) {
    throw error;
  }

  const LOGOUT_LOCATION = {
    pathname: '/logout',
    search: `?next=${encodeURIComponent('/login')}`,
  };

  if (isTransitioning) {
    // No need to redirect if user is already on `/logout`
    if (!arePathnamesSame(currentLocation.pathname, LOGOUT_LOCATION.pathname)) {
      throw new RedirectError(LOGOUT_LOCATION);
    }
  } else {
    history.replace(LOGOUT_LOCATION);

    throw error;
  }
}

/**
 * @param {Object} appContext
 * @param {AppContainer} appContainer
 */
export default function webApiPlugin(appContext) {
  const defaultApiHeaders = {};

  appContext.api = {
    setAuthToken(tokenData) {
      if (!tokenData) {
        return;
      }

      assign(defaultApiHeaders, createAuthHeader(tokenData.token, tokenData.token_type));
    },
    removeAuthToken() {
      delete defaultApiHeaders[API_AUTH_HEADER];
    },
    ...createWebApi({
      requester: createAxiosRequester({
        baseURL: appConfig.apiHost,
        timeout: appConfig.apiTimeout,
        onRequestStart(config) {
          if (isProductApi(config)) {
            config = addDefaultHeaders(defaultApiHeaders, config);
          }

          return config;
        },
        onRequestError(axiosError, details, callInfo) {
          const code = isInteger(details.status) ? details.status : DEFAULT_ERROR_CODE;
          const message = details.statusText;

          let error;
          if (isProductApi(axiosError.config) && code === ERROR_CODES.unauthorized) {
            error = new UnauthorizedAPIError(code, details);
          } else if (axiosError.code === AXIOS_TIMEOUT_CODE) {
            error = new TimeoutAPIError(code, details);
          } else {
            error = new APIError(code, details, message);
          }

          const { dontReport } = axiosError.config;
          const dontReportError = dontReport === true || (isArray(dontReport) && includes(dontReport, code));
          if (dontReportError) {
            markErrorAsLogged(error);
            markErrorAsReported(error);
          }

          callInfo.context.logger.logError(error, {
            tags: {
              culprit: 'webApi.axiosAdapter.onRequestError',
              statusCode: code,
            },
          });

          throw error;
        },
      }),
      callMethod: callMethodInTree(methods),
      onCallError(error, callInfo) {
        callInfo.context.logger.logError(error, {
          tags: { culprit: 'webApi.onCallError' },
        });

        if (UnauthorizedAPIError.is(error)) {
          return handleUnauthorizedAPIError(error, appContext);
        } else if (APIError.is(error)) {
          throw error;
        }

        throw new APIError(ERROR_CODES.unhandledError);
      },
      getCallContext({ methodPath }) {
        return {
          logger: APILogger.getForMethod(methodPath),
        };
      },
    }),
  };
}
