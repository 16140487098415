import APIError from 'errors/APIError';

/**
 * @param {APIError} err
 * @returns {Object}
 */
export default function getExtraDataFromApiError(err) {
  if (!APIError.is(err)) {
    return {};
  }

  return {
    code: err.code,
    details: err.details,
  };
}
