import { types } from 'mobx-state-tree';
import { invert, values, first, reduce } from 'lodash';
import { transformSnapshot, mapSnapshotProps } from 'shared/utils/snapshot';
import parseDate from 'shared/utils/date/parseDate';
import formatDate from 'shared/utils/date/formatDate';
import { capitalizeName } from 'shared/utils/helpers';
import { DATE_FORMATS } from 'shared/constants';
import RelationshipDetails from './RelationshipDetails';
import Service from './Service';

const PRE_MAPPING = {
  statusName: 'status_name',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  readyAt: 'ready_at',
  sentAt: 'sent_at',
  processingAt: 'processing_at',
  paidAmount: 'paid_amount',
  isAOBEnabled: 'is_aob_enabled',
  calculatedBenefits: 'calculated_benefits',
  relationshipSnapshot: 'relationship_details',
  locationName: 'provider_location',
};

const POST_MAPPING = invert(PRE_MAPPING);

const CLAIM_STATUS = {
  new: 'new',
  ready: 'ready',
  sent: 'sent',
  processing: 'processing',
  cancelled: 'cancelled',
  completed: 'completed',
};

const CLAIM_RESOLUTION = {
  paid: 'paid',
  rejected: 'rejected',
  lost: 'lost',
};

const Claim = types
  .model('Claim', {
    id: types.identifierNumber,
    relationshipSnapshot: RelationshipDetails,
    createdAt: types.Date,
    updatedAt: types.Date,
    readyAt: types.maybeNull(types.Date),
    sentAt: types.maybeNull(types.Date),
    processingAt: types.maybeNull(types.Date),
    paidAmount: types.number,
    statusName: types.enumeration(values(CLAIM_STATUS)),
    isAOBEnabled: types.boolean,
    services: types.array(Service),
    diagnoses: types.array(types.frozen()),
    calculatedBenefits: types.frozen(),
    locationName: types.maybeNull(types.string),
  })
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      createdAt: parseDate(snapshot.createdAt, DATE_FORMATS.server),
      updatedAt: parseDate(snapshot.updatedAt, DATE_FORMATS.server),
      readyAt: parseDate(snapshot.readyAt, DATE_FORMATS.server),
      sentAt: parseDate(snapshot.sentAt, DATE_FORMATS.server),
      processingAt: parseDate(snapshot.processingAt, DATE_FORMATS.server),
      paidAmount: Number(snapshot.paidAmount),
      locationName: capitalizeName(snapshot.locationName),
    }),
  ))
  .views(self => ({
    /**
     * @returns {boolean}
     */
    get isStatusSent() {
      return self.statusName === CLAIM_STATUS.sent;
    },
    /**
     * @returns {boolean}
     */
    get isStatusProcessing() {
      return self.statusName === CLAIM_STATUS.processing;
    },
    /**
     * @returns {boolean}
     */
    get isStatusCompleted() {
      return self.statusName === CLAIM_STATUS.completed;
    },
    /**
     * @returns {Date}
     */
    get dateOfService() {
      if (self.services.length > 0) {
        return first(self.services).date;
      }

      return self.readyAt || self.createdAt;
    },
    /**
     * @returns {number}
     */
    get servicesTotal() {
      const centsTotal = reduce(self.services, (summ, service) => {
        summ += service.totalCostCents;

        return summ;
      }, 0);

      return centsTotal / 100;
    },
    /**
     * @returns {number}
     */
    get expectedReimbursement() {
      if (self.calculatedBenefits) {
        return reduce(self.calculatedBenefits.benefits, (summ, benefit) => {
          summ += Number(benefit.reimbursement);

          return summ;
        }, 0);
      }

      return 0;
    },
    /**
     * @returns {string}
     */
    get statusType() {
      if (self.statusName === CLAIM_STATUS.completed && self.outcome) {
        return self.outcome.resolution;
      }

      return self.statusName;
    },
  }))
  .postProcessSnapshot(transformSnapshot.post(
    snapshot => ({
      ...snapshot,
      createdAt: formatDate(snapshot.createdAt, DATE_FORMATS.server),
      updatedAt: formatDate(snapshot.updatedAt, DATE_FORMATS.server),
      readyAt: formatDate(snapshot.readyAt, DATE_FORMATS.server),
      sentAt: formatDate(snapshot.sentAt, DATE_FORMATS.server),
      processingAt: formatDate(snapshot.processingAt, DATE_FORMATS.server),
    }),
    mapSnapshotProps(POST_MAPPING),
  ));

Claim.STATUS = CLAIM_STATUS;
Claim.RESOLUTION = CLAIM_RESOLUTION;

export default Claim;
