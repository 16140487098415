import { flow, types } from 'mobx-state-tree';
import { map } from 'lodash';
import BaseMSTStore from 'app/stores/BaseMSTStore';

export default BaseMSTStore
  .named('StatusUserMessageTemplatesStore')
  .props({
    items: types.maybeNull(types.array(types.frozen())),
  })
  .volatile(() => ({
    error: null,
    loadingPromise: null,
  }))
  .views(self => ({
    get isLoading() {
      return Boolean(self.loadingPromise);
    },
  }))
  .actions(self => ({
    reset() {
      self.items = null;
      self.error = null;

      if (self.loadingPromise) {
        self.loadingPromise.cancel();
        self.loadingPromise = null;
      }
    },
    loadTemplates: flow(function* loadTemplates() {
      if (!self.isLoading) {
        self.error = null;

        try {
          self.loadingPromise = self.api.call('directory.getMessagesTemplates');
          const response = yield self.loadingPromise;
          self.items = map(response.result, item => ({
            label: item.name,
            value: item.response_text,
          }));
        } catch (error) {
          self.error = error;
          self.logger.logError(error);
        } finally {
          self.loadingPromise = null;
        }
      }
    }),
    abortTemplatesLoad() {
      if (self.loadingPromise) {
        self.loadingPromise.cancel();
        self.loadingPromise = null;
      }
    },
  }));
