import { action, isObservable, isObservableMap, isObservableArray, isObservableProp } from 'mobx';
import { isFunction, transform, forEach, assignWith, mapValues, pick, reject, assign } from 'lodash';
import { isStateTreeNode, getSnapshot, applySnapshot } from 'mobx-state-tree';

class AppContainer {
  /**
   * @constructor
   * @param {Object} options
   */
  constructor(options) {
    this.isStore = options.isStore;
    this.stores = {};
    this.context = this.createContext();
    this.applyPlugins(options.plugins);
    const createdStores = this.createStores(options.stores);
    assign(this.stores, createdStores);
    this.resetStores(this.stores);
  }

  /**
   * Adds plugin to the context in the container
   * Plugins can be thought of as mixins
   *
   * Plugins object has a structure like:
   *
   * plugins = {
   *   plugin1: (context, appContainer) => { context.feature1 = feature1 };
   *   plugin2: (context, appContainer) => { context.feature2 = feature2 };
   * }
   *
   * @param {Object} plugins
   * @private
   */
  applyPlugins(plugins) {
    forEach(plugins, plugin => {
      plugin(this.context, this);
    });
  }

  /**
   * Creates stores
   *
   * @param {Object} storeConstructors
   * @returns {Object}
   * @private
   */
  createStores(storeConstructors) {
    return mapValues(storeConstructors, StoreConstructor => {
      if (isFunction(StoreConstructor.create)) {
        return StoreConstructor.create({}, this.context);
      }

      return new StoreConstructor(this.context);
    });
  }

  /**
   * @param {Object} stores
   */
  @action resetStores(stores) {
    forEach(stores, store => {
      store.reset();
    });
  }

  /**
   * @returns {Object}
   */
  createContext() {
    return {
      // Will also contain all properties added by `options.plugins`
      stores: this.stores,
    };
  }

  /**
   * Serialize all stores
   * Expects to find on each store(that we need to serialize) one of(or both):
   *   -  `static serializeKeys = ['key1', 'key2']`
   *   -  `store.serialize(dataAfterSerializeKeys)` function
   *
   * @returns {Object}
   * @public
   */
  serialize() {
    return transform(this.stores, (appState, store, storeName) => {
      const storeState = isStateTreeNode(store) ?
        getSnapshot(store) :
        this.serializeStore(store);

      if (storeState) {
        appState[storeName] = storeState;
      }
    });
  }

  /**
   * Serialize store data
   *
   * @param {Object} store
   * @returns {Object}
   * @private
   */
  serializeStore(store) {
    const { includeSerializeKeys, excludeSerializeKeys, onlySerializeKeys } = store.constructor;

    let state = {};

    let serializableStore = store;

    if (Array.isArray(onlySerializeKeys)) {
      serializableStore = pick(store, onlySerializeKeys);
    }

    state = transform(serializableStore, (state, value, key) => {
      if (this.isStore(value)) {
        state[key] = this.serializeStore(value);
      } else if (isObservableMap(value)) {
        // Need this to preserve map order
        state[key] = [...value.entries()];
      } else if (isObservableArray(value)) {
        state[key] = value.slice();
      } else if (isObservable(value) || isObservableProp(store, key)) {
        state[key] = value;
      }
    }, {});

    if (Array.isArray(includeSerializeKeys)) {
      state = {
        ...pick(serializableStore, includeSerializeKeys),
        ...state,
      };
    }

    if (Array.isArray(excludeSerializeKeys)) {
      state = reject(state, excludeSerializeKeys);
    }

    if (store.serialize) {
      state = store.serialize(state);
    }

    return state;
  }

  /**
   * Deserialize stores states from saved appState
   *
   * @param {Object} appState
   * @public
   */
  @action deserialize(appState) {
    forEach(appState, (storeState, storeName) => {
      this.deserializeStore(this.stores[storeName], storeState);
    });
  }

  /**
   * @param {BaseStore} store
   * @param {*} storeState
   */
  deserializeStore(store, storeState) {
    if (!store || !storeState) {
      return;
    }

    if (store.deserialize) {
      store.deserialize(storeState);
    } else if (isStateTreeNode(store)) {
      applySnapshot(store, storeState);
    } else {
      assignWith(store, storeState, (currentValue, stateValue) => {
        if (this.isStore(currentValue)) {
          this.deserializeStore(currentValue, stateValue);

          return currentValue;
        } else if (isObservableMap(currentValue)) {
          currentValue.replace(stateValue);

          return currentValue;
        } else if (isObservableArray(currentValue)) {
          currentValue.replace(stateValue);

          return currentValue;
        }
      });
    }
  }
}

let appContainer;

export default {
  /**
   * @param {Object} options
   * @returns {Object}
   */
  createApp(options) {
    appContainer = new AppContainer(options);

    return appContainer.context;
  },
  /**
   * @returns {AppContainer}
   */
  get appContainer() {
    return appContainer;
  },
  /**
   * @returns {Object}
   */
  get app() {
    return appContainer.context;
  },
};
