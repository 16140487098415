import { ERROR_CODES } from 'shared/constants';

/**
 * @returns {Promise.<Object>} task object
 */
export function getActive() {
  return this.get('/call-center/tasks/active/', null, {
    dontReport: [ERROR_CODES.notFound],
  });
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @returns {Object} task object
 */
export async function accept({ id }) {
  const response = await this.post(`/call-center/tasks/${id}/accept/`);

  return response.result;
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @param {Object} params.data
 * @returns {Object}
 */
export function invalidMember({ id, data }) {
  return this.post(`/call-center/tasks/${id}/invalid/`, data);
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @param {Object} params.data
 * @returns {Object}
 */
export function fail({ id, data }) {
  return this.post(`/call-center/tasks/${id}/fail/`, data);
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @param {Object} params.data
 * @returns {Object}
 */
export function finish({ id, data }) {
  return this.post(`/call-center/tasks/${id}/finish/`, data);
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @returns {Promise.<void>}
 */
export function heartbeat({ id }) {
  return this.post(`/call-center/tasks/${id}/heartbeat/`, null, {
    dontReport: true,
  });
}

/**
 * @returns {Promise.<Object>}
 */
export function getTasksCount() {
  return this.get('/call-center/task-counts/');
}

/**
 * @param {Object} params
 * @param {number} params.id
 * @param {Object} params.data
 * @param {string} params.data.note
 * @returns {Object}
 */
export function reject({ id, data }) {
  return this.post(`/call-center/tasks/${id}/reject/`, data);
}
