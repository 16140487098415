/**
 * Scroll page to top
 *
 * @param {HTMLElement} node
 */
export default function scrollToTop(node) {
  if (node) {
    node.scrollTop = 0;
  } else {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }
}
