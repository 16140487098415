import { get, isString } from 'lodash';
import { commonLogger } from 'app/logger';
import ApplicationError from 'errors/ApplicationError';

window.addEventListener('unhandledrejection', event => {
  let reason = event;
  try {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    reason = get(event, 'reason') || get(event, 'detail.reason');
  } catch (error) {
    // For some reason Firefox can throw `SecurityError: Permission denied to access property "reason"`
    commonLogger.error(error);
  }

  let error;
  let extra;

  if (reason && reason instanceof Error) {
    error = reason;
  } else if (isString(reason)) {
    error = new ApplicationError(reason);
  } else {
    error = new ApplicationError('Unhandled rejection occurred');
    extra = { reason };
  }

  commonLogger.logError(error, {
    tags: { culprit: 'window.onunhandledrejection' },
    extra,
  });
});
