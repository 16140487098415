import { compact, mapValues } from 'lodash';
import { types } from 'mobx-state-tree';
import ApplicationError from 'errors/ApplicationError';
import { capitalizeName } from 'shared/utils/helpers';
import { transformSnapshot } from 'shared/utils/snapshot';
import { logger } from './BaseModel';

export default types
  .model('Address', {
    city: types.string,
    line1: types.string,
    line2: types.maybeNull(types.string),
    state: types.string,
    zipcode: types.string,
  })
  .preProcessSnapshot(transformSnapshot.pre(snapshot => {
    if (!snapshot.state) {
      logger.logError(new ApplicationError('Received invalid address'), {
        tags: { culprit: 'Address.preProcessSnapshot' },
        extra: { snapshot },
      });
    }

    return {
      ...mapValues(snapshot, capitalizeName),
      state: snapshot.state ? snapshot.state.toUpperCase() : snapshot.state,
    };
  }))
  .views(self => ({
    /**
     * @returns {string}
     */
    get asString() {
      return compact([
        self.line1,
        self.line2,
        self.city,
        `${self.state} ${self.zipcode}`,
      ]).join(', ');
    },
  }));
