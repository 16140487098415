import { types } from 'mobx-state-tree';
import { invert } from 'lodash';
import BaseMSTModel from 'app/models/BaseMSTModel';
import { mapSnapshotProps, transformSnapshot } from 'app/shared/utils/snapshot';

const PRE_MAPPING = {
  acceptedAmount: 'accepted_amount',
  isAmountUnlimited: 'is_amount_unlimited',
};

const POST_MAPPING = invert(PRE_MAPPING);

export default BaseMSTModel
  .named('PlanAccumulator')
  .props({
    amount: types.maybeNull(types.string),
    acceptedAmount: types.maybeNull(types.string),
    isAmountUnlimited: types.maybeNull(types.boolean),
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));
