import { assertMethodExists } from 'shared/utils/assert';

/**
 * Starts `WaitForTransitionEnd` on `push`, `replace` and `go`
 *
 * @param {Function} createHistory
 * @returns {Function}
 */
export default function useWaitForTransitionEnd(createHistory) {
  /**
   * @param {Object} [options={}]
   * @returns {Object}
   */
  return function createHistoryWithWaitForTransitionEnd(options = {}) {
    const history = createHistory(options);

    assertMethodExists(history, 'push');
    assertMethodExists(history, 'replace');
    assertMethodExists(history, 'transitionTo');
    assertMethodExists(history, 'go');

    let transitionEndPromise;

    /**
     * @param {string} historyMethod
     * @param {...*} args
     * @returns {Promise.<TResult>}
     */
    function withWaitForTransitionEnd(historyMethod, args) {
      let fnResult;

      if (!transitionEndPromise) {
        transitionEndPromise = new Promise(resolve => {
          // We need to start the reaction before actually performing transition because it might happen synchronously
          options.onTransitionEndOnce(() => {
            transitionEndPromise = null;
            resolve(fnResult);
          });
        });
      }

      fnResult = history[historyMethod](...args);

      return transitionEndPromise;
    }

    /**
     * @param {...*} args
     * @returns {*}
     */
    function push(...args) {
      return withWaitForTransitionEnd('push', args);
    }

    /**
     * @param {...*} args
     * @returns {*}
     */
    function replace(...args) {
      return withWaitForTransitionEnd('replace', args);
    }

    /**
     * @param {...*} args
     * @returns {*}
     */
    function transitionTo(...args) {
      return withWaitForTransitionEnd('transitionTo', args);
    }

    /**
     * @param {...*} args
     * @returns {*}
     */
    function go(...args) {
      return withWaitForTransitionEnd('go', args);
    }

    return {
      ...history,
      push,
      replace,
      transitionTo,
      go,
    };
  };
}
