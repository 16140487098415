import { get, includes, forEach, isArray } from 'lodash';
import ApplicationError from 'errors/ApplicationError';
import APIError from 'errors/APIError';

/**
 * Check for flag in error
 *
 * @param   {Error}  error
 * @param   {String}  flag
 * @returns {Boolean}
 */
export function hasFlag(error, flag) {
  const flags = get(error, 'data.flags', []);

  return includes(flags, flag);
}

/**
 * Assert required arguments presence
 *
 * @param   {Array} requiredList
 * @param   {Object} passed
 * @param   {String} errorPrefix
 */
export function assertParams(requiredList, passed, errorPrefix) {
  const prefix = errorPrefix ? `${errorPrefix}: ` : '';

  if (!isArray(requiredList) || requiredList.length === 0) {
    throw new ApplicationError(`${prefix}You have to provide not empty list of required parameters into \`assertParams\` assertion`);
  }

  if (!passed) {
    throw new ApplicationError(`${prefix}No parameters passed`);
  }

  forEach(requiredList, paramName => {
    if (!(paramName in passed)) {
      throw new ApplicationError(`${prefix}Required parameter \`${paramName}\` was not provided`);
    }
  });
}

/**
 * Prevent APIError bubbling
 *
 * @param   {Error} error
 * @throws  {Error}
 */
export function rethrowNonAPIError(error) {
  if (!APIError.is(error)) {
    throw error;
  }
}

/**
 * @type {string}
 */
export const WAS_ERROR_LOGGED = 'wasLogged';

/**
 * @param {Error} error
 */
export function markErrorAsLogged(error) {
  Object.defineProperty(error, WAS_ERROR_LOGGED, {
    value: true,
  });
}

/**
 * @param {Error} [error]
 * @return {boolean}
 */
export function wasErrorLogged(error) {
  return Boolean(error) && error[WAS_ERROR_LOGGED];
}

/**
 * @type {string}
 */
export const WAS_ERROR_REPORTED = 'wasReported';

/**
 * @param {Error} error
 */
export function markErrorAsReported(error) {
  Object.defineProperty(error, WAS_ERROR_REPORTED, {
    value: true,
  });
}

/**
 * @param {Error} [error]
 * @return {boolean}
 */
export function wasErrorReported(error) {
  return Boolean(error) && error[WAS_ERROR_REPORTED];
}
