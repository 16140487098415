import history from 'shared//history';

/**
 * @param {Object} location
 * @returns {string}
 */
export default function getLocationPath(location) {
  if (!location) {
    return '';
  }

  if (typeof location === 'string') {
    location = history.createLocation(location);
  }

  return `${location.pathname}${location.search || ''}`;
}
