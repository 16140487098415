import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import MainLayout from 'app/shared/layout/MainLayout/MainLayout';
import Error404 from 'shared/components/Error404/Error404';
import { unwrapSmartComponent } from 'shared/decorators/smartComponent';
import getLazily from './utils/getLazily';
import removeTrailingSlash from './utils/removeTrailingSlash';

const ClaimStatus = () => import('app/pages/ClaimStatus/ClaimStatus');
const Eligibility = () => import('app/pages/Eligibility/Eligibility');
const EmployerPlan = () => import('app/pages/EmployerPlan/EmployerPlan');
const MedicalPlan = () => import('app/pages/MedicalPlan/MedicalPlan');
const Login = () => import('app/pages/Login/Login');
const Logout = () => import('app/pages/Logout/Logout');

export default (
  <Route onEnter={removeTrailingSlash} onChange={removeTrailingSlash}>
    <Route path="/" component={unwrapSmartComponent(MainLayout)}>
      <IndexRedirect to="eligibility" />
      <Route path="eligibility" getComponent={getLazily(Eligibility)} />
      <Route path="claim-status" getComponent={getLazily(ClaimStatus)} />
      <Route path="employer-plan" getComponent={getLazily(EmployerPlan)} />
      <Route path="medical-plan" getComponent={getLazily(MedicalPlan)} />
      <Route path="login" getComponent={getLazily(Login)} />
      <Route path="logout" getComponent={getLazily(Logout)} />
    </Route>
    <Route path="*" component={unwrapSmartComponent(Error404)} />
  </Route>
);
