import { reduce, isObjectLike, noop } from 'lodash';

/**
 * Modify fields that matches predicate function via descriptor function
 *
 * @param   {Object} obj
 * @param   {Function} predicate
 * @param   {Function} descriptor
 * @returns {Object}
 */
export function updateDeep(obj, predicate, descriptor) {
  return reduce(obj, (result, value, key) => {
    if (isObjectLike(value)) {
      result[key] = updateDeep(value, predicate, descriptor);
    } else if (predicate(value)) {
      descriptor(result, key, value);
    } else {
      result[key] = value;
    }

    return result;
  }, {});
}

/**
 * Deeply omit fields in object by predicate
 *
 * @param   {Object} obj
 * @param   {Function} predicate
 * @returns {Object}
 */
export function omitByDeep(obj, predicate) {
  return updateDeep(obj, value => predicate(value), noop);
}

/**
 * Remove deeply all `null` fields from object
 *
 * @param   {Object} obj
 * @returns {Object}
 */
export function omitNullFieldsDeep(obj) {
  return omitByDeep(obj, val => val === null);
}

/**
 * Replace all null fields in object with empty strings
 *
 * @param   {Object} obj
 * @returns {Object}
 */
export function nullToEmptyStringDeep(obj) {
  return updateDeep(obj, value => value === null, (result, key) => {
    result[key] = '';
  });
}
