import { noop, get, unset, find } from 'lodash';
import { createRouterObject } from 'react-router/lib/RouterUtils';
import { createRoutes } from 'react-router/lib/RouteUtils';
import createTransitionManager from 'react-router/lib/createTransitionManager';
import areLocationsSame from 'shared/utils/history/areLocationsSame';
import { createActiveRoutes } from 'entries/shared/activeRoutes';
import Transition from './Transition';

export default class TransitionManager {
  /**
   * @param {Object} options
   * @param {History} options.history
   * @param {Object} options.routes
   * @param {ClientLogger} options.logger
   * @param {Object} [initialRouteState]
   * @param {Function} [onTransitionFlowStart]
   * @param {Function} [onTransitionFlowEnd]
   * @constructor
   */
  constructor(options) {
    this.history = options.history;
    this.rrManager = createTransitionManager(options.history, createRoutes(options.routes));
    this.router = createRouterObject(options.history, this.rrManager, {});
    this.logger = options.logger;
    this.onTransitionFlowStart = options.onTransitionFlowStart || noop;
    this.onTransitionFlowEnd = options.onTransitionFlowEnd || noop;
    this.transitionsMap = new Map();

    this.currentTransition = null;
    this.lastRenderedLocation = null;
    this.currentLocation = null;
    this.prevLocation = null;

    this.initialRouteState = options.initialRouteState;
    this.activeRoutes = null;
  }

  /**
   * @param {Object} options
   * @param {Object} options.routerState
   * @param {boolean} options.isRefresh
   * @returns {ActiveRoute[]}
   */
  setActiveRoutes({ routerState, isRefresh }) {
    const currentActiveRoutes = this.activeRoutes;
    const newActiveRoutes = createActiveRoutes(routerState, this.initialRouteState);

    if (!isRefresh) {
      // Copy state of all unchanged routes from top to bottom
      for (const newActiveRoute of newActiveRoutes) {
        const alreadyActiveRoute = find(currentActiveRoutes, activeRoute => (
          activeRoute.isSameAs(newActiveRoute)
        ));

        // Route has changed, bail out.
        // Even if routes below are the same the tree above them has changed so we can't copy their state.
        if (!alreadyActiveRoute) {
          break;
        }

        newActiveRoute.setState(alreadyActiveRoute.state);
      }
    }

    this.activeRoutes = newActiveRoutes;

    return newActiveRoutes;
  }

  /**
   * @returns {LocationDescriptor|null}
   */
  getCurrentLocation() {
    return this.history.getCurrentLocation();
  }

  /**
   * @param {LocationDescriptor} location
   * @returns {boolean}
   */
  isSameAsLastRenderedLocation(location) {
    return areLocationsSame(this.lastRenderedLocation, location, { compareHash: false });
  }

  /**
   * @returns {boolean}
   */
  isTransitioning() {
    return Boolean(this.currentTransition);
  }

  /**
   * @param {Transition} transition
   */
  endTransition(transition) {
    this.transitionsMap.delete(transition.id);

    const isLastInFlow = transition.isCurrent();
    if (isLastInFlow) {
      this.endTransitionFlow(transition);
    }
  }

  /**
   * @param {Transition} transition
   */
  endTransitionFlow(transition) {
    this.currentTransition = null;
    this.lastRenderedLocation = null;
    this.startingLocation = null;
    this.prevLocation = transition.prevLocation;

    this.onTransitionFlowEnd(transition, this);
  }

  /**
   * @param {LocationDescriptor} location
   * @returns {Transition}
   */
  createOrUpdateTransition(location) {
    const transitionId = get(location.state, Transition.ID_KEY);
    unset(location.state, Transition.ID_KEY);

    // Transition can be already present if there was a redirect from it
    const existingTransition = this.transitionsMap.get(transitionId);
    if (existingTransition) {
      existingTransition.location = location;

      return { isFirstInFlow: false, transition: existingTransition };
    }

    const isFirstInFlow = !this.isTransitioning();
    if (isFirstInFlow && this.currentLocation) {
      this.lastRenderedLocation = this.currentLocation;
    }

    const transition = new Transition({
      history: this.history,
      logger: this.logger,
      transitionManager: this,
      rrManager: this.rrManager,
      router: this.router,
      isFirst: !this.currentLocation,
      isFirstInFlow,
      location,
    });

    this.transitionsMap.set(transition.id, transition);

    return { isFirstInFlow, transition };
  }

  /**
   * Matches location to router state or error.
   * Supports both promise-based and callback-based interfaces
   *
   * Main difference from `react-router`'s `match` is that this one is stateful
   * and doesn't recreate `transitionManager` and `router` object on each call to it
   *
   * @param {LocationDescriptor} location
   * @param {Function} callback
   */
  async match(location, callback) {
    // ignore duplicated transitions
    if (this.isTransitioning && areLocationsSame(location, this.currentLocation)) {
      return;
    }

    const { isFirstInFlow, transition } = this.createOrUpdateTransition(location);
    this.currentTransition = transition;
    this.currentLocation = location;

    if (isFirstInFlow) {
      await Promise.resolve(this.onTransitionFlowStart(transition, this));
    }

    transition.perform(callback);
  }

  /**
   * @param {Function} listener
   * @returns {Function} unlisten
   */
  listen(listener) {
    const { history } = this;

    const unlisten = history.listen(location => {
      this.match(location, listener);
    });

    this.match(history.getCurrentLocation(), listener);

    return unlisten;
  }
}
