import { types } from 'mobx-state-tree';
import { transformSnapshot } from 'shared/utils/snapshot';
import parseDate from 'shared/utils/date/parseDate';
import formatDate from 'shared/utils/date/formatDate';
import { DATE_FORMATS } from 'shared/constants';
import ServiceType from './ServiceType';

export default types
  .model('Service', {
    date: types.Date,
    cost: types.number,
    quantity: types.number,
    valueDiscount: types.maybeNull(types.number),
    percentDiscount: types.maybeNull(types.number),
    type: ServiceType,
  })
  .preProcessSnapshot(transformSnapshot.pre(snapshot => ({
    ...snapshot,
    date: parseDate(snapshot.date, DATE_FORMATS.server),
    // TODO: check can MST convert strings to number automatically
    cost: Number(snapshot.cost),
    valueDiscount: snapshot.value_discount ? Number(snapshot.value_discount) : null,
    percentDiscount: snapshot.percent_discount ? Number(snapshot.percent_discount) : null,
  })))
  .postProcessSnapshot(transformSnapshot.post(snapshot => ({
    ...snapshot,
    date: formatDate(snapshot.date, DATE_FORMATS.server),
    value_discount: snapshot.valueDiscount,
    percent_discount: snapshot.percentDiscount,
  })))
  .views(self => ({
    /**
     * @return {number}
     */
    get totalCostCents() {
      // calcutate in cents to avoid rounding issues
      const CENTS_PER_USD = 100;
      let costCents = self.cost * CENTS_PER_USD * self.quantity;
      if (self.valueDiscount) {
        costCents -= self.valueDiscount * CENTS_PER_USD;
      } else if (self.percentDiscount) {
        costCents *= 1 - self.percentDiscount / 100;
      }

      return costCents;
    },
  }));
