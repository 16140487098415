/**
 * @param {Object} params
 * @param {string} params.taskId
 * @param {number} params.id relationship id
 * @param {Object} params.data
 * @returns {Object}
 */
export function update({ taskId, id, data }) {
  return this.patch(`/call-center/tasks/${taskId}/relationships/${id}/`, data);
}

/**
 * @param {Object} params
 * @param {string} params.taskId
 * @returns {Object}
 */
export function create({ taskId }) {
  return this.post(`/call-center/tasks/${taskId}/relationships/`, {});
}

/**
 * @param {Object} params
 * @param {string} params.taskId
 * @param {number} params.id relationship id
 * @returns {Object}
 */
export function remove({ taskId, id }) {
  return this.delete(`/call-center/tasks/${taskId}/relationships/${id}/`);
}
