import t from 'tcomb-form-plus';
import { isString, isArrayLike } from 'lodash';
import { patchTcombFactory } from 'shared/components/Form/decorators/observerFactory';

const { form } = t;

form.Component.prototype.hasError = function hasError() {
  if (this.state.hasError) {
    return true;
  }

  return this.hasManualError();
};

/**
 * Logic for errors displaying
 *
 * @returns {Boolean}
 */
form.Component.prototype.isErrorVisible = function isErrorVisible() {
  if (this.hasManualError()) {
    return true;
  }

  if (!this.hasError()) {
    return false;
  }

  const { formStore } = this.props.ctx.config;
  if (formStore && formStore.isSubmitTried) {
    return true;
  }

  return this.state.isTouched;
};

const originalShouldComponentUpdate = form.Component.prototype.shouldComponentUpdate;
form.Component.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps, nextState) {
  return originalShouldComponentUpdate.call(this, nextProps, nextState) ||
    // `isTouched` is our custom prop and it doesn't respected in default tcomb `sCU` implementation
    this.state.isTouched !== nextState.isTouched;
};

form.Component.prototype.hasManualError = function hasManualError() {
  const { hasError } = this.props.options;
  if (t.Function.is(hasError)) {
    const { path } = this.getValidationOptions();

    return hasError(path);
  }

  return this.props.options.hasError;
};

form.Component.prototype.getError = function getError() {
  if (this.hasError()) {
    const errorGetter = this.props.options.error;
    if (isString(errorGetter)) {
      return errorGetter;
    }

    if (isArrayLike(errorGetter)) {
      return errorGetter.join(' ');
    }

    const { path, context } = this.getValidationOptions();
    const value = this.getValue();

    if (t.Function.is(errorGetter)) {
      const customErrorMessage = errorGetter(value, path, context);
      if (customErrorMessage) {
        return customErrorMessage;
      }
    }

    if (t.Function.is(this.typeInfo.getValidationErrorMessage)) {
      return this.typeInfo.getValidationErrorMessage(value, path, context);
    }
  }
};

/**
 * Add context to subtype validation predicate calls
 */
const originalSubtypeValidator = t.validate.validators.subtype;
t.validate.validators.subtype = (x, type, path, options) => {
  const ret = originalSubtypeValidator(x, type, path, options);

  // x should satisfy the predicate with given context
  if (ret.errors.length === 0 && !type.meta.predicate(ret.value, options.context, path)) {
    ret.errors = [t.ValidationError.of(x, type, path, options.context)];
  }

  return ret;
};

/**
 * This generic factories may use observables during render
 */
patchTcombFactory(form.List);
patchTcombFactory(form.Struct);
