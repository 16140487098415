import { types, flow, addDisposer, applySnapshot } from 'mobx-state-tree';
import { invert, isEmpty, filter, keyBy, first, find } from 'lodash';
import { negate, prop } from 'lodash/fp';
import ms from 'ms';
import { transformSnapshot, mapSnapshotProps } from 'shared/utils/snapshot';
import createInterval from 'shared/utils/createInterval';
import BaseTaskModel from 'app/models/BaseTaskModel';
import Provider from 'app/models/Provider';
import Member from 'app/models/Member';
import FullInsurer from 'app/models/FullInsurer';
import SourceRelationship from 'app/models/SourceRelationship';
import Relationship from 'app/models/Relationship';
import PlanTemplate from 'app/models/PlanTemplate';
import parseDate from 'shared/utils/date/parseDate';
import { DATE_FORMATS } from 'shared/constants';
import formatDate from 'shared/utils/date/formatDate';

/**
 * @param {Relationship[]|null|undefined} relationships
 * @returns {Relationship[]|null|undefined}
 */
function sortRelationshipsByType(relationships) {
  if (isEmpty(relationships)) {
    return;
  }

  const selfs = filter(relationships, relationship => relationship.relationship_type_name === 'self');
  const spouses = filter(relationships, relationship => relationship.relationship_type_name === 'spouse');
  const children = filter(relationships, relationship => relationship.relationship_type_name === 'child');
  const others = filter(relationships, relationship => (
    relationship.relationship_type_name === 'other' || !relationship.relationship_type_name
  ));

  return [
    ...selfs,
    ...spouses,
    ...children,
    ...others,
  ];
}

const HEARTBEAT_INTERVAL = ms('40s');

const PRE_MAPPING = {
  planTemplate: 'plan_template',
  createdAt: 'created_at',
  isUrgent: 'is_urgent',
  isRejected: 'is_rejected',
  isEmployerPlanRequest: 'is_employer_plan_request',
  isPilot: 'is_pilot',
  requestCode: 'request_code',
  sourceRelationships: 'source_relationships',
};
const POST_MAPPING = invert(PRE_MAPPING);

const PlanTask = BaseTaskModel
  .named('EligibilityTask')
  .props({
    provider: types.maybeNull(Provider),
    member: types.maybeNull(Member),
    insurer: FullInsurer,
    planTemplate: types.maybeNull(PlanTemplate),
    relationships: types.optional(types.array(Relationship), []),
    sourceRelationships: types.optional(types.array(SourceRelationship), []),
    selectedRelationship: types.maybeNull(types.reference(Relationship, ({
      get(id, self) {
        return find(self.relationships, { id });
      },
      set(relationship) {
        return relationship.id;
      },
    }))),
    startedAt: types.optional(types.Date, () => new Date()),
    createdAt: types.maybeNull(types.Date),
    isUrgent: types.optional(types.boolean, false),
    isRejected: types.optional(types.boolean, false),
    isEmployerPlanRequest: types.optional(types.boolean, false),
    isPilot: types.optional(types.boolean, false),
    requestCode: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
  })
  .views(self => ({
    /**
     * @returns {Object} dict of relationships by id
     */
    get relationshipsMap() {
      return keyBy(self.relationships, prop('id'));
    },
    /**
     * @returns {Relationship|null}
     */
    get selfRelationship() {
      return find(self.relationships, relationship => relationship.isTypeSelf) || null;
    },
    get age() {
      return Date.now() - self.createdAt.getTime();
    },
    get isMedical() {
      return self.insurer.isMedical;
    },
    get nextUnsubmittedRelationship() {
      const { selectedRelationship } = self;
      if (!selectedRelationship) {
        return null;
      }

      const relationships = self.relationships.peek();
      const selectedRelationshipIndex = relationships.indexOf(selectedRelationship);
      const fromIndex = selectedRelationshipIndex + 1;
      const isUnsubmitted = negate(prop('isSubmitted'));

      // Try to find relationship after current one
      let nextRelationship = find(relationships, isUnsubmitted, fromIndex);
      // If failed try to find relationship before current one
      if (!nextRelationship) {
        nextRelationship = find(relationships, isUnsubmitted);
      }

      return nextRelationship || null;
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      relationships: sortRelationshipsByType(snapshot.relationships),
      createdAt: parseDate(snapshot.createdAt, DATE_FORMATS.serverIso),
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(
    snapshot => ({
      ...snapshot,
      createdAt: formatDate(snapshot.createdAt, DATE_FORMATS.serverIso),
    }),
    mapSnapshotProps(POST_MAPPING),
  ))
  .actions(self => {
    function afterCreate() {
      const firstRelationship = first(self.relationships);
      if (firstRelationship) {
        self.selectRelationship(firstRelationship.id);
      }

      addDisposer(self, self.stopHeartbeat);
    }

    const createRelationship = flow(function* createRelationship() {
      const taskId = self.id;
      const newRelationship = yield self.api.call('relationship.create', { taskId });
      newRelationship.isNew = true;
      self.relationships.push(newRelationship);

      return self.relationshipsMap[newRelationship.id];
    });

    /**
     * @param {number} relationshipId
     */
    function selectRelationship(relationshipId) {
      self.selectedRelationship = relationshipId;
    }

    /**
     * @param {Relationship} relationship
     */
    const removeRelationship = flow(function* removeRelationship(relationship) {
      const taskId = self.id;
      yield self.api.call('relationship.remove', {
        taskId,
        id: relationship.id,
      });

      const prevRelationshipIndex = self.relationships.indexOf(self.selectedRelationship) - 1;
      self.selectedRelationship = self.relationships[prevRelationshipIndex] || null;
      self.relationships.remove(relationship);
    });

    const heartbeatInterval = createInterval(() => self.heartbeat(), HEARTBEAT_INTERVAL, {
      immediate: true,
    });

    return {
      afterCreate,
      createRelationship,
      removeRelationship,
      selectRelationship,
      startHeartbeat: heartbeatInterval.start,
      stopHeartbeat: heartbeatInterval.stop,
      heartbeat: flow(function* checkHeartbeat() {
        try {
          yield self.api.call('task.heartbeat', { id: self.id });
        } catch (error) {
          self.logger.logError(error, {
            tags: {
              culprit: 'EligibilityTask.heartbeat',
            },
          });
        }
      }),
      onAcceptTaskRequest: flow(function* onAcceptTaskRequest() {
        yield self.api.call('task.accept', {
          id: self.id,
        });
      }),
      complete: flow(function* complete() {
        self.stopHeartbeat();

        yield self.api.call('task.finish', {
          id: self.id,
          data: {
            note: self.note,
          },
        });
      }),
      fail: flow(function* fail(payload) {
        self.stopHeartbeat();

        yield self.api.call('task.fail', {
          id: self.id,
          data: {
            note: self.note,
            ...payload,
          },
        });
      }),
      reject: flow(function* reject() {
        self.stopHeartbeat();

        yield self.api.call('task.reject', {
          id: self.id,
          data: {
            note: self.note,
          },
        });
      }),
      reportInvalidMember: flow(function* reportInvalidMember(payload) {
        self.stopHeartbeat();

        yield self.api.call('task.invalidMember', {
          id: self.id,
          data: {
            note: self.note,
            ...payload,
          },
        });
      }),
      updateRelationship: flow(function* updateRelationship(relationship, payload) {
        const updatedServerRelationship = yield self.api.call('relationship.update', {
          taskId: self.id,
          id: relationship.id,
          data: payload,
        });

        applySnapshot(relationship, updatedServerRelationship);
      }),
      setNote(note) {
        self.note = note;
      },
    };
  });

export default PlanTask;
