import { isError, find } from 'lodash';
import { assignRouterState } from 'react-router/lib/RouterUtils';
import { REFRESH, PUSH, POP } from 'shared/history/Actions';
import RedirectError from 'errors/RedirectError';
import RouteCancellationError from 'errors/RouteCancellationError';
import CancellationError from 'errors/CancellationError';
import getLocationPath from 'shared/utils/history/getLocationPath';

const createUniqueId = () => Math.random().toString(36).substr(2, 6);

export default class Transition {
  static ID_KEY = 'transitionId';

  /**
   * @param {Object} options
   * @param {ReactRouterTranstionManager} rrManager
   * @param {History} history
   * @param {Object} router
   * @param {ClientLogger} logger
   * @param {TransitionManager} transitionManager
   * @param {LocationDescriptor} location
   * @param {boolean} isFirst
   * @param {boolean} isFirstInFlow
   */
  constructor(options) {
    this.id = createUniqueId();
    this.rrManager = options.rrManager;
    this.history = options.history;
    this.router = options.router;
    this.logger = options.logger;
    this.transitionManager = options.transitionManager;
    this.startingLocation = options.location;
    this.location = options.location;
    this.prevLocation = this.getPrevLocation(options.location, options.transitionManager);
    this.activeRoutes = null;
    this.routerState = null;

    // Whether this transition is very first ever
    this.isFirst = options.isFirst;
  }

  end() {
    this.transitionManager.endTransition(this);
  }

  /**
   * Cases:
   * 1. Start location in this transition was done with `PUSH`(history.push) or
   *   `POP`(back/forward browser buttons) action.
   *   - In this case `prevLocation` is equal to `lastRenderedLocation` which is last rendered page.
   * 2. Start location in this transitio was done with `REPLACE`(history.replace) action.
   *   - In this case `prevLocation` is equal to `prevLocation` which is the latest
   *     `lastRenderedLocation` that was changed using `PUSH` or `POP`
   *
   * We save `prevLocation` into `transitionManager.prevLocation`
   * on end of transition flow from the latest transition.
   *
   * @param {LocationDescriptor} location
   * @param {TransitionManager} transitionManager
   * @returns {LocationDescriptor|null}
   */
  getPrevLocation(location, transitionManager) {
    const locationAction = location.action;

    let prevLocation = null;
    if (locationAction === PUSH || locationAction === POP) {
      prevLocation = transitionManager.lastRenderedLocation;
    } else {
      prevLocation = transitionManager.prevLocation;
    }

    return prevLocation;
  }

  /**
   * @returns {string}
   */
  getLocationPath() {
    return getLocationPath(this.location);
  }

  /**
   * @returns {boolean}
   */
  isRefresh() {
    return this.location.action === REFRESH;
  }

  /**
   * @returns {boolean}
   */
  isCurrent() {
    return this.transitionManager.currentTransition === this;
  }

  /**
   * @param {RedirectError} error
   */
  handleRedirectError(error) {
    error.setFrom(this.location);

    this.logger.debug(error.message);
    const { to } = error;

    this.history.replace({
      ...to,
      state: {
        ...to.state,
        [Transition.ID_KEY]: this.id,
      },
    });
  }

  /**
   * @throws {RouteCancellationError}
   */
  assertTransitionCurrent = () => {
    if (!this.isCurrent()) {
      throw new RouteCancellationError();
    }
  };

  /**
   * @param {RouteCancellationError} error
   * @param {string} path
   */
  handleRouteCancellationError(error) {
    error.setLocation(this.location);
    this.logger.debug(error.message);
  }

  /**
   * @param {Error} error
   */
  handleTransitionError(error) {
    const { location } = this;

    if (!this.isCurrent()) {
      this.handleRouteCancellationError(new RouteCancellationError(null, location));
    } else if (RouteCancellationError.is(error)) {
      this.handleRouteCancellationError(error);
    } else if (CancellationError.is(error)) {
      this.handleRouteCancellationError(new RouteCancellationError(error.message, location));
    } else if (RedirectError.is(error)) {
      this.handleRedirectError(error);
    }

    if (!RedirectError.is(error)) {
      this.end();
    }
  }

  /**
   * @param {Error} error
   * @returns {boolean}
   */
  isTransitionError(error) {
    return (
      !this.isCurrent() ||
      RouteCancellationError.is(error) ||
      RedirectError.is(error)
    );
  }

  /**
   * @param {Object} nextState
   * @returns {Error|null}
   */
  getComponentsLoadError(nextState) {
    if (!nextState) {
      return null;
    }

    return find(nextState.components, isError) || null;
  }

  /**
   * @param {Object} routerState
   */
  setState(routerState) {
    this.routerState = routerState;

    const { transitionManager } = this;
    const newActiveRoutes = transitionManager.setActiveRoutes({
      routerState,
      isRefresh: this.isRefresh(),
    });

    this.activeRoutes = newActiveRoutes;
  }

  resetState() {
    this.activeRoutes = null;
    this.routerState = null;
  }

  /**
   * @param {Function} callback
   */
  perform(callback) {
    if (!this.isCurrent()) {
      this.end();
    }

    this.resetState();
    const { location } = this;

    this.rrManager.match(location, (error, redirectLocation, routerState) => {
      let componentsLoadError = null;
      const transition = this;

      // Check if transition changed during fetching of js chunks
      if (!this.isCurrent()) {
        callback(transition, new RouteCancellationError(null, location));
        // history error
      } else if (error) {
        callback(transition, error);
        // at least one of js chunks could not be loaded
      } else if ((componentsLoadError = this.getComponentsLoadError(routerState))) {
        callback(transition, componentsLoadError);
        // internal react-router redirect via e.g. `IndexRedirect`
      } else if (redirectLocation) {
        callback(transition, new RedirectError(redirectLocation, location));
        // first location change in flow was to the same location(excluding hash)
      } else if (
        routerState &&
        this.isFirstInFlow &&
        location === this.startingLocation &&
        this.transitionManager.isSameAsLastRenderedLocation(location)
      ) {
        callback(transition, new RouteCancellationError(null, location));
        // standard case: current transition, no redirects, no errors, changed location
      } else if (routerState) {
        routerState = { ...routerState, router: this.router };
        assignRouterState(this.router, routerState);
        this.setState(routerState);
        callback(transition, null);
      }
    });
  }
}
