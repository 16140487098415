import {
  isPlainObject,
  isString,
  isArray,
  isArrayLike,
  filter,
  get,
  compact,
  reduce,
  union,
  assign,
  values,
  omit,
  isEmpty,
  castArray,
  forEach,
  isRegExp,
  has,
  pickBy,
} from 'lodash';
import { compose } from 'lodash/fp';
import { isUnfilled } from 'shared/utils/helpers';
import ApplicationError from 'app/errors/ApplicationError';

export const ERROR_MAX_LENGTH = 200;

const ERRORS_KEY = '$';

/**
 * Map API error details to form fields
 *
 * @param  {Error|Object} error
 * @param  {String} extra.defaultKey Field which display error without field
 * @param  {Object} extra.mapping Map several fields errors into one. For example
 *   { expiration: ['expiration_month', 'expiration_year'] }
 * @param  {Object} extra.fields
 * @returns {Object}
 */
export default function mapErrorToFields(error, { defaultKey, mapping, fields } = {}) {
  if (!error) {
    return;
  }

  const { message, data } = error;

  // Check for unexpected error format
  if (!isPlainObject(data) || isUnfilled(data)) {
    if (!defaultKey) {
      return {};
    }

    let errorText = 'Unknown error';

    if (isString(data) && data.length > 0) {
      errorText = data;
    } else if (isString(message) && message.length > 0) {
      errorText = message;
    }

    let errorTextWithoutHTML = errorText
    // strip style tags with content
      .replace(/<style[^<]*<\/style[^>]*>/ig, '')
      // strip script tags with content
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/ig, '')
      // strip all other tags but keep content
      .replace(/<[^>]+>/ig, '');

    if (errorTextWithoutHTML.length > ERROR_MAX_LENGTH) {
      const stub = '...';
      errorTextWithoutHTML = errorTextWithoutHTML.substr(0, ERROR_MAX_LENGTH - stub.length) + stub;
    }

    return {
      [defaultKey]: [errorTextWithoutHTML],
    };
  }

  const errorData = normalizeDataFormat(data);
  const allFieldsErrors = getFieldsErrors(errorData.errors);

  let fieldsErrors = omit(allFieldsErrors, [ERRORS_KEY]);
  if (fields) {
    const extractedFields = extractFields(fields);
    fieldsErrors = pickBy(fieldsErrors, (field, fieldKey) => has(extractedFields, fieldKey));
  }

  if (mapping && !isEmpty(allFieldsErrors)) {
    // fieldsToMap is an array of BE fields paths(or regexps)
    fieldsErrors = reduce(mapping, (acc, fieldsToMap, target) => {
      forEach(fieldsToMap, field => {
        if (isRegExp(field)) {
          forEach(allFieldsErrors, (errors, key) => {
            if (field.test(key)) {
              const newKey = key.replace(field, target);
              appendErrors(acc, newKey, errors);
            }
          });
        } else if (isString(field)) {
          appendErrors(acc, target, allFieldsErrors[field]);
        } else if (process.env.NODE_ENV !== 'production') {
          throw new ApplicationError(`Expected a string or a regexp in mapping for "${target}". Instead got: "${field}"`);
        }
      });

      return acc;
    }, { ...fieldsErrors });
  }

  if (defaultKey) {
    let topLevelErrors = allFieldsErrors[ERRORS_KEY];
    // Only use default message if there are no top level fields errors
    if (!topLevelErrors || !topLevelErrors.length) {
      if (message) {
        topLevelErrors = [message];
      } else if (isEmpty(fieldsErrors)) {
        topLevelErrors = [data.detail];
      }
    }

    appendErrors(fieldsErrors, defaultKey, topLevelErrors);
  }

  return fieldsErrors;
}

/**
 * Extract common error message
 *
 * @param   {Error} error
 * @param   {Object} [options]
 * @returns {string}
 */
export function getCommonError(error, options) {
  const defaultKey = get(options, 'defaultKey') || 'common';
  const result = mapErrorToFields(error, {
    ...options,
    defaultKey,
  });

  return fieldErrorsToString(result[defaultKey]);
}

/**
 * Extract all errors
 *
 * @param   {Error|Object} error
 * @param   {Object} options
 * @returns {String[]}
 */
export function getAllErrors(error, options) {
  const result = mapErrorToFields(error, {
    defaultKey: 'common',
    ...options,
  });

  return union(...values(result));
}

/**
 * Extract error string
 *
 * @param   {Error} error
 * @param   {Object} options
 * @returns {String}
 */
export const getAllErrorsString = compose(fieldErrorsToString, getAllErrors);

/**
 * @param {string|string[]|ObservableArray<string>} [fieldErrors]
 * @returns {string}
 */
export function fieldErrorsToString(fieldErrors) {
  if (isString(fieldErrors)) {
    return fieldErrors;
  }

  if (isArrayLike(fieldErrors)) {
    return filter(fieldErrors, isString).join(' ');
  }

  return '';
}

/**
 * Initialize array in given key and append values to it. Duplicates will me omitted.
 *
 * @param   {Object} target
 * @param   {String} fieldName
 * @param   {Array} values
 */
function appendErrors(target, fieldName, values) {
  const errors = compact(union(target[fieldName], values));

  if (errors && errors.length > 0) {
    target[fieldName] = errors;
  }
}

/**
 * @param {Object} field
 * @returns {string[]|undefined}
 */
function getFieldErrors(field) {
  let fieldErrors;
  if (isArray(field)) {
    fieldErrors = field;
  } else {
    fieldErrors = get(field, ERRORS_KEY);
  }

  return fieldErrors || [];
}

/**
 * @param {Object} errorsObj
 * @param {string} rootKey
 * @returns {Object}
 */
function getFieldsErrors(errorsObj, rootKey = '') {
  if (isEmpty(errorsObj) || !isPlainObject(errorsObj)) {
    return {};
  }

  return reduce(errorsObj, (acc, field, fieldKey) => {
    const fullKey = compact([rootKey, fieldKey]).join('.');

    const fieldErrors = getFieldErrors(field);
    if (isArray(fieldErrors) && fieldErrors.length) {
      const key = (fieldKey === ERRORS_KEY && rootKey) ? rootKey : fullKey;
      appendErrors(acc, key, fieldErrors);
    }

    if (fieldKey === ERRORS_KEY) {
      return acc;
    }

    return assign(acc, getFieldsErrors(field, fullKey));
  }, {});
}

/**
 * Turns fields options into a structure,
 * where any nested field can be found via lodash `get` or `has`
 *
 * @example
 * input:
 * {
 *   template: TopTemplate,
 *   fields: {
 *     phone: { placeholder: '12345' },
 *     address: {
 *       template: AddressTemplate,
 *       fields: {
 *         line1: { placeholder: 'Address 1' },
 *         line2: { placeholder: 'Address 2' },
 *       }
 *     }
 *   }
 * }
 *
 * output:
 * {
 *   phone: { placeholder: '12345' },
 *   address: {
 *     line1: { placeholder: 'Address 1' },
 *     line2: { placeholder: 'Address 2' },
 *   }
 * }
 *
 * So
 * `has(output, 'address.line1')` works
 *
 * @param {Object} fields
 * @returns {Object}
 */
function extractFields(fields) {
  if (!fields) {
    return {};
  }

  if (fields.fields) {
    return extractFields(fields.fields);
  }

  return reduce(fields, (acc, field, fieldKey) => {
    if (field && field.fields) {
      return assign(acc, extractFields(field.fields));
    }

    acc[fieldKey] = field;

    return acc;
  }, {});
}

/**
 * Old format:
 * {
 *   'detail': 'Global error',
 *   'errors': {
 *     messages: ['Top level error'],
 *   },
 *   'field_errors': {
 *     'patient': {
 *       'name': ['patient.name error'],
 *     }
 *   }
 * }
 *
 * New format:
 * {
 *   'detail': 'Global error',
 *   'errors': {
 *     '$': ['Top level error'],
 *     'patient': {
 *       '$': ['patient error'], // would not be possible to add in the old format
 *       'name': {
 *         '$': ['patient.name error'],
 *       }
 *     }
 *   }
 * }
 *
 *
 * @param {Object} data
 * @returns {*}
 */
function normalizeDataFormat(data) {
  if (!data) {
    return {};
  }

  const isOldFormat = ('field_errors' in data) || Array.isArray(get(data.errors, 'messages'));
  if (!isOldFormat) {
    return data;
  }

  const topLevelErrors = get(data.errors, 'messages') || [];
  const fieldErrors = normalizeFieldErrorsFormat(data.field_errors);

  return {
    ...omit(data, ['field_errors', 'errors']),
    errors: {
      [ERRORS_KEY]: topLevelErrors,
      ...fieldErrors,
    },
  };
}

/**
 * @param {Object} field
 * @returns {Object}
 */
function normalizeFieldErrorsFormat(field) {
  return reduce(field, (acc, value, fieldName) => {
    if (isPlainObject(value)) {
      acc[fieldName] = normalizeFieldErrorsFormat(value);
    } else {
      acc[fieldName] = {
        [ERRORS_KEY]: compact(castArray(value)),
      };
    }

    return acc;
  }, {});
}
