/**
 * Native `window.location.origin` is not present in every browser
 *
 * @param {Object} [location=window.location]
 * @returns {string}
 */
export default function getWindowLocationOrigin(location = window.location) {
  const { origin, protocol, hostname, port } = location;
  if (origin) {
    return origin;
  }

  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
}
