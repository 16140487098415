import { forEach, isNil, isPlainObject } from 'lodash';
import Logger from 'app/logger';
import ApplicationError from 'errors/ApplicationError';
import getLocationPath from 'shared/utils/history/getLocationPath';

const logger = new Logger({
  prefix: 'DataFetcher',
  prefixStyle: 'color:#0772a1',
});

/**
 * Base DataFetcher class
 */
export default class DataFetcher {
  /**
   * @constructor
   * @param {Object} context
   */
  constructor(context) {
    this.context = context;
    this.logger = logger;
  }

  /**
   * Determines whether route needs to fetch data
   *
   * @param {ActiveRoute} activeRoute
   * @returns {boolean}
   */
  isRouteNeedsData(activeRoute) {
    const { Component } = activeRoute;

    return Boolean(Component && Component.fetchData);
  }

  /**
   * Helpful warnings for development/testing environments
   *
   * @param {ActiveRoute} activeRoute
   * @param {Object} routeData
   * @private
   */
  warnIncorrectUsage(activeRoute) {
    if (!process.env.isProd) {
      const { logger } = this;
      const { Component, routeName } = activeRoute;

      if (Component.processData && !Component.fetchData) {
        logger.warn(
          `Found \`processData\` but not \`fetchData\` on "${routeName}".
         Use \`beforeTransitionTo\` if you need to set data to stores without fetching it.`
        );
      } else if (Component.fetchData && !Component.processData) {
        logger.warn(
          `Found \`fetchData\` but not \`processData\` on "${routeName}".
           \`fetchData\` should be pure function and should not save data to stores.
           It's \`processData\`s responsibility.`
        );
      }
    }
  }

  /**
   * Fetches route data
   *
   * @param {ActiveRoute} activeRoute
   * @returns {Promise.<{ data: * }>}
   * @private
   */
  async getRouteData(activeRoute) {
    if (!this.isRouteNeedsData(activeRoute)) {
      return null;
    }

    const { context, logger } = this;
    const { Component, routeName, routeContext } = activeRoute;

    logger.debug(`Calling \`fetchData\` for "${routeName}" component`);

    const data = await Promise.resolve(Component.fetchData(context, routeContext));

    return { data };
  }

  /**
   * Processes route data
   *
   * @param {Object} [routeData]
   * @param {ActiveRoute} activeRoute
   * @private
   */
  processRouteData(routeData, activeRoute) {
    const { Component, routeName, routeContext } = activeRoute;

    if (!Component) {
      return;
    }

    this.warnIncorrectUsage(activeRoute, routeData);

    let { context, logger } = this;

    context = {
      ...context,
      setFetchedDataProp(fetchedDataProp) {
        if (!isNil(fetchedDataProp) && !isPlainObject(fetchedDataProp)) {
          throw new ApplicationError(
            `You have to provide plain object to \`setFetchedDataProp\` call. See \`processData\` static on ${routeName} component`
          );
        }

        activeRoute.setState({ fetchedDataProp });
      },
    };

    if (Component.processData && routeData) {
      logger.debug(`Calling \`processData\` for "${routeName}" component`);
      Component.processData(context, routeData.data, routeContext);
    }

    if (Component.beforeTransitionTo) {
      logger.debug(`Calling \`beforeTranstionTo\` for "${routeName}" component`);
      Component.beforeTransitionTo(context, routeContext);
    }
  }

  /**
   * @param {ActiveRoute[]} activeRoutes
   * @param {ActiveRoute} activeRoute
   * @param {Error} error
   * @throws {Error}
   */
  addErrorType(activeRoutes, activeRoute, error) {
    const activeRouteIndex = activeRoutes.indexOf(activeRoute);

    let i = activeRouteIndex;
    while (--i >= 0) {
      const { Component } = activeRoutes[i];
      if (Component && Component.errorType) {
        error.errorType = Component.errorType;
        break;
      }
    }

    throw error;
  }

  /**
   * Fetches data for changed routes
   *
   * @param {Object} options
   * @param {Object} options.routerState
   * @param {ActiveRoute[]} options.activeRoutes
   * @returns {Promise}
   * @public
   */
  fetch(options) {
    const { routerState, activeRoutes } = options;
    const path = getLocationPath(routerState.location);
    this.logger.debug(`Fetching data for path "${path}"`);

    return Promise.map(activeRoutes, async activeRoute => {
      try {
        return await Promise.resolve(this.getRouteData(activeRoute, options));
      } catch (error) {
        this.addErrorType(activeRoutes, activeRoute, error);

        throw error;
      }
    });
  }

  /**
   * Processes data for changed routes
   *
   * @param {(null|{ data: * })[]} routesData
   * @param {Object} options
   * @param {ActiveRoute[]} options.activeRoutes
   * @public
   */
  process(routesData, options) {
    const { activeRoutes } = options;

    forEach(activeRoutes, (activeRoute, index) => {
      try {
        this.processRouteData(routesData[index], activeRoute, options);
      } catch (error) {
        this.addErrorType(activeRoutes, activeRoute, error);

        throw error;
      }
    });
  }
}
