import { isFunction } from 'lodash';

/**
 * @param {Object} nextState
 * @param {Function|Object} replace
 */
export default function removeTrailingSlash(nextState, replace) {
  const { location } = nextState;
  const path = location.pathname;

  if (isFunction(replace) && path !== '/' && path.slice(-1) === '/') {
    replace({
      ...location,
      pathname: path.slice(0, -1),
    });
  }
}
