import { mapValues, map, isArray, isPlainObject, isNil } from 'lodash';

/**
 * @param {*} value
 * @returns {null|*}
 */
export function nullifyOptional(value) {
  if (isNil(value) || value === '') {
    return null;
  }

  return value;
}

/**
 * @param {*} src
 * @returns {*}
 */
export default function nullifyOptionalDeep(src) {
  if (isPlainObject(src)) {
    return mapValues(src, nullifyOptionalDeep);
  } else if (isArray(src)) {
    return map(src, nullifyOptionalDeep);
  }

  return nullifyOptional(src);
}
