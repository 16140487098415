import 'styles/global/main.css';
import appConfig from 'app/config';
import createApp from 'entries/shared/createApp';
import AppContainer from 'shared/AppContainer/AppContainer';
import startApp from './startApp';

const app = createApp();

if (process.env.EXPOSE_INTERNALS) {
  window.app = app;
  window.config = appConfig;
}

// Enable Hot Module Loading
if (module.hot) {
  module.hot.accept();

  // Save application state(data in stores) to restore it after hot update
  module.hot.dispose(data => {
    data.appState = AppContainer.appContainer.serialize();
  });

  // Restore stores state after hot reload
  if (module.hot.data && module.hot.data.appState) {
    AppContainer.appContainer.deserialize(module.hot.data.appState);
    module.hot.data.appState = null;
  }
}

startApp(app);
