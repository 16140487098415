import { types } from 'mobx-state-tree';
import { values, invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import Patient from './Patient';
import Member from './Member';
import { UI_RELATIONSHIP_TYPES } from './Relationship';
import { RELATIONSHIP_TYPES } from './SourceRelationship';

const PRE_MAPPING = {
  member: 'insurance_member',
  typeName: 'relationship_type_name',
};
const POST_MAPPING = invert(PRE_MAPPING);

const RelationshipDetails = types
  .model('RelationshipDetails', {
    id: types.identifierNumber,
    patient: Patient,
    member: Member,
    typeName: types.maybeNull(types.enumeration(values(RELATIONSHIP_TYPES))),
  })
  .views(self => ({
    get isTypeSelf() {
      return self.typeName === RELATIONSHIP_TYPES.self;
    },
    get uiTypeName() {
      return UI_RELATIONSHIP_TYPES[self.typeName];
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));

export default RelationshipDetails;
