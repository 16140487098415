import Raven from 'raven-js';
import fecha from 'fecha';
import { once } from 'lodash';
import { commonLogger } from 'app/logger';

/**
 * @param {string} date
 * @param {string} format
 * @returns {Date|null}
 */
export default function parseDate(date, format) {
  try {
    if (!date) {
      return null;
    } else if (date instanceof Date) {
      return date;
    }

    let result = fecha.parse(date, format);

    // Convert to local time only dates with timestamp and formats that preserve time
    if (date.length > 10 && format.length > 10) {
      result = convertUtcToLocalDate(result);
    }

    return result;
  } catch (err) {
    commonLogger.error(err, { date, format });
    Raven.captureException(err, {
      extra: {
        date,
        format,
      },
    });

    return null;
  }
}

/**
 * Calc local timezone offset from UTC
 *
 * @returns {Number} offset in ms
 */
export const getUtcToLocalTimeOffset = once(function getUtcToLocalTimeOffset() {
  const SECONDS_IN_MINUTE = 60;
  const MILLISECONDS_IN_SECOND = 1000;

  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetInMilliseconds = offsetInMinutes * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;

  return offsetInMilliseconds;
});

/**
 * @param {Date} dateObj
 * @returns {Date}
 */
export function convertUtcToLocalDate(dateObj) {
  return new Date(dateObj.getTime() - getUtcToLocalTimeOffset());
}
