import { types } from 'mobx-state-tree';
import { invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';

const PRE_MAPPING = {
  firstName: 'first_name',
  lastName: 'last_name',
};
const POST_MAPPING = invert(PRE_MAPPING);

const User = types
  .model('User', {
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
  })
  .views(self => ({
    get name() {
      return [self.firstName, self.lastName].filter(Boolean).join(' ');
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));

export default User;
