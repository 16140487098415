import { observable, action } from 'mobx';
import BaseStore from '../BaseStore';
import TaskUIStore from './TaskUIStore';

/**
 * UI Store is meant to store everything UI related
 * and replace the need of `this.setState()`
 */
export default class UIStore extends BaseStore {
  constructor(context) {
    super(context);

    this.task = new TaskUIStore(context);
  }

  @observable login = this.createForm();

  @action reset() {
    this.task.reset();
  }
}
