import { map, pick, pickBy, values, omit } from 'lodash';
import nullifyOptionalDeep from 'shared/utils/nullifyOptionalDeep';
import { TEMPLATE_INPUT_REGEXP } from 'app/models/forms/PlanTask';
import { ELIGIBILITY_TYPES } from 'app/models/Benefit';
import ApplicationError from 'errors/ApplicationError';
import { isStatusUserMessageTemplateKey } from './isStatusUserMessageTemplateKey';

/**
 * @param {Object} options
 * @param {Object} options.formData
 * @returns {Object} payload
 */
export default function getPayload({ formData }) {
  const isVerified = formData.is_verified;

  let payload = {
    is_verified: Boolean(isVerified),
    status_user_message: getStatusUserMessage(formData),
  };

  if (isVerified) {
    const formPlan = formData.plan || {};
    const formPatient = formData.patient || {};

    payload = {
      ...payload,
      ...pick(formPatient, ['relationship_type_name', 'member_id']),
      patient: {
        ...pick(formPatient, ['first_name', 'last_name', 'dob']),
      },
      plan: {
        ...pick(formPlan, ['plan_name', 'refresh_date', 'type']),
        benefits: map(formData.benefits, getBenefitPayload),
      },
    };
  }

  return nullifyOptionalDeep(payload);
}

/**
 * @param {Object} benefitData
 * @returns {Object}
 */
function getBenefitPayload(benefitData) {
  if (benefitData.eligibility === ELIGIBILITY_TYPES.unavailable) {
    return {
      ...pick(benefitData, [
        'key',
        'eligibility',
      ]),
      // New way to specify that benefit is unavailable, `eligibility` would be deprecated
      is_available: false,
    };
  }

  // BE throws 500 if we send it
  const fieldsToOmit = [];
  if (!benefitData.items) {
    fieldsToOmit.push('items');
  }

  return {
    ...omit(benefitData, fieldsToOmit),
    is_available: true,
  };
}

export function getStatusUserMessage(formData) {
  const statusUserMessageTemplate = formData.status_user_message;

  if (formData.is_verified || !statusUserMessageTemplate) {
    return null;
  }

  const templateValues = values(pickBy(formData, (_, key) => isStatusUserMessageTemplateKey(key)));

  return statusUserMessageTemplate.split(TEMPLATE_INPUT_REGEXP).reduce((acc, part, index) => {
    if (index > 0) {
      const value = templateValues[index - 1];
      if (!value) {
        throw new ApplicationError(`Can't find matching value for input "${index}"`);
      }

      acc += value;
    }

    acc += part;

    return acc;
  }, '');
}
