import { types } from 'mobx-state-tree';
import { isEmpty, invert, map, startCase } from 'lodash';
import formatPhone from 'shared/utils/formatPhone';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import { capitalizeName } from 'shared/utils/helpers';
import Address from './Address';

const PRE_MAPPING = {
  taxId: 'tax_id',
  typeCodes: 'types',
};
const POST_MAPPING = invert(PRE_MAPPING);

const Provider = types
  .model('Provider', {
    name: types.maybeNull(types.string),
    npi: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    address: types.maybeNull(Address),
    taxId: types.maybeNull(types.string),
    typeCodes: types.maybeNull(types.array(types.string)),
  })
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      address: isEmpty(snapshot.address) ? null : snapshot.address,
      name: capitalizeName(snapshot.name),
    }))
  )
  .postProcessSnapshot(transformSnapshot.post(
    mapSnapshotProps(POST_MAPPING),
  ))
  .views(self => ({
    get uiPhone() {
      return formatPhone(self.phone);
    },
    get speciality() {
      const { typeCodes } = self;
      if (!typeCodes) {
        return null;
      }

      return map(typeCodes, startCase).join(', ');
    },
  }));

export default Provider;
