import React from 'react';
import PropTypes from 'prop-types';
import smart from 'shared/decorators/smartComponent';

/**
 * Error boundaries should be used like this
 * `<Route component={ErrorBoundary} />`
 * except for default `site` error boundary which should be placed in the root of our App
 *
 * @param {string} errorType error type defined on pageErrors.errors
 * @param {Object} errorComponents hash of components by error codes
 * @returns {ErrorBoundary}
 */
export function createErrorBoundary(errorType, errorComponents) {
  @smart
  class ErrorBoundary extends React.Component {
    static propTypes = {
      children: PropTypes.node,
    };

    static errorType = errorType;

    /**
     * @param {Error} error
     */
    componentDidCatch(error) {
      this.stores.pageErrorsUi.setError(error, errorType);
      this.forceUpdate();
    }

    /**
     * @param {Error|PageError} error
     * @returns {ReactElement}
     */
    renderError(error) {
      const ErrorComponent = errorComponents[error.code] || errorComponents.default;

      return (
        <ErrorComponent {...error.props} />
      );
    }

    render() {
      const error = this.stores.pageErrorsUi.getError(errorType);
      if (error) {
        return this.renderError(error);
      }

      return this.props.children || null;
    }
  }

  return ErrorBoundary;
}
