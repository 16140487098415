import ApplicationError from 'errors/ApplicationError';
import { ERROR_MESSAGES } from 'shared/constants';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

/**
 * @exports APIError
 */
class APIError extends ApplicationError {
  /**
   * @inheritdoc
   */
  constructor(code, details, message = ERROR_MESSAGES[code]) {
    super(message);

    this.name = 'APIError';
    this.code = code;
    this.details = details || {};
    this.data = this.details.data;
    this.message = message;
  }

  /**
   * Describes how to convert error to json
   *
   * @returns {Object}
   */
  toJSON() {
    return {
      ...super.toJSON(),
      code: this.code,
      details: this.details,
    };
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
APIError.is = getErrorTypeCheckFor(APIError);

export default APIError;
