import { setComponentName } from 'shared/utils/components';
import { getPartialObserverRender } from 'shared/decorators/observerMethod';

/**
 * @param {t.form.Component} TcombFactory
 * @returns {ReactComponent}
 */
export default function observerFactory(TcombFactory) {
  patchTcombFactory(TcombFactory);

  return TcombFactory;
}

/**
 * @param {t.form.Component} TcombFactory
 */
export function patchTcombFactory(TcombFactory) {
  TcombFactory.prototype.render = getPartialObserverRender(TcombFactory.prototype.render);

  setComponentName(TcombFactory, TcombFactory, 'observerFactory');
}
