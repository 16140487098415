import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { ThemeProvider } from 'react-css-themr';
import { Layout } from 'react-toolbox/lib/layout';
import smart from 'shared/decorators/smartComponent';
import theme from 'styles/theme/theme';
import AppBar from './components/AppBar/AppBar';
import './MainLayout.css';

@smart
export default class MainLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <DocumentTitle title={this.stores.pageMeta.title}>
        <ThemeProvider theme={theme}>
          <Layout>
            <div styleName="panel">
              <AppBar fixed={true} />
              <div styleName="page">
                {this.props.children}
              </div>
            </div>
          </Layout>
        </ThemeProvider>
      </DocumentTitle>
    );
  }
}
