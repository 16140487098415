import { map } from 'lodash';
import ActiveRoute from './ActiveRoute';

/**
 * @param {Object} routerState
 * @param {Object} initialRouteState
 * @returns {ActiveRoute[]}
 */
export default function createActiveRoutes(routerState, initialRouteState) {
  return map(routerState.routes, route => new ActiveRoute({
    route,
    routerState,
    initialState: initialRouteState,
  }));
}
