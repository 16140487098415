import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'react-toolbox/lib/dialog';
import { Input } from 'react-toolbox/lib/input';
import { RadioButton, RadioGroup } from 'react-toolbox/lib/radio';
import { map } from 'lodash';
import { observable, action } from 'mobx';
import history from 'shared/history';
import smart from 'shared/decorators/smartComponent';

const CUSTOM_SERVER = 'CUSTOM_SERVER';

@smart
export default class SettingsModal extends React.Component {
  static propTypes = {
    isOpened: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpened: false,
  };

  @observable ownStore = {
    selectedApiServer: null,
    customApiServer: '',
  };

  handleSaveOptions = () => {
    let { selectedApiServer, customApiServer } = this.ownStore;
    if (selectedApiServer) {
      if (selectedApiServer === CUSTOM_SERVER) {
        if (!customApiServer) {
          return;
        }

        selectedApiServer = customApiServer;
      }

      this.api.options.requester.setBaseUrl(selectedApiServer);
      this.props.onClose();

      if (this.stores.auth.isAuthenticated) {
        history.replace('/logout');
      }
    }
  };

  actions = [
    { label: 'Save', onClick: this.handleSaveOptions },
  ];

  choices = {
    'https://oam-api.regression.anagram.care': 'Regression',
    'https://oam-api.staging.anagram.care': 'Staging',
    'https://oam-api.batman.anagram.care': 'Batman',
    'https://oam-api.dev.anagram.care': 'Dev',
    [CUSTOM_SERVER]: 'Custom',
  };

  @action handleApiServerSelect = url => {
    this.ownStore.selectedApiServer = url;
  };

  @action handleCustomServerChange = value => {
    this.ownStore.customApiServer = value.trim();
  };

  renderRadioButton = (errorMessage, errorType) => (
    <RadioButton
      key={errorType}
      label={errorMessage}
      value={errorType}
      disabled={this.ownStore.isLoading}
    />
  );

  render() {
    const { isOpened, onClose } = this.props;
    const { selectedApiServer, customApiServer } = this.ownStore;

    let activeBaseUrl = selectedApiServer || this.api.options.requester.getBaseUrl();
    if (!this.choices[activeBaseUrl]) {
      activeBaseUrl = CUSTOM_SERVER;
    }

    return (
      <Dialog
        active={isOpened}
        onOverlayClick={onClose}
        onEscKeyDown={onClose}
        actions={this.actions}
        title="API Server"
        type="small"
      >
        <RadioGroup value={activeBaseUrl} onChange={this.handleApiServerSelect}>
          {map(this.choices, this.renderRadioButton)}
        </RadioGroup>
        {activeBaseUrl === CUSTOM_SERVER && (
          <Input
            label="Url"
            value={customApiServer}
            onChange={this.handleCustomServerChange}
            required={true}
          />
        )}
        <small>Settings will be reset to defaults after page reload.</small>
      </Dialog>
    );
  }
}
