/**
 * Fetch list of supported insurers names
 *
 * @returns {Array}
 */
export function getInsurerNames() {
  return this.get('/call-center/insurers/');
}

/**
 * @returns {Promise.<Object>}
 */
export function getMessagesTemplates() {
  return this.get('/call-center/provider-messages/');
}
