import { get, find } from 'lodash';
import { getSnapshot } from 'mobx-state-tree';
import ApplicationError from 'errors/ApplicationError';
import { visionTaskToFormData } from './visionTaskToFormData';

/**
 * @param {PlanTask} task
 * @returns {Object}
 */
export function medicalTaskToFormData(task) {
  const relationship = task.selfRelationship;

  if (!relationship) {
    // should never happen because such tasks can't be started in UI side
    throw new ApplicationError('Task have no primary member');
  }

  const { plan } = relationship;
  const benefitWithCoinsurance = plan ? find(plan.benefits, benefit => Boolean(benefit.coinsurance)) : null;

  return {
    ...visionTaskToFormData({ task, relationship }),
    accumulators: getMedicalPlanAccumulatorsData({ plan }),
    coinsurance: benefitWithCoinsurance ? benefitWithCoinsurance.coinsurance : null,
  };
}

/**
 * @param {Plan} plan
 * @returns {Object|null}
 */
function getMedicalPlanAccumulatorsData({ plan }) {
  if (plan) {
    const serverPlan = getSnapshot(plan);

    return {
      individual: {
        deductible: get(serverPlan, 'deductibles.out_of_network_individual.amount'),
        out_of_pocket: get(serverPlan, 'out_of_pockets.out_of_network_individual.amount'),
        accepted_amount: get(serverPlan, 'deductibles.out_of_network_individual.accepted_amount'),
        isOutOfPocketUnlimited: get(serverPlan, 'out_of_pockets.out_of_network_individual.is_amount_unlimited', false),
      },
      family: {
        deductible: get(serverPlan, 'deductibles.out_of_network_family.amount'),
        out_of_pocket: get(serverPlan, 'out_of_pockets.out_of_network_family.amount'),
        accepted_amount: get(serverPlan, 'deductibles.out_of_network_family.accepted_amount'),
        isOutOfPocketUnlimited: get(serverPlan, 'out_of_pockets.out_of_network_family.is_amount_unlimited', false),
      },
    };
  }

  return null;
}
