import { types } from 'mobx-state-tree';
import { values, invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import Plan from './Plan';
import Patient from './Patient';

const PRE_MAPPING = {
  isVerified: 'is_verified',
  memberId: 'member_id',
  typeName: 'relationship_type_name',
};
const POST_MAPPING = invert(PRE_MAPPING);

export const RELATIONSHIP_TYPES = {
  self: 'self',
  spouse: 'spouse',
  child: 'child',
  other: 'other',
};

const SourceRelationship = types
  .model('SourceRelationship', {
    id: types.identifierNumber,
    isVerified: types.maybeNull(types.boolean),
    memberId: types.maybeNull(types.string),
    patient: Patient,
    plan: types.maybeNull(Plan),
    typeName: types.maybeNull(types.enumeration(values(RELATIONSHIP_TYPES))),
  })
  .views(self => ({
    get isTypeSelf() {
      return self.typeName === RELATIONSHIP_TYPES.self;
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));

export default SourceRelationship;
