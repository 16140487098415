import { action } from 'mobx';
import Logger from 'app/logger';
import Form from 'app/models/Form';

export const logger = new Logger({
  prefix: 'Store',
  prefixStyle: 'color:brown',
});

/**
 * Base Store class
 * Every store should extend it.
 * If extended store needs to initialize anything(other than by class properties),
 * it has to do it in `reset()` method, not in `constructor`
 */
export default class BaseStore {
  /**
   * @param   {any}  any
   * @returns {Boolean}
   */
  static is(any) {
    return any instanceof BaseStore;
  }

  /**
   * @param {Object} context
   */
  constructor(context) {
    const app = this.app = context;
    this.stores = app.stores;
    this.api = app.api;
    this.logger = logger;
  }

  /**
   * Can be called to reset the store to it's initial values
   * Is supposed to be called on store initialization aswell
   */
  @action reset() {}

  /**
   * Should be called to cancel reactions/subscriptions/promises etc
   */
  @action cleanup() {}

  /**
   * Bootstrap typical form fields
   *
   * @param   {Object} extraFields
   * @returns {Form}
   */
  createForm(extraFields = {}) {
    return new Form(extraFields);
  }
}
