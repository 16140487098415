import { types } from 'mobx-state-tree';
import { invert, keys } from 'lodash';
import { parseDate, formatDate, DATE_FORMATS } from 'shared/utils/date';
import { transformSnapshot, mapSnapshotProps } from 'shared/utils/snapshot';
import PlanAccumulators from './PlanAccumulators';
import Benefit from './Benefit';

export const MEDICAL_PLAN_TYPE = {
  ppo: 'PPO',
  pos: 'POS',
  epo: 'EPO',
  hmo: 'HMO',
  cobra: 'COBRA',
  commercial: 'Commercial',
};

export const PLAN_TYPE = {
  ...MEDICAL_PLAN_TYPE,
  vision: 'Vision',
};

const PRE_MAPPING = {
  name: 'plan_name',
  groupId: 'group_id',
  refreshDate: 'refresh_date',
  outOfPockets: 'out_of_pockets',
};
const POST_MAPPING = invert(PRE_MAPPING);

const Plan = types
  .model('Plan', {
    name: types.maybeNull(types.string),
    groupId: types.maybeNull(types.string),
    refreshDate: types.maybeNull(types.Date),
    benefits: types.maybeNull(types.array(Benefit)),
    deductibles: types.maybeNull(PlanAccumulators),
    outOfPockets: types.maybeNull(PlanAccumulators),
    flags: types.maybeNull(types.array(types.string)),
    type: types.maybeNull(types.enumeration(keys(PLAN_TYPE))),
  })
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      refreshDate: parseDate(snapshot.refreshDate, DATE_FORMATS.server),
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(
    mapSnapshotProps(POST_MAPPING),
    snapshot => ({
      ...snapshot,
      refresh_date: formatDate(snapshot.refresh_date, DATE_FORMATS.server),
    }),
  ));

export default Plan;
