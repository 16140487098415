import { endsWith } from 'lodash';

/**
 * Adds ending slash to pathname if it doesn't already have it
 *
 * @param {string} pathname
 * @returns {string}
 */
function normalizePathname(pathname) {
  return endsWith(pathname, '/') ? pathname : `${pathname}/`;
}

/**
 * @param {string} pathname1
 * @param {string} pathname2
 * @returns {boolean}
 */
export default function arePathnamesSame(pathname1, pathname2) {
  if (!pathname1 || !pathname2) {
    return false;
  }

  pathname1 = normalizePathname(pathname1);
  pathname2 = normalizePathname(pathname2);

  return pathname1 === pathname2;
}
