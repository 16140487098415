import { pick, mapValues, partial } from 'lodash';

/**
 * @param {Object} options
 * @param {Object} options.requester
 * @param {Function} options.callMethod
 * @param {Function} [options.getCallContext]
 * @param {Function} [options.onCallSuccess]
 * @param {Function} [options.onCallError]
 * @returns {Object}
 */
export default function createWebApi(options = {}) {
  const requesterMethods = pick(options.requester, ['get', 'post', 'put', 'patch', 'delete']);

  /**
   * @param {string} methodPath
   * @param {Object|*} [data]
   * @param {Object|*} [config]
   * @returns {Promise.<Object>}
   */
  async function call(methodPath, data, config) {
    const callParams = { methodPath, data, config };
    const context = {
      ...(options.getCallContext && options.getCallContext(callParams)),
      ...(config && config.context),
    };
    const callInfo = {
      ...callParams,
      context,
    };

    try {
      const webApiObject = {
        context,
        ...mapValues(requesterMethods, requesterMethod => partial(requesterMethod, callInfo)),
        call,
      };

      const result = await Promise.resolve(options.callMethod(webApiObject, callParams));

      if (options.onCallSuccess) {
        return await Promise.resolve(options.onCallSuccess(result, callInfo));
      }

      return result;
    } catch (error) {
      if (options.onCallError) {
        return Promise.resolve(options.onCallError(error, callInfo));
      }

      throw error;
    }
  }

  return {
    options,
    call,
  };
}

