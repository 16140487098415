import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import ContextPathnameProvider from '../components/ContextPathnameProvider/ContextPathnameProvider';

/**
 * Passes pathname to component context. This pathname used in analytics purposes
 *
 * @param   {React.Component} Component
 * @param   {Object} props
 * @returns {ReactElement}
 */
export default function withContextPathname(Component, props) {
  const ContextPathnameProviderWithStatics = hoistNonReactStatics(ContextPathnameProvider, Component);

  return (
    <ContextPathnameProviderWithStatics {...props}>
      <Component {...props} />
    </ContextPathnameProviderWithStatics>
  );
}
