import { mapValues, isFunction, noop } from 'lodash';
import store from 'store';

const hasLocalStorage = store.enabled;

let localStorage;

if (hasLocalStorage) {
  localStorage = store;
} else {
  localStorage = mapValues(store, prop => isFunction(prop) ? noop : prop);
}

export default localStorage;
