import { types, flow } from 'mobx-state-tree';
import { observe } from 'mobx';
import { set } from 'lodash';
import Raven from 'raven-js';
import ApplicationError from 'app/errors/ApplicationError';
import localStore from 'shared/utils/localStorage';
import { ERROR_CODES } from 'shared/constants';
import User from 'app/models/User';
import BaseMSTStore from './BaseMSTStore';

export default BaseMSTStore
  .named('AuthStore')
  .props({
    user: types.maybeNull(User),
  })
  .views(self => ({
    /**
     * @returns {boolean}
     */
    get isAuthenticated() {
      return Boolean(self.user);
    },
  }))
  .actions(self => ({
    setAuthToken(tokenData) {
      self.api.setAuthToken(tokenData);
    },

    removeAuthToken() {
      self.api.removeAuthToken();
    },

    login: flow(function* login(credentialsData) {
      const tokenData = yield self.api.call('auth.loginUser', credentialsData);

      // Since user logged in successfully we will be able to fetch user info
      // So try to do it several times
      return self.authenticate({ tokenData, maxAttempts: 3 });
    }),

    /**
     * @param {function} callback
     * @returns {function}
     */
    onChange(callback) {
      const INVOKE_IMMEDIATELY = true;

      return observe(self, 'isAuthenticated', ({ newValue }) => callback(newValue), INVOKE_IMMEDIATELY);
    },

    /**
     * @param {function} callback
     * @returns {function}
     */
    onLogin(callback) {
      return self.onChange(isAuthenticated => {
        if (isAuthenticated) {
          callback();
        }
      });
    },

    /**
     * @param {function} callback
     * @returns {function}
     */
    onLogout(callback) {
      return self.onChange(isAuthenticated => {
        if (!isAuthenticated) {
          callback();
        }
      });
    },

    /**
     * Fetch current user info
     *
     * @param {Object} options
     * @param {Object} options.tokenData
     * @param {Boolean} options.ignoreError
     * @param {Number} options.maxAttempts - number of attempts to execute action
     * @param {Number} options.attempt - current attempt number
     */
    authenticate: flow(function* authenticate(options) {
      const authOptions = options || {};

      if (!authOptions.tokenData) {
        if (!authOptions.ignoreError) {
          const error = new ApplicationError('API Access token not provided');

          self.logger.error(error);
          Raven.captureException(error, {
            tags: { culprit: 'user.authenticate' },
          });

          throw error;
        }

        return;
      }

      self.setAuthToken(authOptions.tokenData);

      try {
        const user = yield self.api.call('operator.get');
        self.user = user;
        // save valid auth token to localStorage
        localStore.set('auth', authOptions.tokenData);
      } catch (error) {
        const maxAttempts = options.maxAttempts || 1;
        const attempt = options.attempt || 1;

        const isRetriableError = (error.code === ERROR_CODES.unhandledError) || (error.code === ERROR_CODES.internalServerError);
        if (isRetriableError && attempt < maxAttempts) {
          yield self.authenticate({
            ...options,
            attempt: attempt + 1,
          });
        } else {
          // Auth failed, token is wrong
          self.removeAuthToken();
          localStore.remove('auth');

          if (!authOptions.ignoreError) {
            self.logger.logError(error, {
              culprit: 'user.authenticate',
            });

            throw error;
          }
        }
      }
    }),

    /**
     * @param {Object|*} tokenData
     */
    tryAuthByToken: flow(function* tryAuthByToken(tokenData) {
      if (tokenData) {
        try {
          yield self.authenticate({ tokenData });
        } catch (error) {
          self.logger.logError(error, {
            tags: { culprit: 'AuthStore.tryAuthByToken' },
          });
        }
      }
    }),

    logout() {
      self.user = null;
      self.stores.ui.task.reset();
      set(self.stores.ui.login.data, 'password', null);

      // Remove auth token
      self.removeAuthToken();
      localStore.remove('auth');

      Raven.setUserContext();
    },
  }));
