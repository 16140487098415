import React from 'react';
import PropTypes from 'prop-types';

export default class ContextPathnameProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    route: PropTypes.object.isRequired,
  };

  static contextTypes = {
    contextPathname: PropTypes.string,
  };

  static childContextTypes = {
    contextPathname: PropTypes.string.isRequired,
  };

  getChildContext() {
    const parentContextPathname = this.context.contextPathname || '';
    const currentComponentPathname = this.props.route.path || '';
    const pathname = `${parentContextPathname}/${currentComponentPathname}`;

    return {
      // remove trailing slash
      contextPathname: pathname.replace(/\/$/, ''),
    };
  }

  render() {
    const { children, ...restProps } = this.props;

    return React.cloneElement(children, restProps);
  }
}
