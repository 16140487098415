import NProgress from 'nprogress';

export function startNProgress() {
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
}

export function endNProgress() {
  if (NProgress.isStarted()) {
    NProgress.done();
  }
}
