import { assign, reduce } from 'lodash';

/**
 * @param {Object} history
 * @param {Function[]} middlewares
 * @returns {Function}
 */
export default function createApplyMiddlewares(history, middlewares) {
  /**
   * @param {Object} [options]
   */
  return function applyMiddlewares(options) {
    const createHistory = () => ({ ...history });
    const enhancedCreateHistory = reduce(middlewares, (currentCreateHistory, middleware) => (
      middleware(currentCreateHistory)
    ), createHistory);

    assign(history, enhancedCreateHistory(options));
  };
}
