import { isString } from 'lodash';
import history from 'shared/history';
import arePathnamesSame from './arePathnamesSame';

/**
 * Create location if pathname was passed in
 *
 * @param {LocationDescriptor|string} location
 * @returns {LocationDescriptor}
 */
function normalizeLocation(location) {
  if (isString(location)) {
    return history.createLocation(location);
  }

  return location;
}

/**
 * @param {Object} location1
 * @param {Object} location2
 * @returns {boolean}
 */
export default function areLocationsSame(location1, location2) {
  if (!location1 || !location2) {
    return false;
  }

  // both are pathnames, shortcut comparison of pathnames
  if (isString(location1) && isString(location2)) {
    return arePathnamesSame(location1, location2);
  }

  location1 = normalizeLocation(location1);
  location2 = normalizeLocation(location2);

  return location1 === location2 || (
    arePathnamesSame(location1.pathname, location2.pathname) &&
    location1.search === location2.search &&
    location1.hash === location2.hash
  );
}
