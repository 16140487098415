import { types } from 'mobx-state-tree';
import { invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'app/shared/utils/snapshot';

const PRE_MAPPING = {
  cptCodes: 'cpt_codes',
};

const POST_MAPPING = invert(PRE_MAPPING);

export default types
  .model('ServiceType', {
    id: types.identifierNumber,
    name: types.string,
    cptCodes: types.array(types.string),
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)))
  .views(self => ({
    /**
     * @return {string}
     */
    get fullName() {
      return `${self.name} ${self.cptCodes.join(', ')}`;
    },
  }));
