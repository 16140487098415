import accurateInterval from 'accurate-interval';

/**
 * @param {Function} callback
 * @param {number} interval
 * @param {Object} [options]
 * @return {Object}
 */
export default function createInterval(callback, interval, options) {
  let intervalInstance = null;

  function isActive() {
    return Boolean(intervalInstance);
  }

  return {
    start() {
      if (!isActive()) {
        intervalInstance = accurateInterval(callback, interval, options);
      }
    },
    stop() {
      if (intervalInstance) {
        intervalInstance.clear();
        intervalInstance = null;
      }
    },
    isActive,
  };
}
