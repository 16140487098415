import { types } from 'mobx-state-tree';
import { values, invert } from 'lodash';
import { formatDate, parseDate, DATE_FORMATS } from 'shared/utils/date';
import { transformSnapshot, mapSnapshotProps } from 'shared/utils/snapshot';
import formatPrice from 'shared/utils/formatPrice';
import BenefitItem from './BenefitItem';

const PRE_MAPPING = {
  id: 'key',
  refreshDate: 'refresh_date',
  isAuthorizationRequired: 'is_authorization_required',
};
const POST_MAPPING = invert(PRE_MAPPING);

export const ELIGIBILITY_TYPES = {
  'unavailable': 'unavailable',
  'eligible': 'eligible',
  'used': 'used',
  'reserved': 'reserved',
};

const Benefit = types
  .model('Benefit', {
    id: types.identifier,
    eligibility: types.optional(types.enumeration(values(ELIGIBILITY_TYPES)), ELIGIBILITY_TYPES.unavailable),
    coverage: types.maybeNull(types.string),
    copay: types.maybeNull(types.string),
    items: types.maybeNull(types.array(BenefitItem)),
    refreshDate: types.maybeNull(types.Date),
    coinsurance: types.maybeNull(types.string),
    limits: types.maybeNull(types.frozen()),
    flags: types.maybeNull(types.array(types.string)),
    isAuthorizationRequired: types.optional(types.boolean, false),
    comment: types.maybeNull(types.string),
  })
  .views(self => ({
    get uiRefreshDate() {
      return formatDate(self.refreshDate, DATE_FORMATS.ui);
    },
    get isEligible() {
      return self.eligibility === ELIGIBILITY_TYPES.eligible;
    },
    get isUsed() {
      return self.eligibility === ELIGIBILITY_TYPES.used;
    },
    get isReserved() {
      return self.eligibility === ELIGIBILITY_TYPES.reserved;
    },
    get isUnavailable() {
      return self.eligibility === ELIGIBILITY_TYPES.unavailable;
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      refreshDate: parseDate(snapshot.refreshDate, DATE_FORMATS.server),
      copay: formatPrice(snapshot.copay),
      coverage: formatPrice(snapshot.coverage),
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(
    snapshot => ({
      ...snapshot,
      refreshDate: formatDate(snapshot.refreshDate, DATE_FORMATS.server),
    }),
    mapSnapshotProps(POST_MAPPING),
  ));

export default Benefit;
