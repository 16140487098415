import { isString, isNumber, isNil, negate, get } from 'lodash';
import t from 'tcomb-form-plus';
import getMsg, { getMsgFn } from 'shared/messages';
import {
  longer,
  shorter,
  exactLength,
  dateString,
  digitsOnly,
  lettersOnly,
  lettersOrDigitsOrHyphen,
  numeric,
  all,
  email,
} from 'shared/utils/validators';

export const Email = t.refinement(t.String, email);
Email.getValidationErrorMessage = () => getMsg('error.formField.invalidType', {
  fieldType: getMsg('info.emailAddress'),
});

export const MIN_PASSWORD_LENGTH = 8;
export const Password = t.refinement(t.String, longer(MIN_PASSWORD_LENGTH));
Password.getValidationErrorMessage = () => getMsg('error.formField.minLength', {
  fieldName: getMsg('info.password'),
  numChars: MIN_PASSWORD_LENGTH,
});

const hasSpecialChars = s => isString(s) && !(/^[a-zA-Z\s-']+$/).test(s);
const hasAtLeastOneLetter = s => (/[a-zA-Z]+/).test(s);

export const Name = t.refinement(t.String, s => !hasSpecialChars(s) && hasAtLeastOneLetter(s));
Name.getValidationErrorMessage = value => {
  if (hasSpecialChars(value)) {
    return getMsg('error.formField.invalidInput', {
      fieldName: getMsg('info.thisField'),
    });
  } else if (!hasAtLeastOneLetter(value)) {
    return getMsg('error.formField.requiredChar', {
      fieldName: getMsg('info.thisField'),
      requiredChar: getMsg('info.oneLetter'),
    });
  }
};

export const ZIP = t.refinement(t.String, all(digitsOnly, exactLength(5)));
ZIP.getValidationErrorMessage = () => getMsg('error.formField.invalid', {
  fieldName: getMsg('info.zipCode'),
});

const tenDigits = all(exactLength(10), digitsOnly);

export const Tel = t.refinement(t.String, tenDigits);
Tel.getValidationErrorMessage = () => getMsg('error.formField.minDigits', {
  fieldName: getMsg('info.phone'),
  numDigits: 10,
});

export const Url = t.refinement(t.String, s => (/^https?:\/\/.+\..+/).test(s));

export const State = t.refinement(t.String, all(lettersOnly, exactLength(2)));
State.getValidationErrorMessage = () => getMsg('error.formField.invalid', {
  fieldName: getMsg('info.state'),
});

export const NPI = t.refinement(t.String, tenDigits);
NPI.getValidationErrorMessage = getMsgFn('error.formField.invalid', {
  fieldName: 'NPI',
});

const longer4 = longer(4);

const shorter20 = shorter(20);

export const ssnLastFour = all(exactLength(4), digitsOnly);

const memberIdOrSsnLastFour = all(
  longer4,
  shorter20,
  lettersOrDigitsOrHyphen,
);

/**
 * @param {Function} fieldNameGetter
 * @return {Function}
 */
const getMemberIdOrSsnMessage = fieldNameGetter => value => {
  const fieldName = fieldNameGetter();
  if (!longer4(value)) {
    return getMsg('error.formField.minLength', {
      fieldName,
      numChars: '4',
    });
  }

  if (!shorter20(value)) {
    return getMsg('error.formField.maxLength', {
      fieldName,
      numChars: '20',
    });
  }

  if (!lettersOrDigitsOrHyphen(value)) {
    return getMsg('error.formField.invalidInput', {
      fieldName,
    });
  }
};

const getMemberIdMessage = getMemberIdOrSsnMessage(getMsgFn('info.memberId'));

export const MemberId = t.refinement(t.String, all(
  memberIdOrSsnLastFour,
  negate(ssnLastFour),
));
MemberId.getValidationErrorMessage = value =>
  getMemberIdMessage(value) || getMsg('error.formField.memberId');

export const SSNLastFour = t.refinement(t.String, ssnLastFour);
SSNLastFour.getValidationErrorMessage = () => getMsg('error.formField.minDigits', {
  fieldName: getMsg('info.thisField'),
  numDigits: 4,
});

export const MemberIdOrSsnLastFour = t.refinement(t.String, memberIdOrSsnLastFour);
MemberIdOrSsnLastFour.getValidationErrorMessage = getMemberIdOrSsnMessage(getMsgFn('info.thisField'));

export const DateString = t.refinement(t.String, dateString, 'DateString');

DateString.getValidationErrorMessage = (value = '') => {
  if (!value) {
    return getMsg('error.formField.required');
  }

  if (isString(value) && value.length === 10) {
    const dateItems = value
      .split('/')
      .map(Number);

    if (dateItems.length === 3) {
      const [month, date, year] = dateItems;
      const MAX_MONTH = 12;
      const MAX_DATE = 31;
      const MIN_YEAR = 1900;
      const MAX_YEAR = 2099;

      if (month > MAX_MONTH) {
        return getMsg('error.formField.maxMonth', {
          maxMonth: MAX_MONTH,
        });
      }

      if (date > MAX_DATE) {
        return getMsg('error.formField.maxDate', {
          maxDate: MAX_DATE,
        });
      }

      if (year < MIN_YEAR) {
        return getMsg('error.formField.minYear', {
          minYear: MIN_YEAR,
        });
      }

      if (year > MAX_YEAR) {
        return getMsg('error.formField.maxYear', {
          maxYear: MAX_YEAR,
        });
      }
    }
  }

  return getMsg('error.formField.date');
};

export const Numeric = t.refinement(t.String, numeric);
Numeric.getValidationErrorMessage = () => getMsg('error.formField.invalidType', {
  fieldType: getMsg('info.number'),
});

export const Id = t.refinement(t.Any, x => (isNumber(x) || isString(x)) && Number(x) >= 0);
Id.getValidationErrorMessage = (value, path, context) => {
  const { validationError } = get(context, 'options', {});

  return validationError || getMsg('error.formField.useSuggest', {
    fieldName: getMsg('info.item'),
  });
};

/**
 * @param   {*} value
 * @returns {String}
 */
t.Number.getValidationErrorMessage = value => {
  if (!value && value !== 0) {
    return getMsg('error.formField.required');
  }

  return getMsg('error.formField.invalidType', {
    fieldType: getMsg('info.number'),
  });
};

/**
 * @param   {*} value
 * @returns {String}
 */
t.String.getValidationErrorMessage = value => {
  if (isNil(value) || value === '') {
    return getMsg('error.formField.required');
  }

  return getMsg('error.formField.invalid', {
    fieldName: getMsg('info.thisField'),
  });
};

/**
 * @param   {*} value
 * @returns {String}
 */
const getDefaultEnumsValidationErrorMessage = value => {
  if (isNil(value) || value === '') {
    return getMsg('error.formField.required');
  }

  return getMsg('error.formField.invalid', {
    fieldName: getMsg('info.thisField'),
  });
};

/**
 * tcomb-form doesn't expose Enum cinstructor so this is the only way to add default validation message
 */
const originalEnumsFactory = t.enums;
t.enums = function enums(...args) {
  const type = originalEnumsFactory(...args);
  type.getValidationErrorMessage = getDefaultEnumsValidationErrorMessage;

  return type;
};
