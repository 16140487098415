import { get, isEmpty, isString, template } from 'lodash';
import ApplicationError from 'app/errors/ApplicationError';
import messages from './messages';

const messageTemplatesCache = {};

/**
 * Get message by path interpolated with data
 *
 * @param   {String|String[]} path
 * @param   {Object} [data]
 * @returns {String}
 */
export default function getMsg(path, data) {
  const message = get(messages, path);

  if (!isString(message)) {
    // eslint-disable-next-line no-negated-condition
    if (!process.env.isProd) {
      throw new ApplicationError(`Message in path "${path}" not found`);
    } else {
      return '';
    }
  }

  if (!data || isEmpty(data)) {
    return message;
  }

  // Take template from cache if
  if (messageTemplatesCache[path]) {
    return messageTemplatesCache[path](data);
  }

  const messageTemplate = messageTemplatesCache[path] = template(message);

  return messageTemplate(data);
}

export const getMsgFn = (...args) => () => getMsg(...args);
