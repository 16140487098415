import Bluebird from 'bluebird';

const bluebirdConfig = {
  // Enable cancellation
  cancellation: true,

  // Enables all warnings except forgotten return statements.
  warnings: false,

  // Enable long stack traces
  // "Long stack traces imply a substantial performance penalty, around 4-5x for throughput and 0.5x for latency"
  // @see http://bluebirdjs.com/docs/api/promise.longstacktraces.html
  longStackTraces: !process.env.isProd,

  // Enable monitoring
  monitoring: !process.env.isProd,
};

Bluebird.config(bluebirdConfig);

// Use bluebird as global Promise
window.Promise = Bluebird;
