import { find, pick, map } from 'lodash';
import { getSnapshot } from 'mobx-state-tree';
import { ELIGIBILITY_TYPES } from 'app/models/Benefit';

/**
 * @param {Object} options
 * @param {PlanTask} options.task
 * @param {Relationship} options.relationship
 * @returns {Object}
 */
export function visionTaskToFormData({ relationship, task }) {
  const plan = getPlan({ relationship, task });
  const { member } = task;

  const formData = {
    ...getRelationshipData({ relationship, task }),
    patient: getVisionPatientData({ relationship, member }),
    benefits: getBenefitsData({ relationship, task }),
  };

  const planData = getPlanData({ plan, relationship });
  if (planData) {
    formData.plan = planData;
  }

  return formData;
}

export function getRelationshipData({ relationship, task }) {
  let isVerified = Boolean(relationship.isVerified) || task.isEmployerPlanRequest;
  // Prefill new relationship's `is_verified` as `true` for UX purpose
  if (!isVerified && relationship.isNew) {
    isVerified = true;
  }

  return {
    is_verified: isVerified,
    status_user_message: relationship.statusUserMessage || null,
  };
}

/**
 * @param {Object} options
 * @param {Relationship} options.relationship
 * @param {Member} options.member
 * @returns {Object}
 */
export function getPatientData({ relationship, member }) {
  const serverRelationship = getSnapshot(relationship);

  return {
    ...pick(serverRelationship.patient, [
      'first_name',
      'last_name',
      'dob',
    ]),
    member_id: relationship.memberId || (relationship.isTypeSelf ? member.memberId : null),
  };
}

/**
 * @param {Relationship} relationship
 * @param {Member} member
 * @returns {Object}
 */
function getVisionPatientData({ relationship, member }) {
  const serverRelationship = getSnapshot(relationship);

  return {
    ...getPatientData({ relationship, member }),
    ...pick(serverRelationship, ['relationship_type_name']),
  };
}

/**
 * @param {Object} options
 * @param {Plan|null} options.plan
 * @param {Relationship} options.relationship
 * @returns {Object}
 */
function getPlanData({ plan, relationship }) {
  if (relationship.isTypeSelf) {
    return pick(getSnapshot(plan), [
      'refresh_date',
      'plan_name',
      'type',
    ]);
  }

  return null;
}

/**
 * @param {Object} options
 * @param {Relationship} options.relationship
 * @param {PlanTask} options.task
 * @returns {Object}
 */
export function getBenefitsData({ relationship, task }) {
  const { planTemplate } = task;
  if (!planTemplate || !planTemplate.benefits) {
    return null;
  }

  const benefitsTemplate = planTemplate.benefits;
  const plan = getPlan({ relationship, task });
  const benefits = plan && plan.benefits;

  return map(benefitsTemplate, benefitTemplate => {
    const benefit = find(benefits, benefit => benefit.id === benefitTemplate.id);

    if (benefit) {
      return {
        ...getSnapshot(benefit),
        // By default plan does not have flags information so it needs to be copied from template
        flags: getSnapshot(benefitTemplate.flags),
      };
    }

    return benefitTemplateToForm(benefitTemplate);
  }, {});
}

/**
 * @param {PlanTemplateBenefit} benefitTemplate
 * @returns {Object}
 */
function benefitTemplateToForm(benefitTemplate) {
  return {
    key: benefitTemplate.id,
    eligibility: ELIGIBILITY_TYPES.unavailable,
    flags: getSnapshot(benefitTemplate.flags),
    items: map(benefitTemplate.items, benefitTemplateItem => ({
      key: benefitTemplateItem.id,
      coverage: null,
    })),
    coverage: null,
    copay: null,
    refresh_date: null,
    coinsurance: null,
    comment: null,
    is_authorization_required: false,
    limits: {
      quantity: null,
      quantity_remain: null,
    },
  };
}

/**
 * @param {Relationship} relationship
 * @param {PlanTask} task
 * @returns {Plan|null}
 */
function getPlan({ relationship, task }) {
  const { selfRelationship } = task;
  if (relationship.plan) {
    return relationship.plan;
  } else if (selfRelationship && selfRelationship.plan) {
    return selfRelationship.plan;
  }

  return null;
}
