import { forEach } from 'lodash';

/**
 * @param {Object} config
 * @returns {Object}
 */
export default function deleteEmptyHeaders(config) {
  const { headers } = config;

  forEach(headers, (value, header) => {
    if (value === null || value === undefined) {
      delete headers[header];
    }
  });

  return config;
}
