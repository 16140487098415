import { trim } from 'lodash';

const DEFAULT_COMPONENT_NAME = 'Component';
const FUNCTION_NAME_REGEXP = /function\s([^(]{1,})\(/;

/**
 * @param {Component} Component
 * @returns {string}
 */
export default function getComponentName(Component) {
  if (!Component) {
    return null;
  }

  if (typeof Component === 'string') {
    return Component || DEFAULT_COMPONENT_NAME;
  }

  if (Component.displayName) {
    return Component.displayName;
  }

  return getFunctionName(Component) || DEFAULT_COMPONENT_NAME;
}

/**
 * @param {Function} Component
 * @returns {string}
 */
function getFunctionName(Component) {
  if (Component.name) {
    return Component.name;
  }

  // For browsers, that don't support `name` property
  const results = FUNCTION_NAME_REGEXP.exec(
    Component.toString()
  );

  return (results && results.length > 1) ?
    trim(results[1]) :
    '';
}
