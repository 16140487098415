import { types, flow, getSnapshot, applySnapshot } from 'mobx-state-tree';
import Raven from 'raven-js';
import { pickBy, forEach, map, transform } from 'lodash';
import ApplicationError from 'app/errors/ApplicationError';
import { objectToGetParams } from 'shared/utils/helpers';
import BaseMSTStore from './BaseMSTStore';

const Sorting = types
  .model('Sorting', {
    field: types.identifier,
    isDesc: types.optional(types.boolean, false),
  });
const Filter = types
  .model('Filter', {
    field: types.identifier,
    value: types.union(types.string, types.number),
  });

const PagedItemsStore = BaseMSTStore
  .named('PagedItemsStore')
  .props({
    items: types.map(types.model),
    sortings: types.map(Sorting),
    filters: types.optional(types.map(Filter), {}),
    isLoading: types.optional(types.boolean, false),
    hasError: types.optional(types.boolean, false),
    itemsPerPage: types.optional(types.number, 10),
    nextPageUrl: types.maybeNull(types.string),
    curPageUrl: types.maybeNull(types.string),
    prevPageUrl: types.maybeNull(types.string),
  })
  .preProcessSnapshot(snapshot => {
    if (!snapshot.items) {
      snapshot.items = {};
    }

    if (!snapshot.sortings) {
      snapshot.sortings = {};
    }

    if (!snapshot.filters) {
      snapshot.filters = {};
    }

    return snapshot;
  })
  .views(self => ({
    /**
     * @returns {string}
     */
    get ordering() {
      const sortings = [...self.sortings.values()];
      const orderings = map(sortings, sorting =>
        `${sorting.isDesc ? '-' : ''}${sorting.field}`
      );

      return orderings.join(',');
    },
    /**
     * @returns {Object}
     */
    get filtersAsParams() {
      const filters = [...self.filters.values()];

      return transform(filters, (acc, filter) => {
        acc[filter.field] = filter.value;
      }, {});
    },
    /**
     * @returns {boolean}
     */
    get hasActiveFilters() {
      return self.filters.size !== 0;
    },
    /**
     * @returns {boolean}
     */
    get isLastPage() {
      return !this.nextPageUrl;
    },
    /**
     * @returns {boolean}
     */
    get isFirstPage() {
      return !this.prevPageUrl;
    },
    /**
     * @returns {array}
     */
    get asArray() {
      return [...self.items.values()];
    },
    get endpointPath() {
      throw new ApplicationError('You have to overwrite `endpointPath` getter on `PagedItemsStore` descendant store');
    },
    /**
     * @return {string}
     */
    get firstPageWithCurrentSortingUrl() {
      const params = pickBy({
        count: self.itemsPerPage,
        order: self.ordering,
        ...self.filtersAsParams,
      }, filter => filter !== '');

      return `${self.endpointPath}?${objectToGetParams(params)}`;
    },
    /**
     * @param {string} name
     * @returns {string|number|null}
     */
    getFilterValueByName(name) {
      const filter = self.filters.get(name);
      if (filter) {
        return filter.value;
      }

      return null;
    },
  }))
  .actions(self => {
    let initialState = null;

    return {
      afterCreate() {
        self.curPageUrl = self.firstPageWithCurrentSortingUrl;
        initialState = getSnapshot(self);
      },
      /**
       * @param {string} field
       */
      changeSortingFor(field) {
        const { sortings } = self;
        if (sortings.has(field)) {
          const sorting = sortings.get(field);
          if (sorting.isDesc) {
            sortings.delete(field);
          } else {
            sorting.isDesc = true;
          }
        } else {
          sortings.put({ field });
        }

        self.loadFirstPageWithCurrentSorting();
      },
      /**
       * @param {string} field
       * @param {string|null|undefined} value
       */
      setFilterFor(field, value) {
        const { filters } = self;
        if (filters.has(field)) {
          if (value) {
            const filter = filters.get(field);
            filter.value = value;
          } else {
            filters.delete(field);
          }
        } else if (value) {
          filters.put({ field, value });
        }

        self.loadFirstPageWithCurrentSorting();
      },
      /**
       * @param {Object[]} result
       * @param {string|null} next
       * @param {string|null} prev
       */
      processPageData({ result, next, prev }) {
        const { items } = self;
        items.clear();
        forEach(result, item => items.put(item));
        self.nextPageUrl = next;
        self.prevPageUrl = prev;
      },
      fetchCurrentPage() {
        return self.api.call('http.get', self.curPageUrl);
      },
      loadFirstPageWithCurrentSorting() {
        return self.loadPage(self.firstPageWithCurrentSortingUrl);
      },
      reloadCurrentPage: flow(function* reloadCurrentPage() {
        yield self.loadPage(self.curPageUrl);

        /**
         * When all tasks from current page are completed
         * previous page should be loaded
         */
        if (self.items.size === 0 && self.prevPageUrl) {
          return self.loadPrevPage();
        }
      }),
      loadNextPage() {
        return self.loadPage(self.nextPageUrl);
      },
      loadPrevPage() {
        return self.loadPage(self.prevPageUrl);
      },
      /**
       * @param {string} fetchDataUrl
       * @returns {Promise}
       */
      loadPage: flow(function* loadPage(fetchDataUrl) {
        self.hasError = false;
        self.isLoading = true;

        try {
          const result = yield self.api.call('http.get', fetchDataUrl);
          self.processPageData(result);
          self.curPageUrl = fetchDataUrl;
        } catch (error) {
          self.hasError = true;
          Raven.captureException(error, {
            tags: { culprit: 'PagedItemsStore.loadPage' },
          });
        } finally {
          self.isLoading = false;
        }
      }),
      reset() {
        if (self.onReset) {
          self.onReset();
        }

        if (initialState) {
          applySnapshot(self, initialState);
        }
      },
    };
  });

export default PagedItemsStore;
