import { startsWith } from 'lodash';
import getComponentName from './getComponentName';

/**
 * @param {string} componentName
 * @param {string} [wrapperName]
 * @returns {boolean}
 */
function shouldWrapComponentName(componentName, wrapperName) {
  if (!wrapperName) {
    return false;
  }

  const hasThisWrapper = startsWith(componentName, `${wrapperName}(`);

  return !hasThisWrapper;
}

/**
 * @param {Function} Component
 * @param {string} name
 * @returns {Function}
 */
export function replaceComponentName(Component, name) {
  if (!process.env.isProd) {
    Component.displayName = name;
    Object.defineProperty(Component, 'name', {
      configurable: true,
      writable: true,
      value: name,
    });
  }

  return Component;
}

/**
 * Sets name of the wrapped component to `wrapperName(componentName)` or `componentName`
 *
 * @param {Function} WrappedComponent
 * @param {Function} Component
 * @param {string} [wrapperName]
 * @returns {Function}
 */
export default function setComponentName(WrappedComponent, Component, wrapperName) {
  if (!process.env.isProd) {
    const componentName = getComponentName(Component);
    const displayName = shouldWrapComponentName(componentName, wrapperName) ?
      `${wrapperName}(${componentName})` :
      componentName;
    replaceComponentName(WrappedComponent, displayName);
  }

  return WrappedComponent;
}
