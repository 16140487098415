import { types } from 'mobx-state-tree';
import BaseMSTModel from 'app/models/BaseMSTModel';

export const DEFAULT_TASKS_COUNT = {
  count: 0,
  urgent: 0,
};

const TasksCount = BaseMSTModel
  .named('TasksCount')
  .props({
    count: types.integer,
    urgent: types.integer,
  });

export default TasksCount;
