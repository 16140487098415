import { isEqual, last, assign } from 'lodash';
import getRouteParams from 'react-router/lib/getRouteParams';
import { getComponentName } from 'shared/utils/components';

export default class ActiveRoute {
  /**
   * @param {*} any
   * @returns {boolean}
   */
  static is(any) {
    return any instanceof ActiveRoute;
  }

  /**
   * @param {Route} route
   * @param {Object} routerState
   * @returns {Object|null}
   */
  static getQuery(route, routerState) {
    if (route !== last(routerState.routes)) {
      return null;
    }

    return routerState.location.query;
  }

  /**
   * @param {Route} route
   * @param {Object} routerState
   * @returns {Object|null}
   */
  static getParams(route, routerState) {
    const params = getRouteParams(route, routerState.params);

    return params;
  }

  /**
   * @param {Route} route
   * @param {Object} routerState
   * @returns {Component|null}
   */
  static getComponent(route, routerState) {
    const routeIndex = routerState.routes.indexOf(route);

    return routerState.components[routeIndex] || null;
  }

  /**
   * @param {Object} options
   * @param {Route} options.route
   * @param {Object} options.routerState
   * @constructor
   */
  constructor({ route, routerState, initialState }) {
    this.route = route;
    this.Component = ActiveRoute.getComponent(route, routerState);
    this.routeName = getComponentName(this.Component) || 'route';
    this.params = ActiveRoute.getParams(route, routerState);
    this.query = ActiveRoute.getQuery(route, routerState);
    this.state = { ...initialState };
    this.routeContext = {
      ...routerState,
      routeParams: this.params,
    };
  }

  /**
   * @param {Object} state
   */
  setState(state) {
    assign(this.state, state);
  }

  /**
   * @param {ActiveRoute} otherActiveRoute
   * @returns {boolean}
   */
  isSameAs(otherActiveRoute) {
    return (
      this.route === otherActiveRoute.route &&
      this.Component === otherActiveRoute.Component &&
      isEqual(this.params, otherActiveRoute.params) &&
      isEqual(this.query, otherActiveRoute.query)
    );
  }
}
