import history from 'shared/history';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';
import ApplicationError from 'errors/ApplicationError';
import getLocationPath from 'shared/utils/history/getLocationPath';

/**
 * @param {*|string|Object} to
 * @param {*|string|Object} from
 * @returns {string}
 */
function getMessage(to, from) {
  const toPath = getLocationPath(to);
  const fromPath = getLocationPath(from);

  let message = `Redirect to "${toPath}"`;
  message = fromPath ? `${message} from "${fromPath}"` : message;

  return message;
}

/**
 * Redirection Error class
 */
class RedirectError extends ApplicationError {
  /**
   * @constructor
   * @param {string|Object} to
   * @param {string|Object} [from]
   * @param {number} [status]
   */
  constructor(to, from, status) {
    super(getMessage(to, from));

    this.name = 'RedirectError';
    this.status = status || 302;
    this.setTo(to);
    this.setFrom(from);
  }

  /**
   * @param {*|string|Object} to
   */
  setTo(to) {
    this.to = to ? history.createLocation(to) : null;
    this.message = getMessage(to, this.from);
  }

  /**
   * @param {*|string|Object} from
   */
  setFrom(from) {
    this.from = from ? history.createLocation(from) : null;
    this.message = getMessage(this.to, from);
  }

  /**
   * @returns {Object}
   */
  toJSON() {
    return {
      ...super.toJSON(),
      from: this.from,
      to: this.to,
    };
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
RedirectError.is = getErrorTypeCheckFor(RedirectError);

export default RedirectError;

