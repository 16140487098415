import errorCodesToMessages from 'shared/utils/errorCodesToMessages';

/**
 * Map of error codes
 *
 * @type {Object}
 */
export const ERROR_CODES = {
  'networkError': 0,
  'badRequest': 400,
  'unauthorized': 401,
  'forbidden': 403,
  'notFound': 404,
  'methodNotAllowed': 405,
  'notAcceptable': 406,
  'gone': 410,
  'tooManyRequests': 429,
  'internalServerError': 500,
  'serviceUnavailable': 503,
  'unhandledError': 'unhandledError',
};

/**
 * Map of error codes, converted to human readable message strings, like:
 * {
 *   '500': 'Internal Server Error'
 * }
 *
 * @type {Object}
 */
export const ERROR_MESSAGES = errorCodesToMessages(ERROR_CODES);

/**
 * Verbose months names
 *
 * @type {Array}
 */
export const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',
];

/**
 * Short weekday names
 *
 * @type {String[]}
 */
export const WEEKDAYS_SHORT = [
  'S', 'M', 'T', 'W', 'T', 'F', 'S',
];

/**
 * @type {String[]}
 */
export const PERSON_FIELDS = [
  'first_name',
  'last_name',
  'dob',
];

/**
 * @type {String[]}
 */
export const PATIENT_FIELDS = [
  ...PERSON_FIELDS,
  'address',
  'phone',
];

export const SYMBOLS = {
  'emDash': '\u2014',
};

/**
 * Date formats
 *
 * @type {Object}
 */
export const DATE_FORMATS = {
  server: 'YYYY-MM-DD',
  serverIso: 'YYYY-MM-DDTHH:mm:ss',
  ui: 'MM/DD/YYYY',
  uiShort: 'M/D/YY',
  uiVerbose: 'MMMM D, YYYY',
  uiFull: 'MM/DD/YYYY [at] h:mm A',
  weekday: 'ddd, MMM D',
  month: 'MMMM',
};

/**
 * Input field masks
 *
 * @type {Object}
 */
export const MASKS = {
  phone: '(999) 999-9999',
  date: 'MM/DD/YYYY',
  dateShort: 'MM/DD/YY',
  zipCode: '99999',
  price: '$NNNNNNNNNNNN',
  percent: 'NNNNNN',
  int: '9999999999',
};

/**
 * @type {Object}
 */
export const ERROR_BOUNDARIES = {
  app: 'app',
};

/**
 * @type {Object}
 */
export const TASK_STATUS = {
  accepted: 'accepted',
  new: 'new',
  finished: 'finished',
  error: 'error',
};

