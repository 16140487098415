import { ERROR_CODES, ERROR_BOUNDARIES } from 'shared/constants';
import { createErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import Error404 from 'shared/components/Error404/Error404';
import Error500 from 'shared/components/Error500/Error500';
import ErrorUnknown from 'shared/components/ErrorUnknown/ErrorUnknown';

export default createErrorBoundary(ERROR_BOUNDARIES.app, {
  [ERROR_CODES.notFound]: Error404,
  [ERROR_CODES.internalServerError]: Error500,
  default: ErrorUnknown,
});
