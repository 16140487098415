import APIError from 'errors/APIError';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

class UnauthorizedAPIError extends APIError {
  constructor(code, details, message = 'Auth token invalid. Login to proceed') {
    super(code || 401, details, message);
    this.name = 'UnauthorizedAPIError';
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
UnauthorizedAPIError.is = getErrorTypeCheckFor(UnauthorizedAPIError);

export default UnauthorizedAPIError;
