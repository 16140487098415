import AppContainer from 'shared/AppContainer/AppContainer';
import BaseStore from 'app/stores/BaseStore';
import * as stores from 'app/stores';
import * as plugins from 'app/plugins';

/**
 * @returns {AppContainer}
 */
export default function createApp() {
  const isStore = BaseStore.is;
  const app = AppContainer.createApp({ stores, plugins, isStore });

  return app;
}
