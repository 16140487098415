import React from 'react';
import ReactDOM from 'react-dom';
import scrollToTop from 'shared/utils/scrollToTop';
import Root from 'shared/components/Root/Root';
import ErrorUnknown from 'shared/components/ErrorUnknown/ErrorUnknown';
import appConfig from 'app/config';

export const portalNode = document.getElementById(appConfig.portalId);
export const mountNode = document.getElementById(appConfig.mountId);

export function unmountApp() {
  ReactDOM.unmountComponentAtNode(mountNode);
}

/**
 * @param {ReactElement} reactEl
 */
function renderElement(reactEl) {
  ReactDOM.render(reactEl, mountNode);

  scrollToTop();
}

/**
 * Renders react application into mountNode
 *
 * @param {Object} app
 * @param {Transition} transition
 */
export function renderApp(app, transition) {
  renderElement(<Root app={app} routerState={transition.routerState} activeRoutes={transition.activeRoutes} />);
}

/**
 * Renders unknown error into mountNode
 */
export function renderUnknownPageError() {
  renderElement(<ErrorUnknown />);
}

export function renderFatalError() {
  const FATAL_ERROR_ID = 'fatal-error';

  if (!document.getElementById(FATAL_ERROR_ID)) {
    const errorString = (
      `<div id="${FATAL_ERROR_ID}">` +
      'Unexpected error occurred. Please try to reload the page or contact us at ' +
      `<a href="mailto:${appConfig.email}">${appConfig.email}</a>.` +
      '</div>'
    );
    document.body.insertAdjacentHTML('afterbegin', errorString);
  }
}
