import { isString, curry, every, get } from 'lodash';

export const email = s => (/.+@.+\..+/).test(s);
export const longer = curry((minLength, s) => isString(s) && s.length >= minLength);
export const shorter = curry((maxLength, s) => !s || (isString(s) && s.length <= maxLength));
export const exactLength = curry((len, s) => isString(s) && s.length === len);
export const digitsOnly = s => (/^\d+$/).test(s);
export const lettersOnly = s => (/^[a-zA-Z]+$/).test(s);
export const lettersOrDigitsOrHyphen = s => (/^[a-zA-Z\d-]+$/).test(s);
export const numeric = s => isString(s) && (/^[0-9.]+$/).test(s) && s.split('.').length <= 2;
export const dateString = s => isString(s) && (/^\d{4}-\d{2}-\d{2}/).test(s);

export const all = (...validators) => value =>
  every(validators, validator => validator(value));

/**
 * This utility makes possible to use other form values in particular field validation.
 * Due to tcomb internal logic, type can be validated in many lifecycle stages and in some
 * of them form context or data isn't available.
 * For this cases we skip cross-validaion because type will be re-validated anyway when context
 * will be created.
 *
 * @param {function} validateFn
 * @returns {function}
 */
export function createSafeCrossValidator(validateFn) {
  return function crossValidator(value, context, path) {
    if (!context) {
      return true;
    }

    const rawData = get(context, ['options', 'formStore', 'rawData']);
    if (!rawData) {
      return true;
    }

    return validateFn(rawData, path);
  };
}
