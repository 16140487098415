import React from 'react';
import ErrorPage from 'shared/components/ErrorPage/ErrorPage';
import appConfig from 'app/config';

export default class ErrorUnknown extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <ErrorPage title="Unknown Internal Error">
        An unknown error occurred. Try to refresh the page or contact us at{' '}
        <a href={appConfig.email}>
          {appConfig.email}
        </a>
      </ErrorPage>
    );
  }
}
