import { observable, reaction, action, computed } from 'mobx';
import { forEach } from 'lodash';
import { ControlledForm } from 'app/models/Form';
import BaseStore from 'app/stores/BaseStore';
import CancellationError from 'errors/CancellationError';
import { getAllErrors } from 'shared/components/Form/utils/mapErrorToFields';
import { visionTaskToFormData } from './utils/visionTaskToFormData';
import { medicalTaskToFormData } from './utils/medicalTaskToFormData';
import { medicalFormDataToRelationshipPayload } from './utils/medicalFormDataToRelationshipPayload';
import getRelationshipPayload, { getStatusUserMessage } from './utils/getRelationshipPayload';
import getFormErrorsMapping from './utils/getFormErrorsMapping';
import StatusUserMessageTemplatesStore from './StatusUserMessageTemplatesStore';

export default class TaskUIStore extends BaseStore {
  constructor(context) {
    super(context);

    this.statusUserMessageTemplates = StatusUserMessageTemplatesStore.create({}, context);
  }

  /**
   * @type {string|null}
   */
  @observable _error;

  /**
   * @type {boolean}
   */
  @observable isLoading;

  /**
   * @type {IObservableMap.<Form>} map of forms by relationship ids
   */
  forms = observable.map();

  /**
   * @type {Function|null}
   */
  unlistenReactions = null;

  startReactions() {
    const unlistenRelationshipsCleanUp = reaction(
      () => this.task ? this.task.relationshipsMap : null,
      relationshipsMap => {
        if (!relationshipsMap) {
          return;
        }

        forEach([...this.forms.keys()], relationshipId => {
          if (!relationshipsMap[relationshipId]) {
            this.forms.delete(relationshipId);
          }
        });
      },
      { fireImmediately: true }
    );

    const unlistenTask = reaction(
      () => this.task ? this.task.id : null,
      taskId => {
        if (!taskId) {
          this.reset();
        }
      },
      { fireImmediately: true }
    );

    // Create new form and fill it if selected relationship has changed
    const unlistenSelectedRelationship = reaction(
      () => this.selectedRelationship,
      selectedRelationship => {
        if (selectedRelationship) {
          // Only create form if not already present
          if (!this.forms.get(selectedRelationship.id)) {
            let initialFormData = {};
            if (this.task.isMedical) {
              initialFormData = medicalTaskToFormData(this.task);
            } else {
              initialFormData = visionTaskToFormData({
                task: this.task,
                relationship: selectedRelationship,
              });
            }

            this.forms.set(selectedRelationship.id, new ControlledForm({
              data: initialFormData,
            }));
          }
        }
      },
      { fireImmediately: true }
    );

    return () => {
      unlistenRelationshipsCleanUp();
      unlistenTask();
      unlistenSelectedRelationship();
    };
  }

  reset() {
    this.forms.clear();
    this._error = null;
    this.isLoading = false;
    this.statusUserMessageTemplates.reset();

    if (!this.unlistenReactions) {
      this.unlistenReactions = this.startReactions();
    }
  }

  /**
   * @returns {string|null}
   */
  @computed get error() {
    return this._error || null;
  }

  /**
   * @param {Error} error
   */
  set error(error) {
    if (CancellationError.is(error)) {
      this._error = null;
    }

    this._error = error ? getAllErrors(error).join('; ') : null;
  }

  /**
   * @returns {BaseTasksStore|null}
   */
  @computed get activeTasksStore() {
    const { eligibilityTasks, employerPlanTasks, medicalPlanTasks } = this.stores;
    if (eligibilityTasks.activeTask) {
      return eligibilityTasks;
    }

    if (employerPlanTasks.activeTask) {
      return employerPlanTasks;
    }

    if (medicalPlanTasks.activeTask) {
      return medicalPlanTasks;
    }

    return null;
  }

  /**
   * @returns {PlanTask|null}
   */
  @computed get task() {
    const { activeTasksStore } = this;

    return activeTasksStore ? activeTasksStore.activeTask : null;
  }

  /**
   * @returns {Relationship|null}
   */
  @computed get selectedRelationship() {
    const { task } = this;

    return task ? task.selectedRelationship : null;
  }

  /**
   * @returns {Form|null}
   */
  @computed get currentForm() {
    if (!this.selectedRelationship) {
      return null;
    }

    return this.forms.get(this.selectedRelationship.id) || null;
  }

  @action handleFormChange = () => {
    this.selectedRelationship.setSubmitted(false);
  };

  /**
   * @param {string} errorType
   * @param {string} errorMessage
   */
  @action async submitError({ errorType, errorMessage }) {
    this.isLoading = true;
    this.error = null;

    try {
      await this.task.fail({
        flags: [errorType],
        message: errorMessage,
      });
      this.activeTasksStore.clearActiveTask();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action async submitRelationship() {
    const { selectedRelationship, currentForm, task } = this;
    const validationResult = currentForm.formNode.validate();
    currentForm.isValid = validationResult.isValid();
    currentForm.errors = validationResult.errors;
    currentForm.isSubmitTried = true;

    if (!currentForm.isValid) {
      return;
    }

    const formData = currentForm.data;
    const payload = task.isMedical ?
      medicalFormDataToRelationshipPayload({ formData }) :
      getRelationshipPayload({ formData });

    if (selectedRelationship.isTypeSelf && !formData.is_verified) {
      try {
        this.isLoading = true;
        await task.reportInvalidMember({
          status_user_message: getStatusUserMessage(formData),
        });
        this.activeTasksStore.clearActiveTask();
      } catch (error) {
        currentForm.setError(error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }

      return;
    }

    try {
      this.isLoading = true;
      await task.updateRelationship(selectedRelationship, payload);
      selectedRelationship.setSubmitted(true);

      if (task.nextUnsubmittedRelationship) {
        task.selectRelationship(task.nextUnsubmittedRelationship.id);
      }
    } catch (error) {
      currentForm.setError(error, {
        mapping: getFormErrorsMapping(),
      });
    } finally {
      this.isLoading = false;
    }
  }

  @action async finishTask() {
    const { task } = this;
    this.isLoading = true;
    this.error = null;

    try {
      await task.complete();
      this.activeTasksStore.clearActiveTask();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  @action async rejectTask() {
    this.isLoading = true;

    try {
      await this.task.reject();
      this.activeTasksStore.clearActiveTask();
    } finally {
      this.isLoading = false;
    }
  }
}
