import fecha from 'fecha';
import Raven from 'raven-js';
import { isString, isNumber } from 'lodash';
import { commonLogger } from 'app/logger';
import { getUtcToLocalTimeOffset } from './parseDate';

/**
 * @param {Date} dateObj
 * @returns {Date}
 */
export function convertLocalDateToUtc(dateObj) {
  return new Date(dateObj.getTime() + getUtcToLocalTimeOffset());
}

/**
 * @param {Date|number} date
 * @param {string} format
 * @returns {string|null}
 */
export default function formatDate(date, format) {
  if (!date) {
    return null;
  } else if (isString(date)) {
    return date;
  } else if (isNumber(date)) {
    date = new Date(date);
  }

  try {
    let utcDate;
    /**
     * Only local dates with timestamp should be converted back to UTC time.
     * TODO: find more robust way to detect dates with timestamp and without
     */
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0) {
      utcDate = date;
    } else {
      utcDate = convertLocalDateToUtc(date);
    }

    return fecha.format(utcDate, format);
  } catch (err) {
    commonLogger.error(err, { date, format });

    Raven.captureException(err, {
      extra: {
        date,
        format,
      },
    });

    return null;
  }
}
