import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { computed, observable, action } from 'mobx';
import { Link, withRouter } from 'react-router';
import AppBar from 'react-toolbox/lib/app_bar';
import Navigation from 'react-toolbox/lib/navigation';
import linkStyles from 'react-toolbox/lib/link/theme.css';
import { IconMenu, MenuItem } from 'react-toolbox/lib/menu';
import smart from 'shared/decorators/smartComponent';
import IconLogo from 'shared/icons/IconLogo.svg';
import TasksCounter from './components/TasksCounter/TasksCounter';
import SettingsModal from './components/SettingsModal/SettingsModal';
import appBarTheme from './themes/appBar.css';
import navigationTheme from './themes/navigation.css';
import styles from './AppBar.css';

@withRouter
@smart
export default class AppBarComponent extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  @observable ownStore = {
    isSettingsModalVisible: false,
  };

  /**
   * @returns {Boolean}
   */
  @computed get isReady() {
    const { auth } = this.stores;
    if (auth.isAuthenticated) {
      return auth.user.isReady;
    }

    return false;
  }

  handleLogoClick = () => {
    this.props.router.push('/');
  };

  handleLogout = () => {
    this.props.router.push('/logout');
  };

  @action handleOpenSettings = () => {
    this.ownStore.isSettingsModalVisible = true;
  };

  @action handleCloseSettings = () => {
    this.ownStore.isSettingsModalVisible = false;
  };

  renderNavigation() {
    const activeLinkClasses = `${styles.navigation__item_active} ${linkStyles.active}`;
    const {
      visionPlan,
      visionEmployerPlan,
      claimStatus,
      pilotPlan,
    } = this.stores.tasksCount;

    return (
      <Navigation type="horizontal" theme={navigationTheme}>
        <div styleName="navigation">
          <Link to="/eligibility" styleName="navigation__item" className={linkStyles.link} activeClassName={activeLinkClasses}>
            Eligibility
            <TasksCounter common={visionPlan.count} />
          </Link>
          <Link to="/claim-status" styleName="navigation__item" className={linkStyles.link} activeClassName={activeLinkClasses}>
            Claim status
            <TasksCounter common={claimStatus.count} urgent={claimStatus.urgent} />
          </Link>
          <Link to="/employer-plan" styleName="navigation__item" className={linkStyles.link} activeClassName={activeLinkClasses}>
            Employer plan
            <TasksCounter common={visionEmployerPlan.count} />
          </Link>
          <Link to="/medical-plan" styleName="navigation__item" className={linkStyles.link} activeClassName={activeLinkClasses}>
            Medical plan
            <TasksCounter common={pilotPlan.count} />
          </Link>
        </div>
        <div styleName="actions">
          <IconMenu inverse={true}>
            {process.env.EXPOSE_INTERNALS && (
              <MenuItem value="settings" icon="settings" caption="Settings" onClick={this.handleOpenSettings} />
            )}
            <MenuItem value="logout" icon="subdirectory_arrow_left" caption="Logout" onClick={this.handleLogout} />
          </IconMenu>
        </div>
      </Navigation>
    );
  }

  render() {
    const { isAuthenticated } = this.stores.auth;

    return (
      <Fragment>
        <AppBar
          title="Call Center"
          leftIcon={<IconLogo />}
          onLeftIconClick={this.handleLogoClick}
          theme={appBarTheme}
          {...this.props}
        >
          {isAuthenticated && this.renderNavigation()}
        </AppBar>
        {process.env.EXPOSE_INTERNALS && (
          <SettingsModal isOpened={this.ownStore.isSettingsModalVisible} onClose={this.handleCloseSettings} />
        )}
      </Fragment>
    );
  }
}
