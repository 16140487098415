import { reduce } from 'lodash';

const PHONE_DIGITS = 10;

/**
 * Format phone
 *
 * @param   {String} phone
 * @returns {String}
 */
export default function formatPhone(phone) {
  if (!phone) {
    return '';
  }

  const digits = normalizePhone(phone);

  return reduce(digits, (result, digit, index) => {
    result += digit;
    if (index === 2) {
      result += ') ';
    } else if (index === 5) {
      result += '-';
    }

    return result;
  }, '(');
}

/**
 * Strip non-digits chars and crop length
 *
 * @param   {String} phone
 * @returns {String}
 */
export function normalizePhone(phone) {
  if (!phone) {
    return null;
  }

  let digits = phone.replace(/\D/g, '');
  if (digits.length > PHONE_DIGITS) {
    digits = digits.slice(digits.length - PHONE_DIGITS);
  }

  return digits;
}

