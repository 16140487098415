import { types, flow, addDisposer } from 'mobx-state-tree';
import ms from 'ms';
import { isString, transform } from 'lodash';
import ApplicationError from 'errors/ApplicationError';
import PlanTask from 'app/models/PlanTask';
import createInterval from 'shared/utils/createInterval';
import BaseTasksStore from './BaseTasksStore';

export const PARAM_PROVIDER_NAME = 'provider_name';
export const PARAM_CREATED_AT = 'created_at';
export const PARAM_REQUEST_CODE = 'request_code';

const LIST_REFRESH_INTERVAL = ms('5s');

const createPlanTasksStore = ({ storeName, filteringParams }) => (
  BaseTasksStore
    .named(storeName)
    .props({
      items: types.map(PlanTask),
      activeTask: types.maybeNull(types.reference(PlanTask)),
    })
    .views(self => ({
      /**
       * @return {string}
       */
      get endpointPath() {
        return '/call-center/tasks/';
      },
      /**
       * This property used to observe it because ObservableMap.size can't be observed for some reason
       *
       * @return {number}
       */
      get loadedTasksCount() {
        return self.items.size;
      },
      /**
       * @return {Object}
       */
      get defaultParams() {
        return {
          ...filteringParams,
        };
      },
      /**
       * @return {Object}
       */
      get filtersAsParams() {
        const filters = [...self.filters.values()];

        return transform(filters, (acc, filter) => {
          acc[filter.field] = filter.value;
        }, self.defaultParams);
      },
      get now() {
        throw new ApplicationError('Deprecated field');
      },
    }))
    .actions(self => {
      const autoRefreshInterval = createInterval(() => self.refresh(), LIST_REFRESH_INTERVAL);

      return {
        afterCreate() {
          addDisposer(self, self.stopAutoRefresh);
        },
        refresh: flow(function* refresh(fetchUrl) {
          // skip refresh if any other request in progress to avoid race-conditions
          if (self.isLoading) {
            return;
          }

          const pageUrl = isString(fetchUrl) ? fetchUrl : self.curPageUrl;
          try {
            const result = yield self.api.call('http.get', pageUrl);

            // avoid state update if refresh was stopped during request
            if (autoRefreshInterval.isActive()) {
              self.processPageData(result);
              self.curPageUrl = pageUrl;

              // When all tasks from current page are completed load prev page
              if (self.items.size === 0 && self.prevPageUrl) {
                yield self.refresh(self.prevPageUrl);
              }
            }
          } catch (error) {
            self.logger.logError(error, {
              tags: { culprit: 'PlanTasksStore.refresh' },
            });
          }
        }),
        loadFirstPageWithCurrentSorting: flow(function* loadFirstPageWithCurrentSorting() {
          const wasAutoRefreshActive = autoRefreshInterval.isActive();
          self.stopAutoRefresh();

          const result = yield self.loadPage(self.firstPageWithCurrentSortingUrl);

          if (wasAutoRefreshActive) {
            self.startAutoRefresh();
          }

          return result;
        }),
        startAutoRefresh: autoRefreshInterval.start,
        stopAutoRefresh: autoRefreshInterval.stop,
        resetWorkingState() {
          self.stopAutoRefresh();
          self.activeTask = null;
          self.items.clear();
        },
        onReset() {
          self.stopAutoRefresh();
        },
      };
    })
);

export const medicalPlanTasks = createPlanTasksStore({
  storeName: 'MedicalPlanTasksStore',
  filteringParams: {
    is_pilot: true,
  },
});

export const employerPlanTasks = createPlanTasksStore({
  storeName: 'EmployerPlanTasksStore',
  filteringParams: {
    is_plan_request: true,
    is_pilot: false,
  },
});

export const eligibilityTasks = createPlanTasksStore({
  storeName: 'EligibilityTasksStore',
  filteringParams: {
    is_plan_request: false,
    is_pilot: false,
  },
});
