import { ERROR_CODES } from 'app/shared/constants';

/**
 * @param {string} id
 * @returns {Promise}
 */
export function acceptTask(id) {
  return this.post(`/call-center/claim-status/tasks/${id}/accept/`);
}

/**
 * @param {string} id
 * @returns {Promise}
 */
export function getActiveTask() {
  return this.get('/call-center/claim-status/tasks/active/', null, {
    dontReport: [ERROR_CODES.notFound],
  });
}

/**
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function finishTask({ id, data }) {
  return this.post(`/call-center/claim-status/tasks/${id}/finish/`, data);
}

/**
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export function failTask({ id, data }) {
  return this.post(`/call-center/claim-status/tasks/${id}/fail/`, data);
}
