import { observable, computed, reaction, action } from 'mobx';
import getLocationPath from 'shared/utils/history/getLocationPath';
import areLocationsSame from 'shared/utils/history/areLocationsSame';
import BaseStore from 'app/stores/BaseStore';

export default class NavigationStore extends BaseStore {
  static includeSerializeKeys = ['returnTo'];

  /**
   * @type {LocationDescriptor|null}
   */
  @observable.ref _prevLocation;

  /**
   * @type {LocationDescriptor|null}
   */
  @observable.ref _currentLocation = null;

  /**
   * @type {boolean}
   */
  @observable isTransitioning = false;

  @action reset() {
    this._prevLocation = null;
    this._currentLocation = null;
    this.isTransitioning = false;
  }

  returnTo = {
    // For login and password reset cases
    onAuth: null,
    // For setTaxId, setPassword etc cases
    onAppAccess: null,
  };

  /**
   * @returns {Object}
   */
  @computed({ equals: areLocationsSame })
  get prevLocation() {
    return this._prevLocation;
  }

  /**
   * @param {Object} location
   */
  set prevLocation(location) {
    this._prevLocation = location;
  }

  /**
   * @returns {Object}
   */
  @computed({ equals: areLocationsSame })
  get currentLocation() {
    return this._currentLocation;
  }

  /**
   * @param {Object} location
   */
  set currentLocation(location) {
    this._currentLocation = location;
  }

  /**
   * @returns {string}
   */
  @computed get currentPath() {
    return getLocationPath(this.currentLocation, { includeHash: true });
  }

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onTransitionChange = (fn, options) => reaction(() => this.isTransitioning, fn, options);

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onTransitionStart = (fn, options) => this.onTransitionChange(isTransitioning => {
    if (isTransitioning) {
      fn();
    }
  }, options);

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onTransitionEnd = (fn, options) => this.onTransitionChange(isTransitioning => {
    if (!isTransitioning) {
      fn();
    }
  }, options);

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onTransitionEndOnce = (fn, options) => {
    const dispose = this.onTransitionEnd(() => {
      dispose();
      fn();
    }, options);

    return dispose;
  };

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onTransitionStartOnce = (fn, options) => {
    const dispose = this.onTransitionStart(() => {
      dispose();
      fn();
    }, options);

    return dispose;
  };

  /**
   * @param {Function} fn
   * @param {Object} [options]
   * @returns {Function} dispose
   */
  onCurrentLocationChange = (fn, options) => reaction(() => this.currentLocation, fn, options);

  /**
   * Start transition flow
   */
  @action startTransition() {
    this.isTransitioning = true;
  }

  /**
   * Complete transition flow
   */
  @action endTransition() {
    this.isTransitioning = false;
  }
}
