import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import MainLayout from 'shared/layout/MainLayout/MainLayout';

export default class ErrorPage extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node,
  };

  render() {
    const { children, title } = this.props;

    return (
      <MainLayout>
        <h2>{title}</h2>
        {children}
        <p>
          <Link to="/">
            Return to main page
          </Link>
        </p>
      </MainLayout>
    );
  }
}
