import React from 'react';
import ErrorPage from 'shared/components/ErrorPage/ErrorPage';

export default class Error404 extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <ErrorPage title="Page Not Found">
        Sorry, we couldn't find that page.
      </ErrorPage>
    );
  }
}
