import { types, getParent } from 'mobx-state-tree';
import { invert, isEmpty } from 'lodash';
import { formatDate, parseDate, DATE_FORMATS } from 'shared/utils/date';
import { transformSnapshot, mapSnapshotProps } from 'shared/utils/snapshot';
import { capitalizeName } from 'shared/utils/helpers';
import Address from 'app/models/Address';

const PRE_MAPPING = {
  firstName: 'first_name',
  lastName: 'last_name',
};
const POST_MAPPING = invert(PRE_MAPPING);

const Patient = types
  .model('Patient', {
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    dob: types.maybeNull(types.Date),
    address: types.maybeNull(Address),
  })
  .views(self => ({
    get relationship() {
      return getParent(self);
    },
    get name() {
      return [self.firstName, self.lastName].filter(Boolean).join(' ');
    },
    get uiDob() {
      return formatDate(self.dob, DATE_FORMATS.ui);
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      address: isEmpty(snapshot.address) ? null : snapshot.address,
      firstName: capitalizeName(snapshot.firstName),
      lastName: capitalizeName(snapshot.lastName),
      dob: parseDate(snapshot.dob, DATE_FORMATS.server),
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(
    mapSnapshotProps(POST_MAPPING),
    snapshot => ({
      ...snapshot,
      dob: formatDate(snapshot.dob, DATE_FORMATS.server),
    }),
  ));

export default Patient;
