/**
 * @param {Function} Component
 * @returns {boolean}
 */
export default function isClassComponent(Component) {
  return Boolean(
    Component &&
    Component.prototype &&
    typeof Component.prototype.isReactComponent === 'object'
  );
}
