import { isEmpty } from 'lodash';
import Raven from 'raven-js';
import Logger from 'app/logger';

const WEBAPI_STYLE_COLOR = 'color:purple';

/**
 * Logger for call method
 */
export default class APILogger extends Logger {
  /**
   * Static method, that memoizes logger by methodPath
   *
   * @param {string} methodPath
   * @param {*} [data]
   * @returns {APILogger}
   */
  static getForMethod = (methodPath, data) => (
    new APILogger(methodPath, data)
  );

  /**
   * @constructor
   * @param {string} methodPath
   * @param {*} [data]
   */
  constructor(methodPath, data) {
    const category = 'WebApi';

    super({
      prefix: [category, methodPath],
      prefixStyle: WEBAPI_STYLE_COLOR,
    });

    this.category = category;
    this.methodPath = methodPath;
    this.startTime = null;
    this.data = data;
  }

  /**
   * Log, which method and with which data is being called
   */
  logMethodCall() {
    const { methodPath, data } = this;

    Raven.captureBreadcrumb({
      category: 'app.webApi',
      message: `Calling "${methodPath}"`,
      data: { data },
    });

    const dataLog = isEmpty(data) ? [] : ['with data:', data];

    this.info(...['Calling method'].concat(dataLog));
  }

  /**
   * Logs response of the call
   *
   * @param {*} result
   */
  logResult(result) {
    this.debug('Returned', { result });
  }

  /**
   * Logs time the call to method took
   */
  logElapsedTime() {
    const elapsedTime = Date.now() - this.startTime;

    this.info(`Has executed in %c${elapsedTime}ms`, WEBAPI_STYLE_COLOR);
  }
}
