import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

const CAPTURE_STACK_TRACE_SUPPORT = Boolean(Error.captureStackTrace);
const FIREFOX_ERROR_INFO = /@(.+?):(\d+):(\d+)$/;

/**
 * ApplicationError class
 *
 * @exports ApplicationError
 */
class ApplicationError extends Error {
  /**
   * @inheritdoc
   */
  constructor(message) {
    super(message);

    this.name = 'ApplicationError';
    this.message = message;

    // Ensure we get a proper stack trace in most Javascript environments
    if (CAPTURE_STACK_TRACE_SUPPORT) {
      // V8 environments (Chrome and Node.js)
      Error.captureStackTrace(this, this.constructor);
    } else {
      let { stack } = new Error();

      if (stack) {
        // Firefox workaround
        // Skipping first line in stack (it's the line where we have create our `new Error`)
        stack = stack.split('\n').slice(1);

        this.stack = stack.join('\n');

        // Trying to get file name, line number and column number from the first line in stack
        const match = FIREFOX_ERROR_INFO.exec(stack[0] || '');

        if (match) {
          this.fileName = match[1];
          this.lineNumber = Number(match[2]);
          this.columnNumber = Number(match[3]);
        }
      }
    }
  }

  /**
   * @returns {Object}
   */
  toJSON() {
    return {
      name: this.name,
      message: this.message,
    };
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
ApplicationError.is = getErrorTypeCheckFor(ApplicationError);

export default ApplicationError;
