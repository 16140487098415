import { types } from 'mobx-state-tree';
import PlanTemplateBenefit from './PlanTemplateBenefit';
import PlanTemplateFlag from './PlanTemplateFlag';

const PlanTemplate = types
  .model('PlanTemplate', {
    benefits: types.maybeNull(types.array(PlanTemplateBenefit)),
    flags: types.maybeNull(types.array(PlanTemplateFlag)),
  });

export default PlanTemplate;
