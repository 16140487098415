import { pipe } from 'lodash/fp';
import { isStateTreeNode } from 'mobx-state-tree';

/**
 * @param {...Function} transformFns
 * @returns {Function}
 */
function transformSnapshot(...transformFns) {
  /**
   * @param {Object|null|undefined} snapshot
   * @returns {Object|null|undefined}
   */
  return snapshot => {
    if (!snapshot) {
      return snapshot;
    }

    return pipe(...transformFns)(snapshot);
  };
}

export default {
  post: transformSnapshot,
  pre(...args) {
    const transformationFlow = transformSnapshot(...args);

    /**
     * MST calls `preProcessSnapshot` twice, first time with raw snapshot, second time with MST node that is
     * result of first invocation. It adds complexity in snapshot transformation logic and performance overhead.
     * To avoid this we just pass forward result of first invocation on second call.
     *
     * @see https://github.com/mobxjs/mobx-state-tree/issues/348
     * @see https://github.com/mobxjs/mobx-state-tree/issues/616
     *
     * @param {Object|null|undefined} snapshot
     * @returns {Object|null|undefined}
     */
    return snapshot => {
      if (isStateTreeNode(snapshot)) {
        return snapshot;
      }

      return transformationFlow(snapshot);
    };
  },
};
