/**
 * @returns {Object}
 */
export default function getFormErrorsMapping() {
  return {
    'patient.member_id': ['member_id'],
    'patient.relationship_type_name': ['relationship_type_name'],
    'benefits$1': [/^plan\.benefits(\..+)?$/],
    'benefits.$1': [/^plan\.benefits\.(.+?)\.items$/],
    ...getAccumulatorMapping('individual'),
    ...getAccumulatorMapping('family'),
    'benefits.coinsurance': [/^plan\.benefits\.\d+\.coinsurance$/],
  };
}

/**
 * @param {String} type
 * @returns {Object}
 */
function getAccumulatorMapping(type) {
  return {
    [`accumulators.${type}.deductible`]: [`plan.deductibles.out_of_network_${type}.amount`],
    [`accumulators.${type}.out_of_pocket`]: [`plan.out_of_pockets.out_of_network_${type}.amount`],
    [`accumulators.${type}.accepted_amount`]: [
      `plan.deductibles.out_of_network_${type}.accepted_amount`,
      `plan.out_of_pockets.out_of_network_${type}.accepted_amount`,
    ],
    [`accumulators.${type}.isOutOfPocketUnlimited`]: [
      `plan.out_of_pockets.out_of_network_${type}.is_amount_unlimited`,
    ],
  };
}
