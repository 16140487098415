import config from 'app/config';
import BaseMSTStore from './BaseMSTStore';

const DEFAULT_TITLE = config.head.title;

export default BaseMSTStore
  .named('AuthStore')
  .props({
    title: DEFAULT_TITLE,
  })
  .actions(self => ({
    /**
     * Sets page title
     *
     * @param {string} [title]
     * @param {boolean} [concatWithDefault=true]
     */
    setTitle(title, concatWithDefault = true) {
      if (title && concatWithDefault) {
        self.title = `${DEFAULT_TITLE} | ${title}`;
      } else if (title) {
        self.title = title;
      } else {
        self.title = DEFAULT_TITLE;
      }
    },
    reset() {
      self.setTitle();
    },
  }));
