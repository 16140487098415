import 'es6-symbol/implement';
import 'es6-map/implement';
import 'es6-set/implement';
import 'es6-weak-map/implement';
import regeneratorRuntime from 'regenerator-runtime/runtime';
import shimArrayFrom from 'array.from/shim';
import { assign } from 'lodash';

shimArrayFrom();

if (!Object.assign) {
  Object.assign = assign;
}

global.regeneratorRuntime = regeneratorRuntime;
