import { isString } from 'lodash';
import formatNumber from 'format-number';

/**
 * Format price values from BE to FE format
 * @example
 * input: `35.0000`
 * output: `35`
 *
 * @example
 * input: `45.1200`
 * output: `45.12`
 *
 * @example
 * input: `55.1234`
 * output: `55.1234`
 *
 * @param {string|*} value
 * @returns {string|*}
 */
export default function formatPrice(value) {
  if (!value || !isString(value)) {
    return null;
  }

  const valueNum = parseFloat(value);

  return valueNum ? valueNum.toString() : null;
}

/**
 * Format money value. Adds $ prefix
 *
 * @example
 * input: `5555.1000`
 * output: `$5,555.10`
 *
 * @example
 * input: `.19`
 * output: `$00.19`
 *
 * @return {string}
 */
export const formatMoney = formatNumber({
  integerSeparator: ',',
  decimal: '.',
  padRight: 2,
  truncate: 2,
  round: 2,
  prefix: '$',
});
