import React from 'react';
import PropTypes from 'prop-types';
import decorateWithOptions from 'decorate-with-options';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { setComponentName } from 'shared/utils/components';
import { AppContextConsumer } from 'shared/AppContainer/AppContext';
import withSafeReactiveRender from './withSafeReactiveRender';

const UNWRAPPED_SMART_COMPONENT = '_unwrapped_smart_component';

/**
 * Decorates Component Class
 *
 * @param {Function} ComponentClass
 * @param {Object} [options]
 * @param {boolean} [options.forwardRef]
 * @returns {Function} decorated component class
 */
function smartComponentDecorator(ComponentClass, options) {
  options = options || {};
  let EnhancedComponentClass = ComponentClass;

  // If it's a class and not a stateless function component
  if (EnhancedComponentClass.prototype && (typeof EnhancedComponentClass.prototype.render === 'function')) {
    // Defining getters for app and stores on the prototype
    Object.defineProperties(EnhancedComponentClass.prototype, {
      app: {
        get() {
          return this.props.appContext;
        },
      },
      stores: {
        get() {
          return this.app.stores;
        },
      },
      api: {
        get() {
          return this.app.api;
        },
      },
      logger: {
        get() {
          return this.app.logger;
        },
      },
    });
  }

  EnhancedComponentClass = withSafeReactiveRender(EnhancedComponentClass);

  setComponentName(EnhancedComponentClass, ComponentClass, 'withAppContext');

  class EnhancedComponentWithContext extends React.Component {
    static propTypes = {
      innerRef: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
      ]),
    };

    /**
     * @param {AppContainer} context
     * @returns {ReactElement}
     */
    renderComponent = context => {
      const { innerRef, ...restProps } = this.props;

      return <EnhancedComponentClass {...restProps} appContext={context} ref={innerRef} />;
    };

    render() {
      return (
        <AppContextConsumer>
          {this.renderComponent}
        </AppContextConsumer>
      );
    }
  }

  let ResultingComponentClass = EnhancedComponentWithContext;
  setComponentName(ResultingComponentClass, ComponentClass, 'smart');

  if (options.forwardRef) {
    ResultingComponentClass = React.forwardRef((props, ref) => (
      <EnhancedComponentWithContext {...props} innerRef={ref} />
    ));

    /**
     * ReactRouter 3 doesn't support components wrapped with `React.forwardRef` as route components
     * We well use unwrapped version in `RouterContext` `createElement` prop
     */
    Object.defineProperty(ResultingComponentClass, UNWRAPPED_SMART_COMPONENT, {
      value: EnhancedComponentWithContext,
    });

    hoistNonReactStatics(EnhancedComponentWithContext, ComponentClass);
  }

  hoistNonReactStatics(ResultingComponentClass, ComponentClass);

  return ResultingComponentClass;
}

export default decorateWithOptions(smartComponentDecorator);

/**
 * @param {ReactComponent} Component
 * @returns {ReactComponent}
 */
export function unwrapSmartComponent(Component) {
  if (Component.hasOwnProperty(UNWRAPPED_SMART_COMPONENT)) {
    return Component[UNWRAPPED_SMART_COMPONENT];
  }

  return Component;
}
