import { types } from 'mobx-state-tree';
import { mapValues, invert } from 'lodash';
import { compose, upperFirst, toLower } from 'lodash/fp';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import SourceRelationship from './SourceRelationship';

const PRE_MAPPING = {
  statusUserMessage: 'status_user_message',
  originalRelationshipId: 'original_relationship_id',
};
const POST_MAPPING = invert(PRE_MAPPING);

export const RELATIONSHIP_TYPES = {
  self: 'self',
  spouse: 'spouse',
  child: 'child',
  other: 'other',
};

export const UI_RELATIONSHIP_TYPES = mapValues(RELATIONSHIP_TYPES, compose(upperFirst, toLower));

const Relationship = SourceRelationship
  .named('Relationship')
  .props({
    originalRelationshipId: types.maybeNull(types.number),
    isSubmitted: false,
    isNew: false,
    statusUserMessage: types.maybeNull(types.string),
  })
  .views(self => ({
    get uiTypeName() {
      return UI_RELATIONSHIP_TYPES[self.typeName];
    },
    /**
     * Relationships existing in provider app has positive id
     *
     * @returns {boolean}
     */
    get isCreated() {
      return self.id > 0;
    },
  }))
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      // fallback to normal id if original is not available
      originalRelationshipId: snapshot.originalRelationshipId || snapshot.id,
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)))
  .actions(self => ({
    setSubmitted(isSubmitted) {
      self.isSubmitted = Boolean(isSubmitted);
    },
  }));

export default Relationship;
