import ms from 'ms';
import APIError from 'errors/APIError';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';
import appConfig from 'app/config';

class TimeoutAPIError extends APIError {
  /**
   * @param {...*} args
   */
  constructor(...args) {
    super(...args);
    this.name = 'TimeoutAPIError';
    this.message = `API didn't respond in ${ms(appConfig.apiTimeout)}`;
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
TimeoutAPIError.is = getErrorTypeCheckFor(TimeoutAPIError);

export default TimeoutAPIError;
