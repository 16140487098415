import { types, flow } from 'mobx-state-tree';
import { invert } from 'lodash';
import { differenceInDays } from 'date-fns';
import { TASK_STATUS } from 'shared/constants';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import { DATE_FORMATS, parseDate, formatDate } from 'shared/utils/date';
import BaseTaskModel from './BaseTaskModel';
import Claim from './Claim';
import Insurer from './Insurer';
import Provider from './Provider';

const PRE_MAPPING = {
  createdAt: 'created_at',
  acceptedAt: 'accepted_at',
  provider: 'provider',
  isUrgent: 'is_urgent',
};

const POST_MAPPING = invert(PRE_MAPPING);

const ClaimStatusTask = BaseTaskModel
  .named('ClaimStatusTask')
  .props({
    createdAt: types.Date,
    acceptedAt: types.maybeNull(types.Date),
    isUrgent: types.optional(types.boolean, false),
    note: types.maybeNull(types.string),
    insurer: Insurer,
    claim: Claim,
    provider: Provider,
  })
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING),
    snapshot => ({
      ...snapshot,
      createdAt: parseDate(snapshot.createdAt, DATE_FORMATS.server),
      acceptedAt: parseDate(snapshot.acceptedAt, DATE_FORMATS.server),
    }),
  ))
  .postProcessSnapshot(transformSnapshot.post(
    snapshot => ({
      ...snapshot,
      createdAt: formatDate(snapshot.createdAt, DATE_FORMATS.server),
      acceptedAt: formatDate(snapshot.acceptedAt, DATE_FORMATS.server),
    }),
    mapSnapshotProps(POST_MAPPING)
  ))
  .views(self => ({
    /**
     * @returns {number}
     */
    get age() {
      const localAge = differenceInDays(new Date(), self.createdAt);

      return Math.max(localAge, 0);
    },
  }))
  .actions(self => ({
    onAcceptTaskRequest() {
      return self.api.call('claimStatus.acceptTask', self.id);
    },
    complete: flow(function* complete(payload) {
      yield self.api.call('claimStatus.finishTask', {
        id: self.id,
        data: payload,
      });
    }),
    fail: flow(function* fail(payload) {
      yield self.api.call('claimStatus.failTask', {
        id: self.id,
        data: payload,
      });
    }),
  }));

ClaimStatusTask.STATUS = TASK_STATUS;

export default ClaimStatusTask;
