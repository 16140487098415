import { types } from 'mobx-state-tree';
import { transformSnapshot } from 'shared/utils/snapshot';
import formatPrice from 'shared/utils/formatPrice';

const BenefitItem = types
  .model('BenefitItem', {
    key: types.identifier,
    coverage: types.maybeNull(types.string),
  })
  .preProcessSnapshot(transformSnapshot.pre(
    snapshot => ({
      ...snapshot,
      coverage: formatPrice(snapshot.coverage),
    })
  ));

export default BenefitItem;
