import { types, addDisposer, applySnapshot, flow } from 'mobx-state-tree';
import ms from 'ms';
import { invert } from 'lodash';
import createInterval from 'shared/utils/createInterval';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import TasksCount, { DEFAULT_TASKS_COUNT } from './models/TasksCount';
import BaseMSTStore from '../BaseMSTStore';

const TASKS_COUNT_UPDATE_INTERVAL = ms('15s');

const PRE_MAPPING = {
  visionPlan: 'vision_plans',
  visionEmployerPlan: 'vision_employer_plans',
  claimStatus: 'claim_status',
  pilotPlan: 'pilot_plans',
};
const POST_MAPPING = invert(PRE_MAPPING);

const TasksCountStore = BaseMSTStore
  .named('TasksCountStore')
  .props({
    visionPlan: types.optional(TasksCount, DEFAULT_TASKS_COUNT),
    visionEmployerPlan: types.optional(TasksCount, DEFAULT_TASKS_COUNT),
    claimStatus: types.optional(TasksCount, DEFAULT_TASKS_COUNT),
    pilotPlan: types.optional(TasksCount, DEFAULT_TASKS_COUNT),
  })
  .preProcessSnapshot(transformSnapshot.pre(
    mapSnapshotProps(PRE_MAPPING)
  ))
  .postProcessSnapshot(transformSnapshot.post(
    mapSnapshotProps(POST_MAPPING),
  ))
  .actions(self => {
    const counterUpdateInterval = createInterval(() => self.update(), TASKS_COUNT_UPDATE_INTERVAL, {
      immediate: true,
    });

    return {
      startUpdatingCounter: counterUpdateInterval.start,
      stopUpdatingCounter: counterUpdateInterval.stop,
      update: flow(function* update() {
        try {
          const result = yield self.api.call('task.getTasksCount');
          applySnapshot(self, result);
        } catch (error) {
          self.logger.logError(error, {
            tags: { culprit: 'TasksCountStore.update' },
          });
        }
      }),
      afterCreate() {
        setTimeout(() => {
          /**
           * Start updating counter when user get logged in and stop it on logout
           */
          const { auth } = self.stores;
          const unlistenLogin = auth.onLogin(self.startUpdatingCounter);
          const unlistenLogout = auth.onLogout(self.reset);

          addDisposer(self, unlistenLogin);
          addDisposer(self, unlistenLogout);
        });

        addDisposer(self, self.stopUpdatingCounter);
      },
      reset() {
        self.stopUpdatingCounter();
      },
    };
  });

export default TasksCountStore;
