import { types } from 'mobx-state-tree';
import { invert, values } from 'lodash';
import formatPhone from 'shared/utils/formatPhone';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';

const PRE_MAPPING = {
  claimStatusCheckerRole: 'claim_status_checker_role',
  eligibilityCheckerRole: 'eligibility_checker_role',
};

const POST_MAPPING = invert(PRE_MAPPING);

export const CHECKER_ROLES = {
  provider: 'provider',
  member: 'primary_member',
  patient: 'patient',
};

export default types
  .model('Insurer', {
    id: types.identifierNumber,
    name: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    claimStatusCheckerRole: types.maybeNull(types.enumeration(values(CHECKER_ROLES))),
    eligibilityCheckerRole: types.maybeNull(types.enumeration(values(CHECKER_ROLES))),
    script: types.maybeNull(types.string),
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)))
  .views(self => ({
    /**
     * @return {string}
     */
    get uiPhone() {
      return formatPhone(self.phone);
    },
  }));
