/**
 * Logs user in
 *
 * @param {{email, password}} credentials
 * @returns {Object}
 */
export function loginUser(credentials) {
  return this.post('/call-center/login/', credentials);
}

/**
 * Logs user out
 *
 * @returns {Object}
 */
export function logoutUser() {
  return this.post('/call-center/logout/');
}

