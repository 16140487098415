import {
  cloneDeep,
  isArrayLike,
  isBoolean,
  isDate,
  isString,
  assign,
  isEqual,
  isEmpty,
  get,
  map,
  filter,
  omit,
  keys,
  noop,
} from 'lodash';
import { computed, observable, action, toJS, set as mobxSet } from 'mobx';
import { omitNullFieldsDeep } from 'shared/utils/updateDeep';
import { isUnfilled } from 'shared/utils/helpers';
import { ERROR_CODES } from 'shared/constants';
import mapErrorToFields, { getAllErrors, fieldErrorsToString } from 'shared/components/Form/utils/mapErrorToFields';
import APIError from 'errors/APIError';
import ApplicationError from 'errors/ApplicationError';
import BaseModel from './BaseModel';

/**
 * UI Form
 */
export default class Form extends BaseModel {
  /**
   * @param   {any}  any
   * @returns {Boolean}
   */
  static is(any) {
    return any instanceof Form;
  }

  /**
   * @constructor
   *
   * @param {Object} [options]
   */
  constructor(options = {}) {
    super();
    const { ...extraFields } = options;
    this.defaultExtraFields = extraFields;
    this.reset();
  }

  @observable data;
  /**
   * @type {string|string[]|null}
   */
  @observable commonError;
  @observable errors;
  @observable.ref validationErrors;
  @observable isValid;
  @observable isLoading;
  @observable isSending;
  @observable isSubmitTried;

  /**
   * Get values of valid fields
   *
   * @returns {Object}
   */
  @computed get validData() {
    const pathsWithInputErrors = map(this.inputErrors, err => err.path);
    const pathsWithServerErrors = keys(omitNullFieldsDeep(this.errors));
    const formData = this.getData();

    return omit(formData, [
      ...pathsWithInputErrors,
      ...pathsWithServerErrors,
    ]);
  }

  /**
   * @returns {ValidationError[]}
   */
  @computed get inputErrors() {
    return filter(this.validationErrors, err => err.actual !== null && err.actual !== '');
  }

  /**
   * @returns {Boolean}
   */
  @computed get hasInputErrors() {
    return this.inputErrors.length !== 0;
  }

  /**
   * @returns {Boolean}
   */
  @computed get disabled() {
    return !this.isValid || this.isLoading || this.isSending;
  }

  /**
   * @returns {Boolean}
   */
  @computed get isPristine() {
    const { data } = this.defaultExtraFields;
    if (data) {
      return isEqual(this.data, data);
    }

    return isEmpty(this.data);
  }

  /**
   * Update value
   *
   * @param   {Object|Array} newData
   */
  @action update(newData) {
    if (isArrayLike(newData)) {
      this.data = newData;
    } else {
      /**
       * We should use `assign` here because mobx able to track data mutations
       * but tcomb produces immutable data
       */
      assign(this.data, newData);
    }

    // reset common error on any form change
    this.commonError = null;
  }

  @action reset() {
    this.data = {};
    this.commonError = null;
    this.errors = {};
    this.isValid = false;
    this.isLoading = false;
    this.isSending = false;
    this.isSubmitTried = false;

    // `set` can both add and update properties
    // `extendObservable` cannot update existing properties since mobx@4
    mobxSet(
      this,
      cloneDeep(this.defaultExtraFields)
    );
  }

  /**
   * Get field error
   *
   * @param   {any} _
   * @param   {Array} path
   * @returns {String|undefined}
   */
  getFieldError = (_, path) => {
    const messages = this.errors[path.join('.')];
    if (!messages) {
      return;
    }

    /**
     * Handle common errors for array items and nested structs.
     * In theory we should support 3 common error formats for such items:
     *   1. string   - when plain string set to path of item into errors hash
     *   2. string[] - standard error format for field
     */
    const errorsString = fieldErrorsToString(messages);
    if (errorsString) {
      return errorsString;
    }

    if (!isEmpty(messages)) {
      // primitive values shouldn't receive errors hashes
      const value = get(this.data, path);
      if (isString(value) || isBoolean(value) || isDate(value)) {
        const exception = new ApplicationError(
          `Primitive form field at path '${path.join('.')}' received error that cannot be represented as string: '${JSON.stringify(messages)}'.`
        );
        this.logger.logError(exception, {
          tags: { culprit: 'Form.getFieldError' },
        });
      }
    }
  };

  /**
   * Detect does field has error
   *
   * @param   {Array} path
   * @returns {Boolean}
   */
  hasFieldError = path => Boolean(this.getFieldError(null, path));

  /**
   * Map error to form fields
   *
   * @param {Error|Object} error
   * @param {Object} [options]
   */
  @action setError(error, options = {}) {
    let { common, ...fieldErrors } = mapErrorToFields(error, {
      defaultKey: 'common',
      ...options,
    });

    let requestIdInfo = '';
    if (APIError.is(error) && error.code === ERROR_CODES.internalServerError) {
      const requestId = get(error, 'details.headers.x-request-id', '');
      if (requestId) {
        requestIdInfo = ` [Request ID: ${requestId}]`;
      }
    }

    if (isUnfilled(fieldErrors) && !common) {
      const allErrors = getAllErrors(error, options);

      if (options.defaultKey) {
        fieldErrors = {
          [options.defaultKey]: allErrors,
        };
      } else {
        common = allErrors;
      }
    }

    this.errors = fieldErrors;
    this.commonError = `${common}${requestIdInfo}`;

    if (error instanceof Error && !APIError.is(error)) {
      this.logger.logError(error, {
        tags: { culprit: 'Form.setError' },
      });
    }
  }

  /**
   * @returns {Object}
   */
  @action getData() {
    return toJS(this.data);
  }

  /**
   * @param {Function} cb
   * @returns {Promise.<null|*>}
   */
  @action trySubmit(cb = noop) {
    this.isSubmitTried = true;

    let result = null;
    if (this.isValid) {
      result = cb();
    }

    return Promise.resolve(result);
  }
}

/**
 * All forms in project should use this controlled form because uncontrolled isn't compatible with dev mode
 * and converts whole form to observable object on each form change in prod
 */
export class ControlledForm extends Form {
  @observable.ref data;

  /**
   * Update value
   *
   * @param   {Object|Array} newData
   */
  @action update(newData) {
    this.data = {
      ...this.data,
      ...newData,
    };

    // reset common error on any form change
    this.commonError = null;
  }
}
