import { compose, join, pluck, each, filter as filterFP, isString, isNumber, negate, isEmpty, any } from 'lodash/fp';

export const mapWithIndex = pluck.convert({ 'cap': false });

export const getValidKeys = filterFP(x => isString(x) || isNumber(x));

const isAllFieldsFalsy = negate(any(Boolean));

export const isUnfilled = x => isEmpty(x) || isAllFieldsFalsy(x);

export const filterEmpty = filterFP(negate(isUnfilled));

const eachWithIndex = each.convert({ 'cap': false });

export const objectToGetParams = compose(join('&'), mapWithIndex((val, key) => `${key}=${val}`));

const isDelimiter = char => (
  char === ' ' || char === '-'
);

export const capitalizeName = str => {
  let result = '';

  eachWithIndex((char, index) => {
    if (index === 0 || isDelimiter(str[index - 1])) {
      result += char.toUpperCase();
    } else {
      result += char.toLowerCase();
    }
  }, str);

  return result;
};
