import t from 'tcomb-form-plus';
import { MEDICAL_PLAN_TYPE } from 'app/models/Plan';
import { MASKS } from 'shared/constants';
import { DateString } from './shared';

/**
 * @param {boolean} isTypeSelf
 * @param {boolean} isMedical
 * @returns {Plan}
 */
export default function getPlanModel({ isTypeSelf, isMedical }) {
  if (isTypeSelf) {
    let PlanModel = t.struct({
      refresh_date: t.maybe(DateString),
      plan_name: t.maybe(t.String),
    });

    if (isMedical) {
      PlanModel = PlanModel.extend({
        type: t.enums(MEDICAL_PLAN_TYPE),
      });
    }

    return PlanModel;
  }

  return null;
}

/**
 * @param {ComponentType} PlanLayout
 * @returns {Object}
 */
export function getPlanOptions({ PlanLayout }) {
  return {
    template: PlanLayout,
    fields: {
      refresh_date: {
        placeholder: 'Refresh date (MM/DD/YYYY)',
        mask: MASKS.date,
        inline: true,
      },
      plan_name: {
        placeholder: 'Plan name',
        inline: true,
      },
      type: {
        placeholder: 'Type',
        inline: true,
      },
    },
  };
}
