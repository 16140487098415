import Raven from 'raven-js';
import { toJS } from 'mobx';
import { isObject, forEach, startsWith, pick, reduce, isPlainObject, has, map, isFunction, every, isRegExp, get, merge } from 'lodash';
import { any, compose } from 'lodash/fp';
import BaseModel from 'app/models/BaseModel';
import appConfig from 'app/config';
import ApplicationError from 'errors/ApplicationError';
import CancellationError from 'errors/CancellationError';
import RouteCancellationError from 'errors/RouteCancellationError';
import RedirectError from 'errors/RedirectError';
import UnauthorizedAPIError from 'errors/UnauthorizedAPIError';
import TaskTimeoutAPIError from 'errors/TaskTimeoutAPIError';
import APIError from 'errors/APIError';
import { markErrorAsReported, wasErrorReported } from 'errors/utils';
import { ERROR_CODES } from 'shared/constants';
import getExtraDataFromApiError from 'shared/utils/getExtraDataFromApiError';

if (process.env.REPORT_ERRORS) {
  const IGNORED_ERROR_TYPES = [
    CancellationError,
    RouteCancellationError,
    RedirectError,
    UnauthorizedAPIError,
    TaskTimeoutAPIError,
    { type: APIError, code: ERROR_CODES.networkError },
    { type: APIError, code: ERROR_CODES.tooManyRequests },
    { type: Error, message: /^Loading chunk \w+? failed/i },
  ];

  /**
   * Checks for direct `instanceof` without inheritance chain consideration
   *
   * @param {Error} error
   * @param {*} Type
   * @returns {boolean}
   */
  const isErrorOfType = (error, Type) => {
    if (!isFunction(Object.getPrototypeOf)) {
      return error instanceof Type;
    }

    const ErrorConstructor = isObject(error) && get(Object.getPrototypeOf(error), 'constructor');

    return Boolean(ErrorConstructor) && ErrorConstructor === Type;
  };

  /**
   * Returns true if this error is not considered something we should fix
   *
   * @param {Error} error
   * @returns {boolean}
   */
  const shouldIgnoreError = compose(
    any(Boolean),
    error => map(IGNORED_ERROR_TYPES, ErrorType => {
      if (isFunction(ErrorType) && (ErrorType === Error || ErrorType.prototype instanceof Error)) {
        return isErrorOfType(error, ErrorType);
      } else if (isFunction(ErrorType)) {
        const predicate = ErrorType;

        return predicate(error);
      } else if (isPlainObject(ErrorType)) {
        const { type, ...props } = ErrorType;

        return isErrorOfType(error, type) && every(props, (propValue, propKey) => {
          const errorValue = error[propKey];
          if (isRegExp(propValue)) {
            return propValue.test(errorValue);
          } else if (isFunction(propValue)) {
            return propValue(errorValue);
          }

          return propValue === errorValue;
        });
      }

      return false;
    }),
  );

  /**
   * @see https://docs.sentry.io/learn/rollups/#customize-grouping-with-fingerprints
   *
   * @param {Error} error
   * @param {Object} data
   * @returns {Object}
   */
  const getErrorFingerprint = (error, data) => {
    if (data && data.fingerprint) {
      return {};
    }

    const fingerprint = ['{{ default }}', String(error.message)];

    if (APIError.is(error)) {
      fingerprint.push(String(error.code));
    }

    return { fingerprint };
  };

  const originalCaptureException = Raven.captureException;
  Raven.captureException = function captureException(error, data, ...args) {
    try {
      error = error || new ApplicationError('Unknown captured error');
      if (!wasErrorReported(error) && !shouldIgnoreError(error)) {
        data = merge(data || {}, {
          ...getErrorFingerprint(error, data),
          extra: getExtraDataFromApiError(error),
        });
        originalCaptureException.call(this, error, data, ...args);
        markErrorAsReported(error);
      }
    } catch (ignoreError) {
    }
  };

  const FILTERED_APP_DATA = '[F]';
  const IS_ID_REGEXP = /id$/i;
  const IS_MEMBER_ID_REGEXP = /member_id$/i;

  /**
   * @param {*} crumbData
   * @returns {*}
   */
  const filterCrumbData = crumbData => {
    crumbData = toJS(crumbData);

    if (!isObject(crumbData)) {
      return crumbData;
    }

    if (isPlainObject(crumbData) || BaseModel.is(crumbData)) {
      return reduce(crumbData, (filteredCrumbData, value, key) => {
        if (IS_ID_REGEXP.test(key) && !IS_MEMBER_ID_REGEXP.test(key)) {
          filteredCrumbData[key] = value;
        } else if (BaseModel.is(value)) {
          filteredCrumbData[key] = pick(value, 'id');
        } else {
          filteredCrumbData[key] = FILTERED_APP_DATA;
        }

        return filteredCrumbData;
      }, {});
    }

    return FILTERED_APP_DATA;
  };

  /**
   * @param {Object} data
   * @returns {Object}
   */
  const dataCallback = data => {
    const breadCrumbs = data.breadcrumbs && [...data.breadcrumbs.values];

    forEach(breadCrumbs, (crumb, index) => {
      if (!crumb || !startsWith(crumb.category, 'app.') || !has(crumb, 'data.data')) {
        return;
      }

      const crumbData = crumb.data.data;

      breadCrumbs[index] = {
        ...crumb,
        data: {
          data: filterCrumbData(crumbData),
        },
      };
    }, {});

    return {
      ...data,
      breadcrumbs: breadCrumbs ?
        { ...data.breadcrumbs, values: breadCrumbs } :
        data.breadcrumbs,
    };
  };

  const ALLOWED_DOMAIN_REGEXP = /anagram\.care/;

  // Send errors to Sentry
  Raven.config(appConfig.sentryUrl, {
    serverName: location.hostname,
    release: appConfig.sentryRelease,
    // Raven's implementation doesn't cover all cases
    // e.g. it's incompatible with bluebird
    captureUnhandledRejections: false,
    stacktrace: true,
    extra: {
      apiServer: appConfig.apiHost,
    },
    autoBreadcrumbs: {
      console: true,
      xhr: true,
      dom: true,
      location: true,
    },
    maxBreadcrumbs: 50,
    dataCallback,
    whitelistUrls: [ALLOWED_DOMAIN_REGEXP],
    /**
     * Frames from non anagram.care will appear collapsed in `sentry`
     * @see `includePaths` in https://docs.sentry.io/clients/javascript/config/
     */
    includePaths: [ALLOWED_DOMAIN_REGEXP],
  }).install();
}
