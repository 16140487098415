import { flow, types } from 'mobx-state-tree';
import { values } from 'lodash';
import ms from 'ms';
import BaseMSTModel from 'app/models/BaseMSTModel';
import { ERROR_CODES, TASK_STATUS } from 'shared/constants';
import ApplicationError from 'app/errors/ApplicationError';

const BaseTaskModel = BaseMSTModel
  .named('BaseTaskModel')
  .props({
    id: types.identifierNumber,
    status: types.enumeration(values(TASK_STATUS)),
    instructions: types.maybeNull(types.string),
  })
  .volatile(() => ({
    isLoading: false,
    errorMessage: null,
  }))
  .actions(self => ({
    onAcceptTaskRequest() {
      throw new ApplicationError('You have to implement `onAcceptTaskRequest` method on `BaseTaskModel` descendant model');
    },
    accept: flow(function* accept() {
      if (self.status !== TASK_STATUS.accepted) {
        self.errorMessage = null;
        self.isLoading = true;
        try {
          yield self.onAcceptTaskRequest();
        } catch (error) {
          const isTaskGone = error.code === ERROR_CODES.gone;
          self.errorMessage = isTaskGone ?
            '🎈 This task was taken by another operator, please try next one' :
            '⚠️ EligibilityTask isn\'t started due to unexpected error, please try again';

          if (!isTaskGone) {
            setTimeout(self.resetVolatileState, ms('5s'));
          }

          throw error;
        }
      }
    }),
    resetVolatileState() {
      self.errorMessage = null;
      self.isLoading = false;
    },
  }));

export default BaseTaskModel;
