import { action } from 'mobx';
import AppContainer from 'shared/AppContainer/AppContainer';
import Logger from 'app/logger';

export const logger = new Logger({
  prefix: 'Model',
  prefixStyle: 'color:#1874cd',
});

/**
 * Base model provide webApi access and app context
 */
export default class BaseModel {
  /**
   * @param   {any}  any
   * @returns {Boolean}
   */
  static is(any) {
    return any instanceof BaseModel;
  }

  /**
   * @type {Logger}
   */
  logger = logger;

  /**
   * @returns {Object}
   */
  get app() {
    return AppContainer.app;
  }

  /**
   * @returns {Object}
   */
  get stores() {
    return this.app.stores;
  }

  /**
   * @returns {Function}
   */
  get api() {
    return this.app.api;
  }

  /**
   * Should be called to cancel reactions/subscriptions/promises etc
   */
  @action cleanup() {}
}
