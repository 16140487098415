import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './TasksCounter.css';

const transitionTime = parseInt(styles.transitionTime);

export default class TasksCounter extends React.PureComponent {
  static propTypes = {
    common: PropTypes.number,
    urgent: PropTypes.number,
  };

  static defaultProps = {
    common: 0,
    urgent: 0,
  };

  render() {
    const { common, urgent } = this.props;
    const isEmpty = !common && !urgent;
    const commonCountClasses = classnames({
      'counter__common': true,
      'counter__common_with-urgent': urgent,
    });
    const urgentCountClasses = classnames({
      counter__urgent: true,
      counter__urgent_visible: urgent,
    });

    return (
      <TransitionGroup>
        {!isEmpty && (
          <CSSTransition classNames={styles} timeout={transitionTime}>
            <span styleName="counter">
              <span styleName={urgentCountClasses}>
                {urgent}
              </span>
              {Boolean(common) && (
                <span styleName={commonCountClasses}>
                  {common}
                </span>
              )}
            </span>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}
