import { assertMethodExists } from 'shared/utils/assert';
import { REFRESH } from 'shared/history/Actions';

/**
 * Adds `history.refresh()` method,
 * that will call `history.listen` handler again with the current location
 * and `REFRESH` action
 *
 * @param {Function} createHistory
 * @returns {Function}
 */
export default function useRefresh(createHistory) {
  return function createHistoryWithRefresh(options = {}) {
    const history = createHistory(options);

    assertMethodExists(history, 'listen');

    let originalListener;
    let currentLocation;

    /**
     * Monkey patched `history.listen()`
     *
     * @param {Function} listener
     * @returns {Function} unlisten
     */
    function listen(listener) {
      originalListener = listener;

      return history.listen(function listener(location) {
        currentLocation = location;
        originalListener(location);
      });
    }

    /**
     * Calls
     */
    function refresh() {
      const location = {
        ...currentLocation,
        action: REFRESH,
      };

      originalListener(location);
    }

    return {
      ...history,
      listen,
      refresh,
    };
  };
}
