import { map } from 'lodash';
import getPayload from 'app/stores/UIStore/TaskUIStore/utils/getRelationshipPayload';

/**
 * @param {Object} options
 * @param {IObservableObject} options.formData
 * @returns {Object}
 */
export function medicalFormDataToRelationshipPayload({ formData }) {
  const { benefits, coinsurance, accumulators, ...restData } = formData;

  const relationshipPayload = getPayload({
    formData: {
      ...restData,
      benefits: map(benefits, benefit => ({
        ...benefit,
        coinsurance: coinsurance ? coinsurance.toString() : null,
      })),
    },
  });

  relationshipPayload.plan = {
    ...relationshipPayload.plan,
    deductibles: getAccumulators('deductible', accumulators),
    out_of_pockets: getAccumulators('out_of_pocket', accumulators),
  };

  return relationshipPayload;
}

/**
 * @param {'deductible'|'out_of_pocket'} type
 * @param {Object} accumulatorsFormData
 * @returns {Object}
 */
function getAccumulators(type, accumulatorsFormData) {
  return {
    out_of_network_individual: getAccumulator({
      type,
      accumulatorFormData: accumulatorsFormData.individual,
    }),
    out_of_network_family: getAccumulator({
      type,
      accumulatorFormData: accumulatorsFormData.family,
    }),
  };
}

/**
 * @param {Object} options
 * @param {'deductible'|'out_of_pocket'} options.type
 * @param {Object} options.accumulatorFormData
 * @returns {Object}
 */
function getAccumulator({ type, accumulatorFormData }) {
  const { isOutOfPocketUnlimited } = accumulatorFormData;
  const isNoOutOfPocket = type === 'out_of_pocket' && isOutOfPocketUnlimited;
  const amount = accumulatorFormData[type];
  if (amount || amount === 0 || isNoOutOfPocket) {
    return {
      amount: isNoOutOfPocket ? null : amount,
      accepted_amount: accumulatorFormData.accepted_amount,
      is_amount_unlimited: isNoOutOfPocket,
    };
  }

  return null;
}

