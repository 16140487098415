import { observable, action } from 'mobx';
import { forEach, has } from 'lodash';
import { ERROR_BOUNDARIES } from 'shared/constants';
import BaseStore from 'app/stores/BaseStore';

const DEFAULT_ERROR_TYPE = ERROR_BOUNDARIES.app;

export default class PageErrorsUIStore extends BaseStore {
  static DEFAULT_ERROR_TYPE = DEFAULT_ERROR_TYPE;

  /**
   * @type {Object}
   */
  @observable errors;

  @action reset() {
    this.errors = {
      [ERROR_BOUNDARIES.app]: null,
    };
  }

  /**
   * @param {string} errorType
   * @returns {PageError|Error|null}
   */
  getError(errorType) {
    if (!has(this.errors, errorType)) {
      this.logger.warn(`errorType ${errorType} is not defined on "errors" object`);
    }

    return this.errors[errorType] || null;
  }

  /**
   * @param {Error|null} error
   * @param {string} [errorType=DEFAULT_ERROR_TYPE]
   */
  @action setError(error, errorType = DEFAULT_ERROR_TYPE) {
    if (!has(this.errors, errorType)) {
      this.logger.warn(`errorType ${errorType} must be defined on "errors" object beforehand`);
      errorType = DEFAULT_ERROR_TYPE;
    }

    this.errors[errorType] = error;
  }

  @action clear() {
    const { errors } = this;
    forEach(errors, (error, errorType) => {
      errors[errorType] = null;
    });
  }
}
