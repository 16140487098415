import React, { Fragment } from 'react';
import { action, computed } from 'mobx';
import Dropdown from 'react-toolbox/lib/dropdown';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import PropTypes from 'prop-types';
import smart from 'shared/decorators/smartComponent';
import getTextboxFactory from 'shared/components/Form/components/TextboxFactory/TextboxFactory';
import dropdownTheme from './themes/dropdown.css';
import './StatusMessageTemplateSelect.css';

@getTextboxFactory
@smart
export default class StatusMessageTemplateSelect extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  templatesStore = this.stores.ui.task.statusUserMessageTemplates;

  componentWillUnmount() {
    this.templatesStore.abortTemplatesLoad();
  }

  handleChange = (value, event) => {
    event.preventDefault();

    this.props.onChange({
      target: {
        value,
      },
    });
  };

  @action handleFocus = () => {
    const { templatesStore } = this;

    // load items on first focus event
    if (!templatesStore.items) {
      templatesStore.loadTemplates();
    }
  };

  getUtilItem(label) {
    return {
      label,
      value: null,
    };
  }

  /**
   * @returns {Object[]}
   */
  @computed get choices() {
    const { items, isLoading, error } = this.templatesStore;
    if (isLoading) {
      return [this.getUtilItem(
        <Fragment>
          <ProgressBar styleName="loader" type="circular" />
          Loading...
        </Fragment>
      )];
    }

    if (error) {
      return [this.getUtilItem('Error loading templates')];
    }

    return items ? [this.getUtilItem('None')].concat(items.peek()) : [];
  }

  render() {
    const { placeholder, required, disabled, error, value } = this.props;

    return (
      <Dropdown
        theme={dropdownTheme}
        auto={false}
        label={`${placeholder}${required ? ' *' : ''}`}
        onChange={this.handleChange}
        source={this.choices}
        value={value}
        onFocus={this.handleFocus}
        error={error}
        disabled={disabled}
      />
    );
  }
}
