import React from 'react';
import { observer } from 'mobx-react';
import { untracked } from 'mobx';
import { bind, partial, defaults } from 'lodash';
import { commonLogger } from 'app/logger';
import { isClassComponent, setComponentName } from 'shared/utils/components';

const SAFE_REACTIVE_RENDER_PROP = '_withSafeReactiveRender_';

/**
 * @param {Function} Component
 * @returns {boolean}
 */
export function isWithSafeReactiveRender(Component) {
  return Boolean(Component && Component[SAFE_REACTIVE_RENDER_PROP]);
}

/**
 * We don't want to re-render components on previous page during page transitions.
 * It might result in errors.
 * So we stop it from happening by returning result of the previous render during that period.
 *
 * @param {Function} Component
 * @returns {Function}
 */
export default function withSafeReactiveRender(Component) {
  if (isWithSafeReactiveRender(Component)) {
    commonLogger.warn(Component, 'is already wrapped in `withSafeReactiveRender` decorator');
  }

  const EnhancedComponent = isClassComponent(Component) ? classDecorator(Component) : functionDecorator(Component);

  Object.defineProperty(EnhancedComponent, SAFE_REACTIVE_RENDER_PROP, {
    value: true,
  });

  return observer(EnhancedComponent);
}

/**
 * Manages transition end subscriptions from react components
 * We don't want to subscribe to to transition end for every component, so we only have at most 1 subscription at any point in time
 */
class TransitionEndManager {
  instances = new Set();
  unlistenTransitionEndOnce;

  /**
   * @returns {boolean}
   */
  get isForceUpdateScheduled() {
    return Boolean(this.unlistenTransitionEndOnce);
  }

  handleTransitionEndOnce = () => {
    const { instances } = this;
    instances.forEach(instance => {
      instance._isForceUpdateScheduled = false;
      instance.forceUpdate();
    });
    instances.clear();
    this.unlistenTransitionEndOnce = null;
  };

  /**
   * @param {React.Component} instance
   */
  scheduleForceUpdate(instance) {
    if (!this.isForceUpdateScheduled) {
      this.instances.clear();
      this.unlistenTransitionEndOnce = instance.stores.navigation.onTransitionEndOnce(this.handleTransitionEndOnce);
    }

    this.instances.add(instance);
    instance._isForceUpdateScheduled = true;
  }

  /**
   * @param {React.Component} instance
   */
  unscheduleForceUpdate(instance) {
    if (!this.isForceUpdateScheduled) {
      return;
    }

    const { instances } = this;
    instance._isForceUpdateScheduled = false;
    instances.delete(instance);

    if (instances.size === 0) {
      this.unlistenTransitionEndOnce();
      this.unlistenTransitionEndOnce = null;
    }
  }
}

const transitionEndManager = new TransitionEndManager();

/**
 * New methods, that will be called as well as methods, that are defined on the original component
 *
 * @type {Object}
 */
const overridingMethods = {
  /**
   * @inheritDoc
   */
  constructor() {
    this._lastRenderResult = null;
    this._isReactiveRenderDuringTransition = false;
    this._isForceUpdateScheduled = false;
  },
  /**
   * @inheritDoc
   */
  componentWillReact() {
    untracked(() => {
      if (this.stores.navigation.isTransitioning) {
        if (!this._isForceUpdateScheduled) {
          transitionEndManager.scheduleForceUpdate(this);
        }

        this._isReactiveRenderDuringTransition = true;
      }
    });
  },
  /**
   * @inheritDoc
   */
  componentWillUpdate() {
    if (this._isForceUpdateScheduled && !this._isReactiveRenderDuringTransition) {
      // This means that non-reactive update is in progress during transition
      // And we won't need to `forceUpdate` this component after that, so we can safely unsub it
      // to avoid extra render
      transitionEndManager.unscheduleForceUpdate(this);
    }
  },
  /**
   * @inheritDoc
   */
  componentDidUpdate() {
    this._isReactiveRenderDuringTransition = false;
  },
  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    if (this._isForceUpdateScheduled) {
      transitionEndManager.unscheduleForceUpdate(this);
    }
  },
  /**
   * @param {Function} baseRenderMethod original Component's render method,
   *                   we could take it from the component's prototype, but we also want to support
   *                   functional components, so we pass it as an argument instead
   * @returns {*}
   */
  render(baseRenderMethod) {
    if (this._isReactiveRenderDuringTransition) {
      return this._lastRenderResult;
    }

    // If there is an error in `baseRenderMethod` we should not store previous render result
    this._lastRenderResult = null;
    const result = this._lastRenderResult = baseRenderMethod();

    return result;
  },
};

/**
 * @param {Function} Component
 * @returns {Function}
 */
function classDecorator(Component) {
  class ComponentWithSafeReactiveRender extends Component {
    /**
     * @inheritDoc
     */
    constructor(...args) {
      super(...args);

      overridingMethods.constructor.call(this, ...args);
      this._baseRenderMethod = bind(super.render, this);
    }

    /**
     * @inheritDoc
     */
    componentWillReact(...args) {
      overridingMethods.componentWillReact.apply(this, args);

      if (super.componentWillReact) {
        super.componentWillReact(...args);
      }
    }

    /**
     * @inheritDoc
     */
    componentWillUpdate(...args) {
      overridingMethods.componentWillUpdate.apply(this, args);

      if (super.componentWillUpdate) {
        super.componentWillUpdate(...args);
      }
    }

    /**
     * @inheritDoc
     */
    componentDidUpdate(...args) {
      overridingMethods.componentDidUpdate.apply(this, args);

      if (super.componentDidUpdate) {
        super.componentDidUpdate(...args);
      }
    }
    /**
     * @inheritDoc
     */
    componentWillUnmount() {
      overridingMethods.componentWillUnmount.call(this);

      if (super.componentWillUnmount) {
        super.componentWillUnmount();
      }
    }

    /**
     * @inheritDoc
     */
    render() {
      return overridingMethods.render.call(this, this._baseRenderMethod);
    }
  }

  setComponentName(ComponentWithSafeReactiveRender, Component);

  return ComponentWithSafeReactiveRender;
}

/**
 * @param {Function} Component
 * @returns {ComponentWithObserver}
 */
function functionDecorator(Component) {
  class ComponentWithSafeReactiveRender extends React.Component { // eslint-disable-line react/no-multi-comp
    /**
     * @inheritDoc
     */
    constructor(...args) {
      super(...args);

      overridingMethods.constructor.call(this, ...args);
      this._baseRenderMethod = partial(Component, ...args);
    }

    /**
     * @inheritDoc
     */
    componentWillReact(...args) {
      overridingMethods.componentWillReact.apply(this, args);
    }

    /**
     * @inheritDoc
     */
    componentWillUpdate(nextProps, nextState, nextContext) {
      // Need to re-apply it partially, because the props might have changed
      // and functional component receives them as an argument
      this._baseRenderMethod = partial(Component, nextProps, nextContext);
      overridingMethods.componentWillUpdate.call(this, nextProps, nextState, nextContext);
    }

    /**
     * @inheritDoc
     */
    componentDidUpdate(...args) {
      overridingMethods.componentDidUpdate.apply(this, args);
    }

    /**
     * @inheritDoc
     */
    componentWillUnmount() {
      overridingMethods.componentWillUnmount.call(this);
    }

    /**
     * @inheritDoc
     */
    render() {
      return overridingMethods.render.call(this, this._baseRenderMethod);
    }
  }

  defaults(ComponentWithSafeReactiveRender, Component);
  setComponentName(ComponentWithSafeReactiveRender, Component);

  return ComponentWithSafeReactiveRender;
}
