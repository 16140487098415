import t from 'tcomb-form-plus';
import { TEMPLATE_INPUT_NAME_PREFIX } from 'app/stores/UIStore/TaskUIStore/utils/isStatusUserMessageTemplateKey';
import StatusMessageTemplateSelect from 'shared/components/StatusMessageTemplateSelect/StatusMessageTemplateSelect';
import inlineInputTheme from 'shared/components/PlanTasksPage/components/Task/components/VisionPlanTask/components/TaskPatient/themes/inlineInputTheme.css';
import { getPatientOptions } from './Patient';
import { getPlanOptions } from './Plan';

export const TEMPLATE_INPUT_REGEXP = /_+/g;

/**
 * @param {Object} options
 * @param {boolean} options.isVerified
 * @param {t.Type} options.patientModel
 * @param {t.Type} options.benefitsModel
 * @param {t.Type} [options.planModel]
 * @param {String} [options.statusUserMessageTemplate]
 * @param {Object} [options.extraFields]
 * @returns {t.Type}
 */
export function getPlanTaskModel({
  isVerified,
  statusUserMessageTemplate,
  patientModel,
  planModel,
  benefitsModel,
  extraFields,
}) {
  let PlanTaskModel = t.struct({
    is_verified: t.Boolean,
  });

  if (!isVerified) {
    PlanTaskModel = PlanTaskModel.extend({
      status_user_message: t.maybe(t.String),
      ...mapTemplateInputs(statusUserMessageTemplate, () => t.String),
    });

    return PlanTaskModel;
  }

  PlanTaskModel = PlanTaskModel.extend({
    patient: patientModel,
    benefits: benefitsModel,
    ...extraFields,
  });

  if (planModel) {
    PlanTaskModel = PlanTaskModel.extend({
      plan: planModel,
    });
  }

  return PlanTaskModel;
}

export function mapTemplateInputs(template, predicate) {
  if (template) {
    const templateInputMatches = template.match(TEMPLATE_INPUT_REGEXP);
    if (templateInputMatches) {
      return templateInputMatches.reduce((acc, _, index) => {
        acc[`${TEMPLATE_INPUT_NAME_PREFIX}${index}`] = predicate();

        return acc;
      }, {});
    }
  }

  return {};
}

/**
 * @param {Object} options
 * @param {Object} options.formStore
 * @param {ComponentType} options.PatientLayout
 * @param {ComponentType} options.PlanLayout
 * @param {string|null} options.statusUserMessageTemplate
 * @param {boolean} options.isRelationshipVerified
 * @param {Object} [options.relationshipTypeOptions]
 * @returns {Object}
 */
export function getPlanTaskOptions({
  formStore,
  PatientLayout,
  PlanLayout,
  statusUserMessageTemplate,
  relationshipTypeOptions,
  isRelationshipVerified,
}) {
  return {
    is_verified: {
      label: 'Has Insurance',
    },
    status_user_message: {
      placeholder: 'Optional message for provider',
      factory: StatusMessageTemplateSelect,
    },
    ...mapTemplateInputs(statusUserMessageTemplate, () => ({
      placeholder: '',
      theme: inlineInputTheme,
    })),
    patient: getPatientOptions({ PatientLayout, formStore, relationshipTypeOptions, isRelationshipVerified }),
    plan: getPlanOptions({ PlanLayout }),
  };
}
