import { types } from 'mobx-state-tree';
import { invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';
import Insurer from './Insurer';

const PRE_MAPPING = {
  isVision: 'is_vision',
  isMedical: 'is_medical',
};

const POST_MAPPING = invert(PRE_MAPPING);

export default Insurer
  .named('FullInsurer')
  .props({
    isVision: types.boolean,
    isMedical: types.boolean,
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));
