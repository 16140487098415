import { invert } from 'lodash';
import { types } from 'mobx-state-tree';
import BaseMSTModel from 'app/models/BaseMSTModel';
import PlanAccumulator from 'app/models/PlanAccumulator';
import { mapSnapshotProps, transformSnapshot } from 'app/shared/utils/snapshot';

const PRE_MAPPING = {
  inNetworkFamily: 'in_network_family',
  outOfNetworkFamily: 'out_of_network_family',
  universalFamily: 'universal_family',
  inNetworkIndividual: 'in_network_individual',
  outOfNetworkIndividual: 'out_of_network_individual',
  universalIndividual: 'universal_individual',
};

const POST_MAPPING = invert(PRE_MAPPING);

export default BaseMSTModel
  .named('PlanAccumulators')
  .props({
    inNetworkFamily: types.maybeNull(PlanAccumulator),
    outOfNetworkFamily: types.maybeNull(PlanAccumulator),
    universalFamily: types.maybeNull(PlanAccumulator),
    inNetworkIndividual: types.maybeNull(PlanAccumulator),
    outOfNetworkIndividual: types.maybeNull(PlanAccumulator),
    universalIndividual: types.maybeNull(PlanAccumulator),
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));
