import getLocationPath from 'shared/utils/history/getLocationPath';
import { assertMethodExists } from 'shared/utils/assert';
import getWindowLocationOrigin from 'shared/utils/getWindowLocationOrigin';
import { commonLogger } from 'app/logger';

/**
 * Changes `window.location.href` directly if `history.pushState` or `history.replaceState` throw
 *
 * The reason for this is that Mobile Safari throws an error if there were 100 URL changes made
 * using history api within 30 seconds(in reality it seems to be much longer). It then throws
 * `SecurityError: Attempt to use history.replaceState() more than 100 times per 30.000000 seconds`
 * or
 * `Error: SecurityError: DOM Exception 18`
 * depending on the version.
 *
 * @see https://github.com/ReactTraining/history/issues/291
 * @see http://jdurand.com/blog/2016/05/03/ember-history-pushstate-dom-exception-18/
 * @see https://codepen.io/morten-olsen/post/when-safari-broke-webapps
 *
 * @param {Function} createHistory
 * @returns {Function}
 */
export default function useHistoryApiFallback(createHistory) {
  return function createHistoryWithFallback(options = {}) {
    const history = createHistory(options);

    assertMethodExists(history, 'push');
    assertMethodExists(history, 'replace');

    /**
     * @param {string} historyMethod
     * @param {Object} location
     * @param {...*} args
     * @returns {*}
     */
    function withHistoryApiFallback(historyMethod, location, ...args) {
      try {
        return history[historyMethod](location, ...args);
      } catch (error) {
        commonLogger.error(error);
        const locationOrigin = getWindowLocationOrigin(window.location);
        const locationPath = getLocationPath(location);

        const locationMethod = (historyMethod === 'replace') ? 'replace' : 'assign';
        const url = `${locationOrigin || ''}${locationPath}`;

        window.location[locationMethod](url);
      }
    }

    /**
     * Monkey patched `history.push()`
     *
     * @param {...*} args
     * @returns {*}
     */
    function push(...args) {
      return withHistoryApiFallback('push', ...args);
    }

    /**
     * Monkey patched `history.replace()`
     *
     * @param {...*} args
     * @returns {*}
     */
    function replace(...args) {
      return withHistoryApiFallback('replace', ...args);
    }

    return {
      ...history,
      push,
      replace,
    };
  };
}
