import { types } from 'mobx-state-tree';
import { invert } from 'lodash';
import { mapSnapshotProps, transformSnapshot } from 'shared/utils/snapshot';

// Used for package benefits because its coverage calculated from items
export const FLAG_NO_COVERAGE = 'NO_COVERAGE';

const PRE_MAPPING = {
  items: 'children',
};
const POST_MAPPING = invert(PRE_MAPPING);

const PlanTemplateBenefit = types
  .model('PlanTemplateBenefit', {
    id: types.identifier,
    name: types.maybeNull(types.string),
    items: types.maybeNull(types.array(types.late(() => PlanTemplateBenefit))),
    flags: types.maybeNull(types.array(types.string)),
  })
  .preProcessSnapshot(transformSnapshot.pre(mapSnapshotProps(PRE_MAPPING)))
  .postProcessSnapshot(transformSnapshot.post(mapSnapshotProps(POST_MAPPING)));

export default PlanTemplateBenefit;
