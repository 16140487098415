import { forEach, isFunction } from 'lodash';
import getLocationPath from 'shared/utils/history/getLocationPath';
import Logger from 'app/logger';
import RedirectError from 'errors/RedirectError';

const logger = new Logger({
  prefix: 'AccessManager',
  prefixStyle: 'color:#9a32cd',
});

export default class AccessManager {
  /**
   * @constructor
   * @param {Object} context
   */
  constructor(context) {
    this.context = context;
    this.logger = logger;
  }

  /**
   * @param {ActiveRoute} activeRoute
   * @returns {boolean}
   */
  isRouteNeedsCheckAccess(activeRoute) {
    const { Component } = activeRoute;

    return Boolean(Component) && Array.isArray(Component.checkAccess);
  }

  /**
   * @param {string} key
   * @param {LocationDescriptor} location
   */
  saveReturnToLocation(key, location) {
    this.context.stores.navigation.returnTo[key] = location;
  }

  /**
   * @param {Function} checkFn
   * @param {ActiveRoute} activeRoute
   * @throws {RedirectError}
   */
  runCheckAccessFn = (checkFn, activeRoute) => {
    const { routeName, routeContext } = activeRoute;
    const { context, logger } = this;
    const { stores } = context;
    const result = checkFn(stores, routeContext);

    if (!result) {
      return;
    }

    if (isFunction(result)) {
      result(stores, routeContext);
    } else if (result.error) {
      const { error } = result;
      logger.debug(`Route "${routeName}" denied access with error`, error);

      throw error;
    } else {
      const { redirectTo, onRedirect, returnToKey } = result;

      logger.debug(`Route "${routeName}" initiated redirect to`, redirectTo);

      if (returnToKey) {
        this.saveReturnToLocation(returnToKey, routeContext.location);
      }

      if (onRedirect) {
        onRedirect(stores, routeContext);
      }

      throw new RedirectError(redirectTo);
    }
  };

  /**
   * @param {Object} options
   * @param {Object} options.routerState
   * @param {ActiveRoute[]} options.activeRoutes
   */
  check({ routerState, activeRoutes }) {
    const path = getLocationPath(routerState.location);
    this.logger.debug(`Checking access for path "${path}"`);

    forEach(activeRoutes, activeRoute => {
      const { Component } = activeRoute;

      if (this.isRouteNeedsCheckAccess(activeRoute)) {
        forEach(Component.checkAccess, checkFn => {
          this.runCheckAccessFn(checkFn, activeRoute);
        });
      }
    });
  }
}
