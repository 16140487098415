import APIError from 'errors/APIError';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

class TaskTimeoutAPIError extends APIError {
  /**
   * @param {...*} args
   */
  constructor(...args) {
    super(...args);
    this.name = 'TaskTimeoutAPIError';
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
TaskTimeoutAPIError.is = getErrorTypeCheckFor(TaskTimeoutAPIError);

export default TaskTimeoutAPIError;
