import { isString } from 'lodash';
import t from 'tcomb-form-plus';
import fecha from 'fecha';
import { DateString } from 'app/models/forms/shared';
import { formatDate, parseDate, DATE_FORMATS } from 'shared/utils/date';
import { dateString } from 'shared/utils/validators';

/**
 * Transforms masked values to pure values according to its model
 */
export default class MaskedValueTransformer {
  /**
   * Possible mask delimiters. Can be updated in future to support date transforms
   * @type {RegExp}
   */
  static MASK_DELIMETERS = /\s|\(|\)|-|\$/g;

  /**
   * Save inner field type into transformer instance
   *
   * @param  {t.any}  options.typeInfo Tcomb type
   * @param  {String} options.mask
   */
  constructor({ typeInfo, mask }) {
    this.setTypeInfo(typeInfo);
    this.setMask(mask);
  }

  mask = '';

  /**
   * Update field type
   *
   * @param {tcomb.any} type
   */
  setTypeInfo(type) {
    this.typeInfo = type;
  }

  /**
   * Update field mask
   *
   * @param {String} mask
   */
  setMask(mask) {
    this.mask = mask;
  }

  /**
   * Checks current field type
   *
   * @returns {Boolean}
   */
  isDate() {
    let { type } = this.typeInfo;

    if (type.meta.kind === 'maybe') {
      type = type.meta.type;
    }

    return type === DateString;
  }

  /**
   * Format model value to masked. Actually do nothing because
   * this stuff handled by react-masked-input
   *
   * @param  {any} value
   * @returns {any} value
   */
  format(value) {
    if (this.isDate() && dateString(value)) {
      const date = parseDate(value, DATE_FORMATS.server);

      return fecha.format(date, this.mask);
    }

    return t.form.Textbox.transformer.format(value);
  }

  /**
   * Parse input value to unmasked value according to field inner type
   *
   * @param  {any} maskedValue Parameter passed by tcomb-form
   * @returns {any}            Unmasked value
   */
  parse(maskedValue) {
    if (!isString(maskedValue)) {
      return maskedValue;
    }

    const textTransformer = t.form.Textbox.transformer.parse;
    const unmaskedValue = maskedValue.replace(MaskedValueTransformer.MASK_DELIMETERS, '');
    const { innerType } = this.typeInfo;
    if (innerType === t.Number || innerType === t.Integer) {
      return t.form.Textbox.numberTransformer.parse(unmaskedValue);
    } else if (this.isDate()) {
      return textTransformer(this.dateToString(maskedValue));
    }

    return textTransformer(unmaskedValue);
  }

  /**
   * Date parser
   *
   * @param  {String} str
   * @returns {String|Date}
   */
  dateToString(str = '') {
    let date;
    if (isString(str) && !dateString(str) && str.length === this.mask.length) {
      const [month, day] = str.split('/');
      if (month > 12 || day > 31) {
        return str;
      }

      date = fecha.parse(str, this.mask);

      // extract full year from date object because mask can contain only two last digits on year
      const year = date.getFullYear();
      if (year < 1900 || year > 2099) {
        return str;
      }
    }

    if (date) {
      return formatDate(date, DATE_FORMATS.server);
    }

    return str;
  }
}
