import { types } from 'mobx-state-tree';
import ClaimStatusTask from 'app/models/ClaimStatusTask';
import BaseTasksStore from './BaseTasksStore';

export const PARAM_CLAIM_ID = 'claim_id';
export const PARAM_FIRST_NAME = 'first_name';
export const PARAM_LAST_NAME = 'last_name';
export const PARAM_PROVIDER_LOCATION = 'provider_location';
export const PARAM_CLAIM_STATUS = 'claim_status';
export const PARAM_AOB = 'is_aob_enabled';
export const PARAM_CREATED_AT = 'created_at';

const ClaimStatusTasksStore = BaseTasksStore
  .named('ClaimStatusTasksStore')
  .props({
    items: types.map(ClaimStatusTask),
    activeTask: types.maybeNull(types.reference(ClaimStatusTask)),
  })
  .views(() => ({
    /**
     * @return {string}
     */
    get endpointPath() {
      return '/call-center/claim-status/tasks/';
    },
  }));

export default ClaimStatusTasksStore;
