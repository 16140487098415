import { isFunction, curry } from 'lodash';
import ApplicationError from 'app/errors/ApplicationError';

/**
 * Ensure that passed object has given method
 *
 * @param   {*} object
 * @param   {String} methodName
 * @param   {String} [message]
 * @throws  ApplicationError
 */
export const assertMethodExists = curry(function assertMethodExists(
  object,
  methodName,
  message = 'Passed object should provide method',
) {
  if (!object || !isFunction(object[methodName])) {
    throw new ApplicationError(`${message} "${methodName}"`);
  }
});
