import DataFetcher from './DataFetcher';

/**
 * Browser data fetcher
 */
export default class ClientDataFetcher extends DataFetcher {
  /**
   * @inheritDoc
   */
  getRouteData(activeRoute, options) {
    if (options.assertTransitionCurrent) {
      options.assertTransitionCurrent();
    }

    if (activeRoute.state.isDataFetched) {
      return null;
    }

    return super.getRouteData(activeRoute, options);
  }

  /**
   * @inheritDoc
   */
  processRouteData(routeData, activeRoute, options) {
    super.processRouteData(routeData, activeRoute, options);
    activeRoute.setState({ isDataFetched: true });
  }
}
