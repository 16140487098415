import ApplicationError from 'errors/ApplicationError';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';
import getLocationPath from 'shared/utils/history/getLocationPath';

/**
 * @param {string|*} _message
 * @param {LocationDescriptor} location
 * @returns {string}
 */
function getMessage(_message, location) {
  let message = 'RouteCancellationError';
  if (location) {
    message += ` [${getLocationPath(location)}]`;
  }

  if (_message) {
    message += `: ${_message}`;
  }

  return message;
}

/**
 * Redirection Error class
 */
class RouteCancellationError extends ApplicationError {
  /**
   * @constructor
   * @param {string} _message
   * @param {LocationDescriptor} [location]
   */
  constructor(_message, location) {
    super(getMessage(_message, location));

    this.name = 'RouteCancellationError';
    this.setLocation(location);
  }

  /**
   * @param {LocationDescriptor} location
   */
  setLocation(location) {
    this.location = location || null;
    this.message = getMessage(this._message, location);
  }

  /**
   * @returns {Object}
   */
  toJSON() {
    return {
      ...super.toJSON(),
      location: this.location,
    };
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
RouteCancellationError.is = getErrorTypeCheckFor(RouteCancellationError);

export default RouteCancellationError;

