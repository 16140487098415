export {
  PUSH,
  REPLACE,
  POP,
} from 'history/lib/Actions';

/**
 * REFRESH action type
 * located on `location.action` after call to `history.refresh()`
 *
 * @type {string}
 */
export const REFRESH = 'REFRESH';
