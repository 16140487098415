export function get(...args) {
  return this.get(...args);
}

export function post(...args) {
  return this.post(...args);
}

export function put(...args) {
  return this.put(...args);
}

export function patch(...args) {
  return this.patch(...args);
}

// export function delete(...args) {
//   return this.delete(...args);
// }
