import { action } from 'mobx';
import ms from 'ms';
import 'afterlag-js';
import history, { applyHistoryMiddlewares } from 'shared/history';
import AccessManager from 'entries/shared/accessManager';
import DataFetcher from 'entries/shared/dataFetcher';
import PageError from 'errors/PageError';
import routes from 'app/routes';
import { startNProgress, endNProgress } from 'shared/utils/NProgress';
import Logger from 'app/logger';
import localStore from 'shared/utils/localStorage';
import { renderApp, renderUnknownPageError, renderFatalError, unmountApp } from './renderApp';
import TransitionManager from './transitionManager';

export default async function startApp(app) {
  // Fetch current user info before app initialization to perform synchronous auth checks in components
  await app.stores.auth.tryAuthByToken(localStore.get('auth'));
  listenForTransitions(app);
}

const logger = new Logger({
  prefix: 'Router',
  prefixStyle: 'color:#938A08',
});

/**
 * Starts the app, by trying to fetch user and setting up history listener
 *
 * @param {AppContainer} app
 */
function listenForTransitions(app) {
  const { stores } = app;
  const navigationStore = stores.navigation;

  /**
   * @param {Transition} transition
   * @returns {ReactComponent}
   */
  function render(transition) {
    return renderApp(app, transition);
  }

  /**
   * @param {Error} error
   */
  function logAndReportError(error) {
    logger.logError(error, {
      tags: { culprit: 'startApp.transitionManagerListener' },
    });
  }

  /**
   * @param {PageError} error
   * @param {Transition} transition
   */
  function handlePageError(error, transition) {
    stores.pageErrorsUi.setError(error, error.errorType);
    render(transition);

    if (PageError.is(error)) {
      logger.debug(error);
    } else {
      logAndReportError(error);
    }
  }

  /**
   * @param {Error} fatalError
   */
  function handleFatalError(fatalError) {
    try {
      // First try to render nice and pretty react page error
      renderUnknownPageError();
      logAndReportError(fatalError);
    } catch (fatalError2) {
      // If it failed, unmount the app and render a simple fatal error message
      unmountApp();
      renderFatalError();
      if (fatalError2 !== fatalError) {
        logAndReportError(fatalError2);
      }
    }
  }

  function handleFirstTransitionEnd() {
    // inline styles import causes minification error because it can't be transformed correctly by webpack
    const loaderFadeOutTime = 300;

    const afterlag = new Afterlag({ timeout: ms('2s') });
    afterlag.run(() => {
      const loadingWrapper = document.getElementById('loadingWrapper');
      // Check needed for HMR because it will try to remove this node again
      if (!loadingWrapper) {
        return;
      }

      loadingWrapper.setAttribute('class', 'loading-wrapper loading-wrapper_hidden');
      setTimeout(() => {
        loadingWrapper.parentNode.removeChild(loadingWrapper);
      }, loaderFadeOutTime);
    });
  }

  applyHistoryMiddlewares({
    onTransitionEndOnce: navigationStore.onTransitionEndOnce,
  });

  if (process.env.EXPOSE_INTERNALS) {
    window.appHistory = history;
  }

  const transitionManager = new TransitionManager({
    history,
    logger,
    routes,
    initialRouteState: {
      isDataFetched: false,
      fetchedDataProp: null,
    },
    onTransitionFlowStart(transition) {
      if (!transition.isFirst) {
        startNProgress();
      }

      return new Promise(resolve => {
        setTimeout(() => {
          // Make transition start on next tick so that
          // scheduled reactions are guaranteed to be executed before it
          navigationStore.startTransition();
          resolve();
        }, 0);
      });
    },
    onTransitionFlowEnd(transition) {
      if (transition.isFirst) {
        handleFirstTransitionEnd();
      }

      navigationStore.endTransition();
      endNProgress();
    },
  });

  const accessManager = new AccessManager(app);
  const dataFetcher = new DataFetcher(app);

  const unlisten = transitionManager.listen(action(async (transition, matchError) => {
    const { location, prevLocation, activeRoutes, routerState, assertTransitionCurrent } = transition;

    try {
      navigationStore.currentLocation = location;
      navigationStore.prevLocation = prevLocation;

      if (matchError) {
        throw matchError;
      }

      accessManager.check({ routerState, activeRoutes });
      const routesData = await dataFetcher.fetch({ routerState, activeRoutes, assertTransitionCurrent });
      assertTransitionCurrent();
      dataFetcher.process(routesData, { routerState, activeRoutes });
      render(transition);
      transition.end();
    } catch (error) {
      if (transition.isTransitionError(error)) {
        transition.handleTransitionError(error);
      } else {
        try {
          handlePageError(error, transition);
        } catch (fatalError) {
          handleFatalError(fatalError);
        } finally {
          transition.end();
        }
      }
    }
  }));

  // HMR
  if (module.hot) {
    module.hot.dispose(data => {
      data.path = navigationStore.currentPath;
      unlisten();
    });
  }
}
