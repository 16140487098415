import ApplicationError from 'errors/ApplicationError';
import { ERROR_MESSAGES } from 'shared/constants';
import getErrorTypeCheckFor from 'errors/utils/getErrorTypecheckFor';

/**
 * @exports PageError
 */
class PageError extends ApplicationError {
  /**
   * @inheritdoc
   */
  constructor(code, props, message = ERROR_MESSAGES[code]) {
    super(message);

    this.name = 'PageError';
    this.code = code;
    this.props = props;
    this.message = message;
  }

  /**
   * Describes how to convert error to json
   *
   * @returns {Object}
   */
  toJSON() {
    return {
      ...super.toJSON(),
      code: this.code,
      props: this.props,
    };
  }
}

/**
 * @param {*} any
 * @param {Function|Object} [predicate]
 * @returns {boolean}
 */
PageError.is = getErrorTypeCheckFor(PageError);

export default PageError;
