import t from 'tcomb-form-plus';
import { get, omit } from 'lodash';
import { UI_RELATIONSHIP_TYPES } from 'app/models/Relationship';
import { MASKS } from 'shared/constants';
import { Name, MemberId, DateString } from './shared';

const BasePatientModel = t.struct({
  first_name: Name,
  last_name: Name,
});

export const MedicalPatientModel = BasePatientModel.extend({
  dob: DateString,
  member_id: MemberId,
  relationship_type_name: t.String,
});

const UI_RELATIONSHIP_TYPES_DEPENDENT = omit(UI_RELATIONSHIP_TYPES, 'self');

/**
 * @param {boolean} isTypeSelf
 * @returns {t.struct}
 */
export default function getVisionPatientModel({ isTypeSelf }) {
  const relationshipTypes = isTypeSelf ? UI_RELATIONSHIP_TYPES : UI_RELATIONSHIP_TYPES_DEPENDENT;
  const dobType = isTypeSelf ? DateString : t.maybe(DateString);

  return BasePatientModel.extend({
    dob: dobType,
    relationship_type_name: t.enums(relationshipTypes),
    member_id: t.maybe(MemberId),
  });
}

export function getPatientOptions({ PatientLayout, formStore, relationshipTypeOptions, isRelationshipVerified }) {
  const isTypeSelf = get(formStore, 'data.patient.relationship_type_name') === 'self';

  return {
    template: PatientLayout,
    fields: {
      first_name: {
        placeholder: 'First name',
        inline: true,
        disabled: isRelationshipVerified,
      },
      last_name: {
        placeholder: 'Last name',
        inline: true,
        disabled: isRelationshipVerified,
      },
      dob: {
        placeholder: 'Date of birth (MM/DD/YYYY)',
        mask: MASKS.date,
        inline: true,
        disabled: isRelationshipVerified,
      },
      relationship_type_name: {
        placeholder: 'Relationship type',
        inline: true,
        disabled: isTypeSelf,
        ...(relationshipTypeOptions || {}),
      },
      member_id: {
        placeholder: 'Member Id',
        inline: true,
      },
    },
  };
}
