const userAgent = (navigator && navigator.userAgent || '').toLowerCase();
const BROWSERS = [
  {
    id: 'ie',
    minVersion: 11,
    versionRegexp: /(?:msie |trident.+?; rv:)(\d+)/,
  }, {
    id: 'opera',
    minVersion: 15,
    versionRegexp: /(?:^opera.+?version|opr)\/(\d+)/,
  }, {
    id: 'firefox',
    minVersion: 29,
    versionRegexp: /(?:firefox|fxios)\/(\d+)/,
  }, {
    id: 'safari',
    minVersion: 8,
    version: null,
    versionRegexp: /version\/(\d+).+?safari/,
  }, {
    id: 'chrome',
    minVersion: 22,
    versionRegexp: /(?:chrome|crios)\/(\d+)/,
  },
  // TODO: test old android devices
];

let currentBrowser = null;

/**
 * Find current browser and its version
 *
 * @param  {Object} browser
 * @returns {Object|null}
 */
export function getBrowser() {
  if (currentBrowser) {
    return currentBrowser;
  }

  for (let i = 0; i < BROWSERS.length; i++) {
    const browser = BROWSERS[i];

    const match = userAgent.match(browser.versionRegexp);
    if (match) {
      currentBrowser = {
        id: browser.id,
        version: parseInt(match[1]),
        minVersion: browser.minVersion,
      };

      return currentBrowser;
    }
  }

  return null;
}

/**
 * Checks browser version
 *
 * @returns {Boolean}
 */
export function checkBrowserSupport() {
  const browser = getBrowser();
  if (browser && browser.version < browser.minVersion) {
    return false;
  }

  return true;
}
