import useRouterHistory from 'react-router/lib/useRouterHistory';
import useRefresh from './middleware/useRefresh';
import useWaitForTransitionEnd from './middleware/useWaitForTransitionEnd';
import useHistoryApiFallback from './middleware/useHistoryApiFallback';
import createApplyMiddlewares from './createApplyMiddlewares';

const middlewares = [
  useRefresh,
  useRouterHistory,
  useWaitForTransitionEnd,
];

let history;
if (process.env.isClient) {
  history = require('history/lib/createBrowserHistory').default();
  middlewares.unshift(useHistoryApiFallback);
} else {
  history = require('history/lib/createMemoryHistory').default();
}

export const applyHistoryMiddlewares = createApplyMiddlewares(history, middlewares);

export default history;
