const MAX_ATTEMPTS = 2;

/**
 * @param {Function} getAsyncComponent
 * @param {Object} options
 * @returns {Promise.<*>}
 */
async function loadAsyncComponent(getAsyncComponent, { attempt = 1 } = {}) {
  try {
    const Component = await getAsyncComponent();
    const isEs6DefaultExport = Component && Component.__esModule;

    return isEs6DefaultExport ? Component.default : Component;
  } catch (error) {
    // Attempt to load it again just in case
    if (attempt < MAX_ATTEMPTS) {
      return loadAsyncComponent(getAsyncComponent, { attempt: attempt + 1 });
    }

    // We return error to always have `routerState` in `transitionManagerListener`
    return error;
  }
}

/**
 * Lazily gets component from it's getter that returns promise
 *
 * @param {Function} getAsyncComponent
 * @returns {Function}
 */
export default function getLazily(getAsyncComponent) {
  /**
   * @returns {Promise}
   */
  return () => loadAsyncComponent(getAsyncComponent);
}

