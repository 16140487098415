import Raven from 'raven-js';
import { transform, assign, noop, bind, map } from 'lodash';
import { markErrorAsLogged, wasErrorLogged } from 'errors/utils';
import isColorSupported from './utils/isColorSupported';

export const DEFAULT_LOG_METHODS = ['debug', 'info', 'warn', 'error', 'log'];

export default class ClientLogger {
  /**
   * @param {Object} options
   * @returns {ClientLogger}
   */
  static create(options) {
    return new ClientLogger(options);
  }

  /**
   * @constructor
   * @param {Object} [options]
   * @param {string|string[]} [options.prefix='']
   * @param {string} [options.prefixStyle='color:inherit']
   * @param {string[]} [options.logMethods=DEFAULT_LOG_METHODS]
   */
  constructor({ prefix = '', prefixStyle = 'color:inherit', logMethods = DEFAULT_LOG_METHODS } = {}) {
    if (!Array.isArray(logMethods)) {
      throw new TypeError(`"logMethods" must be of type "Array" in ClientClientLogger for prefix: ${prefix}`);
    }

    this.prefix = prefix;
    this.prefixStyle = prefixStyle;

    const boundLogMethods = transform(logMethods, (result, method) => {
      result[method] = bind(this.logMethod, this, method);
    }, {});

    assign(this, boundLogMethods);
  }

  /**
   * @param {Error} error
   * @param {Object} [options]
   */
  logError(error, options) {
    let level = options && options.level || 'error';
    level = (level === 'warning') ? 'warn' : level;

    try {
      if (!wasErrorLogged(error)) {
        const errorJSONArgs = error.toJSON ? ['\n', error.toJSON()] : [];
        this[level || 'log'](error, ...errorJSONArgs);

        markErrorAsLogged(error);
      }

      Raven.captureException(error, options);
    } catch (ignoreError) {
    }
  }
}

if (process.env.isProd) {
  ClientLogger.prototype.logMethod = noop;
} else {
  ClientLogger.prototype.logMethod = isColorSupported() ?
    colorLogMethod :
    noColorLogMethod;
}

/**
 * @param {string|string[]|null} prefix
 * @returns {string}
 */
function getPrefixString(prefix) {
  if (!prefix) {
    return '';
  }

  if (Array.isArray(prefix)) {
    return map(prefix, getPrefixString).join('');
  }

  return `[${prefix}]`;
}

/**
 * Log method for console with color support
 *
 * @param {string|*} method method to call on console
 * @param {...*} args method arguments
 */
function colorLogMethod(method, ...args) {
  const prefixString = getPrefixString(this.prefix);

  const prefixArgs = [];
  if (prefixString) {
    prefixArgs.push(`%c${prefixString}`, this.prefixStyle);
  }

  // eslint-disable-next-line no-console
  console[method](...prefixArgs, ...args);
}

/**
 * Log method for console without color support
 *
 * @param {string|*} method method to call on console
 * @param {...*} args method arguments
 */
function noColorLogMethod(method, ...args) {
  const prefixString = getPrefixString(this.prefix);

  // eslint-disable-next-line no-console
  console[method](prefixString, ...args);
}

/**
 * @type {ClientLogger}
 */
export const commonLogger = ClientLogger.create();
