import { types, getEnv } from 'mobx-state-tree';
import { logger } from './BaseStore';

export default types
  .model('BaseMSTStore')
  .views(self => {
    const context = getEnv(self);

    return {
      /**
       * @returns {Object}
       */
      get stores() {
        return context.stores;
      },

      /**
       * @returns {Function}
       */
      get api() {
        return context.api;
      },

      /**
       * @returns {Object}
       */
      get logger() {
        return logger;
      },
    };
  })
  .actions(() => ({
    reset() {},
  }));
