import Axios from 'axios';
import { forEachRight, get, merge, isFunction, identity } from 'lodash';
import appConfig from 'app/config';
import deleteEmptyHeaders from './interceptors/deleteEmptyHeaders';

/**
 * Creates axios adapter
 *
 * @param {Object} [options] axios options
 * @returns {Object}
 */
export default function createAxiosRequester(options) {
  options = options || {};

  const {
    onRequestStart,
    onRequestSuccess,
    onRequestError,
    ...config
  } = options;

  const DEFAULT_CONFIG = {
    responseType: 'text',
    headers: {
      common: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  };

  const axios = Axios.create(merge(DEFAULT_CONFIG, config));

  const requestInterceptors = [
    onRequestStart ? config => onRequestStart(config, config.callInfo) : identity,
    // Add ability to delete headers even if defaults are set for given header
    deleteEmptyHeaders,
  ];

  // Axios applies them in the reversed order, so we use `forEachRight` instead of `forEach`
  forEachRight(requestInterceptors, interceptor => {
    if (isFunction(interceptor)) {
      axios.interceptors.request.use(interceptor);
    }
  });

  /**
   * Returns function, that calls method with attached `.then()` and `.catch()` handlers
   *
   * @param {string} method method name
   * @returns {Function}
   */
  function withResponseHandler(method) {
    /**
     * @param {Object} callInfo
     * @param {...*} args
     * @throws {APIError|Error}
     * @returns {Promise.<*>}
     */
    return async function methodWithResponseHandler(callInfo, ...args) {
      try {
        const response = await method(callInfo, ...args);

        if (onRequestSuccess) {
          return await Promise.resolve(onRequestSuccess(response, callInfo));
        }

        return response.data;
      } catch (error) {
        if (onRequestError) {
          const { response, config } = error;
          const details = {
            code: error.code,
            request: {
              url: config.url,
              baseURL: config.baseURL,
              method: config.method,
              headers: config.headers,
              params: config.params,
              auth: config.auth,
              timeout: config.timeout,
              responseType: config.responseType,
              proxy: config.proxy,
              withCredentials: config.withCredentials,
            },
            data: get(response, 'data'),
            status: get(response, 'status'),
            statusText: get(response, 'statusText'),
            headers: get(response, 'headers'),
          };

          return Promise.resolve(onRequestError(error, details, callInfo));
        }

        throw error;
      }
    };
  }

  return {
    /**
     * Debug method
     *
     * @param {string} url
     */
    setBaseUrl(url) {
      axios.defaults.baseURL = url;
      appConfig.apiHost = url;
    },
    /**
     * @return {string}
     */
    getBaseUrl() {
      return axios.defaults.baseURL;
    },
    /**
     * Wrapper around axios's GET method
     * accepts params as second argument and rest of config in the 3rd argument
     *
     * @param {string} url
     * @param {Object} [params]
     * @param {Object} [config]
     * @returns {Promise}
     */
    get: withResponseHandler((callInfo, url, params, config) => axios.get(url, {
      ...config,
      params,
      callInfo,
    })),

    /**
     * Wrapper around axios post method
     *
     * @param {...*} args
     * @returns {Promise}
     */
    post: withResponseHandler((callInfo, url, data, config) => axios.post(url, data, {
      ...config,
      callInfo,
    })),

    /**
     * Wrapper around axios put method
     *
     * @param {...*} args
     * @returns {Promise}
     */
    put: withResponseHandler((callInfo, url, data, config) => axios.put(url, data, {
      ...config,
      callInfo,
    })),

    /**
     * Wrapper around axios patch method
     *
     * @param {...*} args
     * @returns {Promise}
     */
    patch: withResponseHandler((callInfo, url, data, config) => axios.patch(url, data, {
      ...config,
      callInfo,
    })),

    /**
     * Wrapper around axios delete method
     *
     * @param {...*} args
     * @returns {Promise}
     */
    delete: withResponseHandler((callInfo, url, config) => axios.delete(url, {
      ...config,
      callInfo,
    })),
  };
}

