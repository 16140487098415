import { checkBrowserSupport } from './inlineModules/browserCheck';

if (checkBrowserSupport()) {
  require('client/init/initPolyfills');
  require('client/init/initBluebird');
  require('client/init/initRaven');
  require('client/init/initErrorHandling');
  require('client/init/initMobx');
  require('client/init/initTcomb');
  require('client/init/initNProgress');

  require('client/client');
}
