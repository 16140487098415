import React from 'react';
import PropTypes from 'prop-types';
import { RouterContext } from 'react-router';
import { find } from 'lodash';
import ActiveRoute from 'entries/shared/activeRoutes/ActiveRoute';
import AppErrorBoundary from 'shared/components/AppErrorBoundary/AppErrorBoundary';
import { AppContextProvider } from 'shared/AppContainer/AppContext';
import withContextPathname from './utils/withContextPathname';

export default class RootProd extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    children: PropTypes.node,
    routerState: PropTypes.object,
    // not required because Root can be rendered without routerState or activeRoutes to display an error
    activeRoutes: PropTypes.arrayOf(PropTypes.instanceOf(ActiveRoute)),
  };

  /**
   * @param {ReactComponent} Component
   * @returns {ActiveRoute}
   */
  findActiveRouteByComponent(Component) {
    return find(this.props.activeRoutes, activeRoute => activeRoute.Component === Component);
  }

  /**
   * @param {ReactComponent} Component
   * @param {Object} props
   * @returns {ReactElement}
   */
  createRouteElement = (Component, props) => {
    const activeRoute = this.findActiveRouteByComponent(Component);

    return withContextPathname(Component, {
      ...props,
      // should be an empty object for easier access inside components
      // similar to `this.props` or `this.state` in React)
      fetchedData: activeRoute.state.fetchedDataProp || {},
    });
  };

  render() {
    const { app, children, routerState } = this.props;

    return (
      <AppContextProvider value={app}>
        <AppErrorBoundary>
          <div className="app">
            {Boolean(routerState) && (
              <RouterContext createElement={this.createRouteElement} {...routerState} />
            )}
            {children}
          </div>
        </AppErrorBoundary>
      </AppContextProvider>
    );
  }
}
