import { configure, onReactionError } from 'mobx';
import { setLivelinessChecking } from 'mobx-state-tree';
import { commonLogger } from 'app/logger';

configure({
  // Enable mobx strict mode, which makes mobx throw if functions, that change observable state, are not wrapped in `@action`
  enforceActions: process.env.isProd ? 'never' : 'always',
});

// Global reaction error handler
onReactionError(error => {
  commonLogger.logError(error, {
    tags: { culprit: 'mobx.reactionError' },
  });
});

setLivelinessChecking('error');
