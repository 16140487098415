import React from 'react';
import PropTypes from 'prop-types';
import { bind, isFunction } from 'lodash';
import smart from 'shared/decorators/smartComponent';
import ApplicationError from 'app/errors/ApplicationError';

@smart
class SafeObserver extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
  };

  render() {
    return this.props.render();
  }
}

/**
 * @param {Function} renderMethod
 * @returns {Function}
 */
export function getPartialObserverRender(renderMethod) {
  if (!isFunction(renderMethod)) {
    throw new ApplicationError('@observerMethod decorator can me applied only to class methods');
  }

  return function withObserverRender(...args) {
    return (
      <SafeObserver render={bind(renderMethod, this, ...args)} />
    );
  };
}

/**
 * This decorator should be applied to render methods that passed by reference to render props
 *
 * @param {Object} target - target class prototype
 * @param {string} propertyName
 * @param {Object} descriptor
 * @returns {Object}
 */
export default function observerMethod(target, propertyName, descriptor) {
  // simple class method `renderStuff() {...}`
  if (descriptor.value) {
    return {
      ...descriptor,
      value: getPartialObserverRender(descriptor.value),
    };
    // bound method `renderStuff = () => {...}`
  } else if (descriptor.initializer) {
    return {
      ...descriptor,
      initializer() {
        // preserve arrow function context
        const boundRenderMethod = descriptor.initializer.call(this);

        return getPartialObserverRender(boundRenderMethod);
      },
    };
  }

  throw new ApplicationError('Found unexpected usage on "@observerMethod" decorator');
}
